import React, { useEffect, useMemo, useState } from 'react'
import PageHeader from '../../../shared/page-header';
import { routes } from '../../../config/routes';
import { useDispatch, useSelector } from 'react-redux';
import { TableClass } from '../../../Constant/Classes/Classes';
import { useModal } from '../../../shared/modal-views/use-modal';
import { GetCertificatesColumns } from './add-certificate/certificates-column';
import { useColumn } from '../../../Hooks/use-column';
import { searchCertificate } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { CompileCertificates } from './promise/certificate-promise';
import { setCertificatesApiJson, setCertificatesData } from './store/action/certificate-action';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import { deactiveText } from '../../../Constant/Common/common-variable';


function Certificates() {
  const reduxCertificates = useSelector((state) => state.CertificatesReducer);
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const dispatch = useDispatch()
  const { loadingState, setDynamicLoading } = useDynamicLoading()

  useEffect(() => {
    if (reduxCertificates?.doc === null) {
      loadData('init');
    }
  }, [reduxCertificates]);

  const loadData = (type) => {
    var json = reduxCertificates?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
      Object.assign(json.search, { status: { "$ne": deactiveText } })
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setDynamicLoading({ certificates: true })
    HitApi(json, searchCertificate).then((result) => {

      if (result?.success !== false) {
        CompileCertificates(result, reduxPagination?.doc).then((compiledData) => {
          dispatch(setCertificatesData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements
          }));
        });
      } else {
        dispatch(setCertificatesData([]));
        dispatch(setPagination({limit: json?.limit,totalPages: 1,number: 1,totalElements: 1}));
      }
      setDynamicLoading({ certificates: false })
    });
  };

  const columns = useMemo(() => GetCertificatesColumns());
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

  return (
    <div>
      <PageHeader
        href={routes?.panel?.deviceManager?.addCertificates}
        title={'Add Certificate'}
        columns={columns}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        json={reduxCertificates?.searchJson}
        setAction={setCertificatesApiJson}
        ApiHit={loadData}
      />
      <ControlledTable
        variant="modern"
        isLoading={loadingState?.doc?.certificates}
        showLoadingText={true}
        data={reduxCertificates?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
      />
    </div>
  )
}

export default Certificates
