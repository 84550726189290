import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from '../../../../shared/modal-views/use-modal'
import useCustomAlertController from '../../../../Hooks/use-custom-alert copy'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { setDevicesData } from '../../../device-manager/devices/store/action/devices/devices-action'
import { mapping, removeMapping, searchDevice } from '../../../../Constant/Api/Api'
import { Title } from 'rizzui'
import { FaPlus, FaTimes } from 'react-icons/fa'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { setSelectedMappingMasterJson } from '../../../../Store/Action/master/mapping-master/mapping-master-action'
import { FaArrowRightArrowLeft } from 'react-icons/fa6'
import cn from '../../../../Utils/class-names'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import CustomButton from '../../../../Component/ui/form/button/custom-button'
import { BuildingToReaderMapping, LocationToReaderMapping, ZoneToReaderMapping } from '../mapping-json/mapping-json'

export default function Reader() {
  const dispatch = useDispatch()
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxDevices = useSelector((state) => state.DevicesReducer);
  const reduxMappingMaster = useSelector(state => state.MappingMasterReducer)
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const { loadingState, setDynamicLoading } = useDynamicLoading();

  const selectionType = 'mapping-reader'

  useEffect(() => {
    if (reduxMappingMaster?.mappingJson?.building?._id !== null && reduxMappingMaster?.mappingJson?.zone?._id !== null && reduxMappingMaster?.mappingJson?.location?._id !== null && reduxDevices?.doc === null) {
      loadReaderData()
    }



  }, [reduxMappingMaster])

  const loadReaderData = () => {
    var json = { page: 1, limit: 5000, search: { locationIds: { $in: [reduxMappingMaster?.mappingJson?.location?._id] }, zoneIds: { $in: [reduxMappingMaster?.mappingJson?.zone?._id] }, buildingIds: { $in: [reduxMappingMaster?.mappingJson?.building?._id] } } }
    HitApi(json, searchDevice).then((result) => {

      dispatch(setDevicesData(result))
    })
  }

  const handleReaderClick = (ele) => {
    var json = reduxMappingMaster?.mappingJson
    Object.assign(json, { reader: { value: ele?.model, _id: ele?._id, readerProfileId : ele?.profileId,show: true, ApiHit: loadReaderData } })
    dispatch(setSelectedMappingMasterJson(json))
  }

  const handleOnChange = (e) => {
    const { label, value, ele, _id } = e
    var json = reduxMappingMaster?.mappingJson
    Object.assign(json, { reader: { value: ele?.model, _id: ele?._id } })
    dispatch(setSelectedMappingMasterJson(json))
    openModalWithUpdatedContent()
  }

  const handleApiCalls = async (apiCalls) => {
    var mappingJson = reduxMappingMaster?.mappingJson
    var allSuccess = false;
    for (let i = 0; i < apiCalls.length; i++) {
      const { api, json } = apiCalls[i];
      try {
        const result = await HitApi(json, api); // Assuming HitApi returns a promise
        apiCalls[i].status = result?.status;
        if (result?.status !== 200) {
          console.error(`API call to ${api} failed`);
          break; // Stop if the current API call fails
        }
        showCustomAlert(result)
      } catch (error) {
        console.error(`Error during API call to ${api}:`, error);
        break; // Stop on error
      }

      if (i === apiCalls.length-1) {
        if (mappingJson?.location?.ApiHit) {
          mappingJson?.location?.ApiHit()
        }
        loadReaderData()
        closeModal()
        // // calling clear function to clear select and json
        onClear()
      }
    }
  };

  const handleAddReader = async () => {
    const apiCalls = [
      { api: mapping, success: '', json: LocationToReaderMapping(reduxMappingMaster) },
      { api: mapping, success: '', json: ZoneToReaderMapping(reduxMappingMaster) },
      { api: mapping, success: '', json: BuildingToReaderMapping(reduxMappingMaster) },
    ];

    handleApiCalls(apiCalls)
  }
  const handleRemove = async (ele) => {
    var mappingJson = reduxMappingMaster?.mappingJson
    Object.assign(mappingJson, { reader: { value: ele?.value, _id: ele?._id } })

    const apiCalls = [
      { api: removeMapping, success: '', json: LocationToReaderMapping(reduxMappingMaster) },
      { api: removeMapping, success: '', json: ZoneToReaderMapping(reduxMappingMaster) },
      { api: removeMapping, success: '', json: BuildingToReaderMapping(reduxMappingMaster) },
    ];

    handleApiCalls(apiCalls)
  }

  const onClear = () => {
    var mappingJson = reduxMappingMaster?.mappingJson
    delete mappingJson?.reader // deleting zone key from mapping json 
    var selected = reduxSelect?.selected
    var findItem = selected?.map((Obj) => Obj?.selectionType !== selectionType)
    dispatch(setSearchableSelectSelectedData(findItem))
  }

  const handleClick = () => {
    openModalWithUpdatedContent();
  }

  const openModalWithUpdatedContent = () => {
    openModal({
      view: <div className='p-10 h-[31.25rem] flex flex-col justify-between'>
        <SearchableSelect name="readerId" label="Reader" selectionType={selectionType} api={searchDevice} dynamicSearch={{ locationIds: { "$size": 0 } }} getFieldName={'model'} onChange={handleOnChange} />
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <label className='font-semibold'>From Reader</label>
            <label>{reduxMappingMaster?.mappingJson?.reader?.value}</label>
          </div>
          <div className='flex justify-center'><FaArrowRightArrowLeft className='h-8 w-8' /></div>
          <div className='flex flex-col'>
            <label className='font-semibold'>To Location</label>
            <label>{reduxMappingMaster?.mappingJson?.location?.value}</label>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <label className='font-semibold'>From Reader</label>
            <label>{reduxMappingMaster?.mappingJson?.reader?.value}</label>
          </div>
          <div className='flex justify-center'><FaArrowRightArrowLeft className='h-8 w-8' /></div>
          <div className='flex flex-col'>
            <label className='font-semibold'>To Zone</label>
            <label>{reduxMappingMaster?.mappingJson?.zone?.value}</label>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <label className='font-semibold'>From Reader</label>
            <label>{reduxMappingMaster?.mappingJson?.reader?.value}</label>
          </div>
          <div className='flex justify-center'><FaArrowRightArrowLeft className='h-8 w-8' /></div>
          <div className='flex flex-col'>
            <label className='font-semibold'>To Building</label>
            <label>{reduxMappingMaster?.mappingJson?.building?.value}</label>
          </div>
        </div>
        <CustomButton loading={loadingState?.doc?.location} title={'Start Mapping'} onClick={() => handleAddReader()} />
      </div>
    })
  }

  let item;
  if (reduxDevices?.doc !== null) {
    item = reduxDevices?.doc?.content?.map((ele, index) => {
      return <div key={index} className='group my-1.5'>
        <div className={cn('shadow-sm rounded-lg group-hover:cursor-pointer border', ele?._id === reduxMappingMaster?.mappingJson?.reader?._id ? 'bg-red-lighter text-red-main font-bold tracking-wider border border-red-main' : 'bg-white ')}>
          <div className='flex justify-between'>
            <div className='flex items-center p-3 w-full' onClick={() => handleReaderClick(ele)}>
              <div><label className='group-hover:cursor-pointer'>{ele?.model}</label></div>
            </div>
            {<div className='bg-red-main text-white flex items-center p-2 rounded-r-lg' onClick={() => handleRemove(ele)}>
              <label className='group-hover:cursor-pointer'><FaTimes /></label>
            </div>}
          </div>
        </div>
        <label className='group-hover:cursor-pointer'>{ele?._id}</label>
      </div>
    })
  }


  return (
    <div>
      <Title as='h5'>Device</Title>
      <div>
        {loadingState?.doc?.reader ? <CustomButton title={'Loading...'} /> : <CustomButton title={'Map Reader'} LeftIcon={<FaPlus />} onClick={() => handleClick()} disabled={!reduxMappingMaster?.mappingJson?.zone?._id} />}
        <div>
          {item || 'No Data Found'}
        </div>
      </div>
    </div>
  )
}
