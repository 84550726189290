import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from '../../../Utils/class-names';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { generateCycleCount, searchCycleCount } from '../../../Constant/Api/Api';
import { setDashboardApiJson } from '../../../Store/Action/dashbaord/DashboardAction';
import { setDashboardCycleCountData, setDashboardDifferenceData, setDashboardInventoryData } from '../../../Store/Action/dashbaord/cycle-count/cycle-count-action';

export default function CycleCountLocationItem({ ele, parent }) {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector((state) => state.DashboardReducer);
    const reduxPagination = useSelector(state => state.PaginationReducer);


    const handleClick = (ele) => {
        dispatch(setDashboardDifferenceData(null))
        dispatch(setDashboardInventoryData(null))
        dispatch(setDashboardCycleCountData(null))
        var json = reduxDashboard?.apiJson
        Object.assign(json, {selectedLocation : ele})
        dispatch(setDashboardApiJson(json))
    }

    return (
        <div className='w-48 group'>
            <div className={cn('card cursor-pointer p-4 justify-between border-2 rounded-md', reduxDashboard?.apiJson?.selectedLocation?._id === ele?._id && 'bg-red-lighter border-red-custom_light')} onClick={() => handleClick(ele)}>
                <div className='flex flex-col'>
                    <label className='capitalize cursor-pointer'>{ele?._id?.slice(-5)}</label>
                    <label className='capitalize cursor-pointer'>{ele?.value}</label>
                </div>
            </div>
        </div>
    )
}
