import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../../Hooks/useValidation';
import { _id } from '../../../../Constant/Common/common-variable';
import CustomInput from '../../../../Component/ui/form/input/custom-input';
import CustomButton from '../../../../Component/ui/buttons/custom-button';
import { searchBuilding } from '../../../../Constant/Api/Api';
import { setTagMasterApiJson } from '../../../../Store/Action/master/tag-master/tag-master-action';
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect';

export default function Building({row, onClick}) {
    const dispatch = useDispatch()
    const reduxTag = useSelector(state => state.TagMasterReducer)
    const { errors, validate } = useValidation();



    const handleVehicleSelect = (e) => {
        const { label, value, _id } = e
        var json = reduxTag?.apiJson
        Object.assign(json,{buildingId : _id})


    }

    const handleSubmit = () => {
        if (onClick) { onClick() }
    }
    return (
        <div>
            <div className='grid grid-cols-4 gap-4'>
                <SearchableSelect selectionType={'dropdown'} name="buildingName" label="Building" api={searchBuilding} getFieldName={'buildingName'} onChange={handleVehicleSelect} />
                <CustomInput name="rfidTag" label="RFID Tag" validate={validate} value={reduxTag?.apiJson?.rfidTag} error={errors} reduxState={reduxTag?.apiJson} setAction={setTagMasterApiJson} />
                <CustomInput name="tagInfo" label="Tag Info" validate={validate} value={reduxTag?.apiJson?.tagInfo} error={errors} reduxState={reduxTag?.apiJson} setAction={setTagMasterApiJson} />
                <CustomInput name="tagPlacement" label="Tag Placement" validate={validate} value={reduxTag?.apiJson?.tagPlacement} error={errors} reduxState={reduxTag?.apiJson} setAction={setTagMasterApiJson} />
            </div>
            <div className='flex my-4 justify-end'>
                <CustomButton type={'submit'} className={''} text={row?.[_id] ? 'Update' : 'Submit'} onClick={handleSubmit} />
            </div>
        </div>
    )
}
