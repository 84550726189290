import React from 'react'
import CustomJsonEditor from '../../../Component/ui/editor/json-editor';

export default function DeviceResponse({ data, closeModal }) {



    
    return (
        <div className='p-10'>
             <CustomJsonEditor json={data?.res} readOnly={true} />
            {/* <label>{data?.res}</label> */}
        </div>
    )
}
