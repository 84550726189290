import { ActionIcon, Text, Tooltip } from "rizzui";
import { HeaderCell } from "../../../../Component/ui/table";
import PencilIcon from "../../../../Constant/Icons/pencil";
import { EditScreen } from "../../../../shared/edit-screen";
import AddSupplierMaster from "../../../../Form/master/supplier-master/add-supplier-master";
import DeletePopover from "../../../../shared/delete-popover";
import { HitApi } from "../../../../Store/Action/Api/ApiAction";
import { useModal } from "../../../../shared/modal-views/use-modal";
import useCustomAlertController from "../../../../Hooks/use-custom-alert copy";
import { useState } from "react";
import { deactiveText } from "../../../../Constant/Common/common-variable";
import { updateCertificate } from "../../../../Constant/Api/Api";
import Skeleton from "react-loading-skeleton";

export const GetCertificatesColumns = (ApiHit) => {
    const { openModal, closeModal } = useModal();
    const { showCustomAlert } = useCustomAlertController();
    const [loadingRows, setLoadingRows] = useState({});
  
    const handleDelete = async (row) => {
      setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
      Object.assign(row, {status : deactiveText})
      try {
        const result = await HitApi(row, updateCertificate);

        showCustomAlert(result)
        if (ApiHit) { ApiHit(); }
        
      } catch (err) {
  
      } finally {
        setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
      }
      
    };
  
    const renderCell = (value, row, content) => (
      loadingRows[row.index] ? <Skeleton /> : content
    );
    return [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 30,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        },
        {
            title: <HeaderCell title="Certificate Name" className={'font-extrabold'} />,
            dataIndex: 'certificateName',
            key: 'certificateName',
            width: 150,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        }, {
            title: <HeaderCell title="Certificate Type" className={'font-extrabold'} />,
            dataIndex: 'certificateType',
            key: 'certificateType',
            width: 150,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        },
        {
            title: <HeaderCell title="Version" className={'font-extrabold'} />,
            dataIndex: 'version',
            key: 'version',
            width: 150,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        },
        {
            title: <HeaderCell title="Issuer" className={'font-extrabold'} />,
            dataIndex: 'issuer',
            key: 'issuer',
            width: 150,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        },
        {
            title: <HeaderCell title="Serial Number" className={'font-extrabold'} />,
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: 150,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        },
        {
            title: <HeaderCell title="Revoked" className={'font-extrabold'} />,
            dataIndex: 'isRevoked',
            key: 'isRevoked',
            width: 150,
            render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
        },
    
    
        {
            title: <HeaderCell title="Actions" className={'font-extrabold'} />,
            dataIndex: 'action',
            key: 'action',
            width: 130,
            render: (_, row) => (
                <div className="flex items-center gap-3 pe-4">
                    <Tooltip size="sm" content={'Edit Certificate'} placement="top" color="invert">
                        <a href={'/device-manager/certificates/edit/' + row._id}>
                            <ActionIcon as="span" size="sm" variant="outline" className="hover:text-gray-700">
                                <PencilIcon className="h-4 w-4" />
                            </ActionIcon>
                        </a>
                    </Tooltip>
                    <DeletePopover title={`Delete Certificate`} description={`Are you sure you want to delete?`}
                        loading={loadingRows[row.index]}
                        onDelete={() => handleDelete(row)}
                    />
                </div>
            ),
        },
    ]
}
