import { Navigate } from "react-router-dom";
import usePermissionCheck from "../../Hooks/use-permission-check";
import { getAuthenticatedUserWithRoles } from "../../Storage/Storage";
import { routes } from "../../config/routes";

const AuthRoute = ({ element, screen }) => {
  const user = getAuthenticatedUserWithRoles();
  const isEditAllowed = usePermissionCheck(screen, 'read');

  if(isEditAllowed){
    return element;
  }

  return <Navigate to={routes?.panel?.access?.blocked} />;

};

export default AuthRoute;
