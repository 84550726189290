import React, { useEffect, useState } from 'react'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import { searchDeviceProfile, searchTag } from '../../Constant/Api/Api'
import CustomBarChart from '../../Component/ui/chart/bar-chart/bar-chart'
import CustomButton from '../../Component/ui/buttons/custom-button'

export default function Test() {
  const [data, setData] = useState(null)

  useEffect(() => {

  }, [])

  const loadData = () => {
    var json = { page: 1, limit: 10, search: { currentLocation : "66e9428f13e07f59c42f8e5e",} }

    Object.assign(json.search, {"$or":[{ opreationStatus : "HOLD" },{ opreationStatus : "CONSUME" }]})



    HitApi(json, searchTag).then((res) => {


    })
  }

  return (
    <div>
      test

      <CustomButton onClick={loadData} text={'click'} />
    </div>

  )
}
