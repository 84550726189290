import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { mapping, removeMapping, searchZone } from '../../../../Constant/Api/Api'
import cn from '../../../../Utils/class-names'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { Title } from 'rizzui'
import useCustomAlertController from '../../../../Hooks/use-custom-alert'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import CustomButton from '../../../../Component/ui/form/button/custom-button'
import { setZoneMasterData } from '../../../../Store/Action/master/zone-master/zone-master-action'
import { useModal } from '../../../../shared/modal-views/use-modal'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { FaArrowRightArrowLeft } from 'react-icons/fa6'
import { setSelectedMappingMasterJson, setSelectedMappingMasterZoneData } from '../../../../Store/Action/master/mapping-master/mapping-master-action'
import { BuildingToZoneMapping } from '../mapping-json/mapping-json'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import { setLocationMasterData } from '../../../../Store/Action/master/location-master/location-master-action'

export default function Zone() {
  const dispatch = useDispatch()
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxZone = useSelector(state => state.ZoneMasterReducer)
  const reduxMappingMaster = useSelector(state => state.MappingMasterReducer)
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const { loadingState, setDynamicLoading } = useDynamicLoading();
  const selectionType = 'mapping-zone'

  useEffect(() => {
    if (reduxMappingMaster?.mappingJson?.building?._id !== null && reduxZone?.doc === null) {
      loadZoneData()
    }

  }, [reduxMappingMaster])

  const loadZoneData = () => {
    var json = { page: 1, limit: 5000, search: { buildingIds: { $in: [reduxMappingMaster?.selectedBuilding?._id] } } }
    HitApi(json, searchZone).then((result) => {
      dispatch(setZoneMasterData(result))
    })
  }

  const handleZoneClick = (ele) => {
    var json = reduxMappingMaster?.mappingJson
    Object.assign(json, { zone: { value: ele?.value, _id: ele?._id, show:true, ApiHit : loadZoneData } })
    dispatch(setSelectedMappingMasterJson(json))
    // clearing location data
    dispatch(setLocationMasterData(null))

  }

  const handleOnChange = (e) => {
    const { label, value, ele, _id } = e
    var json = reduxMappingMaster?.mappingJson
    Object.assign(json, { zone: { value: ele?.value, _id: ele?._id } })
    dispatch(setSelectedMappingMasterJson(json))
    dispatch(setSelectedMappingMasterZoneData(ele))
    openModalWithUpdatedContent()
  }

  const handleAddZone = async () => {
    var finalJson = BuildingToZoneMapping(reduxMappingMaster)
    var mappingJson = reduxMappingMaster?.mappingJson
    const zoneResult = await HitApi(finalJson, mapping);
    if (zoneResult?.status === 200) {
      showCustomAlert(zoneResult)
      loadZoneData()
      closeModal()
      // calling clear function to clear select and json
      onClear()
    }
  }
  const handleRemove = async(ele) => {
    var mappingJson = reduxMappingMaster?.mappingJson
    Object.assign(mappingJson, { zone: { value: ele?.value, _id: ele?._id } })
    var finalJson = BuildingToZoneMapping(reduxMappingMaster)
    const zoneResult = await HitApi(finalJson, removeMapping);
    if (zoneResult?.status === 200) {
      showCustomAlert(zoneResult)
      loadZoneData()
      closeModal()
      // calling clear function to clear select and json
      onClear()
    }



  }

  const onClear = () =>{
    var mappingJson = reduxMappingMaster?.mappingJson
    delete mappingJson?.zone // deleting zone key from mapping json 
    var selected = reduxSelect?.selected
    var findItem = selected?.map((Obj)=>Obj?.selectionType !== selectionType)
    dispatch(setSearchableSelectSelectedData(findItem))
  }

  const handleClick = () => {
    openModalWithUpdatedContent();
  }

  const openModalWithUpdatedContent = () => {
    openModal({
      view: <div className='p-10 h-96 flex flex-col justify-between'>
        <SearchableSelect name="zoneId" label="Zone" selectionType={selectionType} api={searchZone} dynamicSearch={{ buildingIds: { "$size": 0 } }} getFieldName={'value'} onChange={handleOnChange} />
        <div className='flex justify-between items-center'>
          <div className='flex flex-col'>
            <label className='font-semibold'>From Zone</label>
            <label>{reduxMappingMaster?.mappingJson?.zone?.value}</label>
          </div>
          <div><FaArrowRightArrowLeft className='h-8 w-8' /></div>
          <div className='flex flex-col'>
            <label className='font-semibold'>To Building</label>
            <label>{reduxMappingMaster?.mappingJson?.building?.value}</label>
          </div>
        </div>
        <CustomButton title={'Add Zone'} onClick={() => handleAddZone()} />
      </div>
    })
  }


  let item;
  if (reduxZone?.doc !== null) {
    item = reduxZone?.doc?.content?.map((ele, index) => {
      return <div key={index} className='group my-1.5'>
        <div className={cn('shadow-sm rounded-lg group-hover:cursor-pointer border', ele?._id === reduxMappingMaster?.mappingJson?.zone?._id ? 'bg-red-lighter text-red-main font-bold tracking-wider border border-red-main' : 'bg-white ')}>
          <div className='flex justify-between'>
            <div className='flex items-center p-3 w-full' onClick={() => handleZoneClick(ele)}>
              <div><label className='group-hover:cursor-pointer'>{ele?.value}</label></div>
            </div>
            {ele?.locationIds?.length === 0 && <div className='bg-red-main text-white flex items-center p-2 rounded-r-lg' onClick={() => handleRemove(ele)}>
              <label className='group-hover:cursor-pointer'><FaTimes /></label>
            </div>}
          </div>
        </div>
        <label className='group-hover:cursor-pointer'>{ele?._id}</label>
      </div>
    })
  }

  return (
    <div>
      <Title as='h5'>Zone</Title>
      <div>
        {loadingState?.doc?.zone ? <CustomButton title={'Loading...'} /> : <CustomButton title={'Map Zone'} LeftIcon={<FaPlus />} onClick={() => handleClick()} disabled={!reduxMappingMaster?.mappingJson?.building?._id} />}
        <div>
          {item || 'No Data Found'}
        </div>
      </div>
    </div>
  )
}
