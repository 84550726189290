import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import cn from '../../../Utils/class-names';
import { setDashboardApiJson } from '../../../Store/Action/dashbaord/DashboardAction';

export default function ProfileItem({ ele }) {
  const dispatch = useDispatch()
  const reduxDashboard = useSelector(state => state.DashboardReducer);

  console.log('ele ProfileItem', ele);


  const handleClick = (ele) =>{
    var json = reduxDashboard?.apiJson
    Object.assign(json,{selectedDeviceAnteena : ele})
    dispatch(setDashboardApiJson(json))
  }

  return (
    <div className='w-48 group'>
      <div className={cn('card cursor-pointer p-4 justify-between border-2 rounded-md ', parseInt(reduxDashboard?.apiJson?.selectedDeviceAnteena?.value) === parseInt(ele?.value) ? 'bg-red-lighter border-red-custom_light' : '')} onClick={()=>handleClick(ele)}>
        <div className='flex justify-between cursor-pointer'>
          <label className='capitalize cursor-pointer'>{ele?.name}</label>
          <label className='capitalize cursor-pointer'>{ele?.userEntry}</label>
        </div>
      </div>
    </div>
  )
}
