export const deviceVariable = {
    deviceName : 'deviceName',
    deviceType : 'deviceType',
    buildingIds : 'buildingIds',
    placement : 'placement',
    make : 'make',
    model:'model',
    firmwareVersion : 'firmwareVersion',
    isCloudConfigurable : 'isCloudConfigurable',
    protocol : 'protocol',
    isSecureConnection : 'isSecureConnection',
    secureConnectionType : 'secureConnectionType',
    host : 'host',
    port : 'port',
    username : 'username',
    password : 'password',
    isDeviceMovable : 'isDeviceMovable',
    deviceLocation : 'deviceLocation',
    lat:'lat',
    lng:'lng',
    brokerDetailsId : 'brokerDetailsId',
    certificateId : 'certificateId',
    apiService:'apiService',
    deviceProfile:'deviceProfile',
    profileId : 'profileId',
    deviceUserIdentify : 'deviceUserIdentify',
    macAddress : 'macAddress'
}