import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mapping, removeMapping, searchLocation } from '../../../../Constant/Api/Api'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { setLocationMasterData } from '../../../../Store/Action/master/location-master/location-master-action'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { FaArrowRightArrowLeft } from 'react-icons/fa6'
import CustomButton from '../../../../Component/ui/form/button/custom-button'
import { FaPlus, FaTimes } from 'react-icons/fa'
import cn from '../../../../Utils/class-names'
import { setSelectedMappingMasterJson, setSelectedMappingMasterLocationData } from '../../../../Store/Action/master/mapping-master/mapping-master-action'
import { useModal } from '../../../../shared/modal-views/use-modal'
import useCustomAlertController from '../../../../Hooks/use-custom-alert copy'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import { BuildingToLocationMapping, ZoneToLocationMapping } from '../mapping-json/mapping-json'
import { Title } from 'rizzui'
import { useState } from 'react'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import { setDevicesData } from '../../../device-manager/devices/store/action/devices/devices-action'

export default function Location() {
  const dispatch = useDispatch()
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxLocation = useSelector(state => state.LocationMasterReducer)
  const reduxMappingMaster = useSelector(state => state.MappingMasterReducer)
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const { loadingState, setDynamicLoading } = useDynamicLoading();

  const selectionType = 'mapping-location'

  useEffect(() => {
    if (reduxMappingMaster?.mappingJson?.building?._id !== null && reduxMappingMaster?.mappingJson?.zone?._id !== null && reduxLocation?.doc === null) {
      loadLocationData()
    }

  }, [reduxMappingMaster])

  const loadLocationData = () => {
    var json = { page: 1, limit: 5000, search: { zoneIds: { $in: [reduxMappingMaster?.mappingJson?.zone?._id] }, buildingIds: { $in: [reduxMappingMaster?.mappingJson?.building?._id] } } }
    HitApi(json, searchLocation).then((result) => {

      dispatch(setLocationMasterData(result))
    })

  }

  const handleLocationClick = (ele) => {
    var json = reduxMappingMaster?.mappingJson
    Object.assign(json, { location: { value: ele?.value, _id: ele?._id, show: true, ApiHit: loadLocationData } })
    dispatch(setSelectedMappingMasterJson(json))
    // clearing location data
    dispatch(setDevicesData(null))

  }

  const handleOnChange = (e) => {
    const { label, value, ele, _id } = e
    var json = reduxMappingMaster?.mappingJson
    Object.assign(json, { location: { value: ele?.value, _id: ele?._id } })
    dispatch(setSelectedMappingMasterJson(json))
    openModalWithUpdatedContent()
  }

  const handleAddLocation = async () => {
    var mappingJson = reduxMappingMaster?.mappingJson
    var finalJson = ZoneToLocationMapping(reduxMappingMaster)

    openModalWithUpdatedContent();
    const locationResult = await HitApi(finalJson, mapping);
    if (locationResult?.status === 200) {
      showCustomAlert(locationResult)
      var buildingToLocationJson = BuildingToLocationMapping(reduxMappingMaster)
      const buildingResult = await HitApi(buildingToLocationJson, mapping);
      if (buildingResult?.status === 200) {
        showCustomAlert(buildingResult)
        if (mappingJson?.zone?.ApiHit) {
          mappingJson?.zone?.ApiHit()
        }
        loadLocationData()
        closeModal()
        // // calling clear function to clear select and json
        onClear()
      }

    }

  }
  const handleRemove = async (ele) => {
    var mappingJson = reduxMappingMaster?.mappingJson
    Object.assign(mappingJson, { location: { value: ele?.value, _id: ele?._id } })
    var finalJson = ZoneToLocationMapping(reduxMappingMaster)
    const locationResult = await HitApi(finalJson, removeMapping);
    if (locationResult?.status === 200) {
      showCustomAlert(locationResult)
      var buildingToLocationJson = BuildingToLocationMapping(reduxMappingMaster)
      const buildingResult = await HitApi(buildingToLocationJson, removeMapping);
      if (buildingResult?.status === 200) {
        showCustomAlert(buildingResult)
        if (mappingJson?.zone?.ApiHit) {
          mappingJson?.zone?.ApiHit()
        }
        loadLocationData()
        closeModal()
        // // calling clear function to clear select and json
        onClear()
      }

    }
  }

  const onClear = () => {
    var mappingJson = reduxMappingMaster?.mappingJson
    delete mappingJson?.location // deleting zone key from mapping json 
    var selected = reduxSelect?.selected
    var findItem = selected?.map((Obj) => Obj?.selectionType !== selectionType)
    dispatch(setSearchableSelectSelectedData(findItem))
  }

  const handleClick = () => {
    openModalWithUpdatedContent();
  }

  const openModalWithUpdatedContent = () => {
    openModal({
      view: <div className='p-10 h-96 flex flex-col justify-between'>
        <SearchableSelect name="locationId" label="Location" selectionType={selectionType} api={searchLocation} dynamicSearch={{ zoneIds: { "$size": 0 } }} getFieldName={'value'} onChange={handleOnChange} />
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <label className='font-semibold'>From Location</label>
            <label>{reduxMappingMaster?.mappingJson?.location?.value}</label>
          </div>
          <div className='flex justify-center'><FaArrowRightArrowLeft className='h-8 w-8' /></div>
          <div className='flex flex-col'>
            <label className='font-semibold'>To Zone</label>
            <label>{reduxMappingMaster?.mappingJson?.zone?.value}</label>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <label className='font-semibold'>From Location</label>
            <label>{reduxMappingMaster?.mappingJson?.location?.value}</label>
          </div>
          <div className='flex justify-center'><FaArrowRightArrowLeft className='h-8 w-8' /></div>
          <div className='flex flex-col'>
            <label className='font-semibold'>To Building</label>
            <label>{reduxMappingMaster?.mappingJson?.building?.value}</label>
          </div>
        </div>
        <CustomButton loading={loadingState?.doc?.location} title={'Start Mapping'} onClick={() => handleAddLocation()} />
      </div>
    })
  }


  let item;
  if (reduxLocation?.doc !== null) {
    item = reduxLocation?.doc?.content?.map((ele, index) => {
      return <div key={index} className='group my-1.5'>
        <div className={cn('shadow-sm rounded-lg group-hover:cursor-pointer border', ele?._id === reduxMappingMaster?.mappingJson?.location?._id ? 'bg-red-lighter text-red-main font-bold tracking-wider border border-red-main' : 'bg-white ')}>
          <div className='flex justify-between'>
            <div className='flex items-center p-3 w-full' onClick={() => handleLocationClick(ele)}>
              <div><label className='group-hover:cursor-pointer'>{ele?.value}</label></div>
            </div>
            {ele?.readerIds?.length === 0 && <div className='bg-red-main text-white flex items-center p-2 rounded-r-lg' onClick={() => handleRemove(ele)}>
              <label className='group-hover:cursor-pointer'><FaTimes /></label>
            </div>}
          </div>
        </div>
        <label className='group-hover:cursor-pointer'>{ele?._id}</label>
      </div>
    })
  }

  return (
    <div>
      <Title as='h5'>Location</Title>
      <div>
        {loadingState?.doc?.location ? <CustomButton title={'Loading...'} /> : <CustomButton title={'Map Location'} LeftIcon={<FaPlus />} onClick={() => handleClick()} disabled={!reduxMappingMaster?.mappingJson?.zone?._id} />}
        <div>
          {item || 'No Data Found'}
        </div>
      </div>
    </div>
  )
}
