import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDevicesApiJson, setDevicesData } from './store/action/devices/devices-action';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { searchDevice, searchApiService, deleteDevice } from '../../../Constant/Api/Api';
import { CompileDevices } from './promiss/devices-promiss';
import { Button, Loader } from 'rizzui';
import CustomTable from '../../../Component/ui/table/table';
import TableActions from '../../../Component/ui/table/TableActions';
import PageHeader from '../../../shared/page-header';
import { routes } from '../../../config/routes';
import { useModal } from '../../../shared/modal-views/use-modal';
import DeviceResponse from './device-response';
import ProgressBar from '../../../Component/ui/loading/progress-bar';
import useCustomAlertController from '../../../Hooks/use-custom-alert';
import { _id, deactiveText } from '../../../Constant/Common/common-variable';
import { autoRequest } from './utils/device-utils';

const Devices = () => {
  const dispatch = useDispatch();
  const reduxDevices = useSelector((state) => state.DevicesReducer);
  const reduxPagination = useSelector((state) => state.PaginationReducer);
  const [data, setData] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Start as an empty array
  const [servicesLoading, setServicesLoading] = useState(null);  // Track loading state (one at a time)
  const [stepsData, setStepsData] = useState({});
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();

  useEffect(() => {
    if (reduxDevices?.doc === null) {
      loadData('init');
    }
  }, [reduxDevices]);




  useEffect(() => {
    if (data?.length === stepsData?.length) {
      setServicesLoading(null)
    }

  }, [data])

  const loadData = (type) => {
    let json = reduxDevices?.searchJson;
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
      Object.assign(json.search, { status: { "$ne": deactiveText } })
       delete json?.search?._id

    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }

    HitApi(json, searchDevice).then((result) => {
      if (result?.success !== false) {
        CompileDevices(result, reduxPagination?.doc).then((compiledData) => {
          dispatch(setDevicesData(compiledData));
          dispatch(
            setPagination({
              limit: json?.limit,
              totalPages: compiledData?.totalPages,
              number: compiledData?.number,
              totalElements: compiledData?.totalElements,
            })
          );
        });
      } else {
        dispatch(setDevicesData([]));
      }
    });
  };


  const handleStart = (index, element, record) => {
    setData(null)

    const json = { page: 1, limit: 1, search: { _id: element?.serviceId } };
    HitApi(json, searchApiService).then(({ content }) => {
      if (content?.[0]) {
        const stepsFromApi = content?.[0]?.requests || [];
        setStepsData({ length: stepsFromApi?.length, [index]: stepsFromApi })
        setServicesLoading(index)
        var checkGlobal = content?.[0]?.globalVariables;

        if (checkGlobal) {
          content?.[0]?.globalVariables?.map((key)=>{
            var check = record?.[key?.valueName]
            if(key?.valueName==='readerId'){
              key['data'] = record?._id
            }else{
              if(check){
                key['data'] = record?.[key?.valueName]
              }
            }
          })
          var findHost = content?.[0]?.globalVariables?.find(Obj=>Obj.valueName === 'host')
          if (findHost?.data !== 'blankhost') {
            autoRequest(content?.[0], setData, content?.[0]?.globalVariables);
          }
        }
      }
    })
  };

  // setTimeout(() => {
  //   setServicesLoading(null)
  // }, 1000);

  const handleExpand = (_id, expanded) => {
    if (expanded) {
      // Set only the expanded row's _id
      setExpandedRowKeys([_id]);
    } else {
      // If collapsing the row, clear the expandedRowKeys
      setExpandedRowKeys([]);
    }
  };

  const showResponse = (index) => {
    var tp = data?.find(Obj => Obj._id === index)


    openModal({ view: <DeviceResponse data={tp} />, title: 'Response', customSize: '800px' })
  }

  const expandedStepsRender = (service, stepsData) => {
    const steps = stepsData[service.key] || [];
    return (
      <div className="flex space-x-5">
        {steps.length > 0 ? (
          steps.map((step, index) => (
            <div key={index} className="flex flex-col">
              <label>{step?.requestName}</label>
              <label>
                {data && data?.[index]?._id === index ? (
                  <span className="text-green-buttonGreen font-bold cursor-pointer" onClick={() => showResponse(index)}>Success</span>
                ) : data?.[index]?._id === undefined ? (
                  <ProgressBar duration={5000} onComplete={data?.[index]?._id === step?.requestId} />
                  // <label>loading...</label>
                ) : (
                  <span>Error</span>
                )}
              </label>
            </div>
          ))
        ) : null}
      </div>
    );
  };

  const expandedRowRender = (record) => {
    const apiServiceColumns = [
      {
        title: 'Service Usage',
        dataIndex: 'serviceUsage',
        width: 350,
        key: 'serviceUsage',
        render: (value, service, index) => (
          <div className="flex justify-between items-center">
            <div>{value || '---'}</div>
            <Button
              onClick={() => handleStart(index, service, record)}
              isLoading={servicesLoading === index} // Only the clicked button will load
              disabled={servicesLoading !== null} // Disable all buttons while one is loading
              className="disabled:bg-white"  // Apply the custom disabled style
            >
              {servicesLoading === index ? 'Loading...' : 'Start'}
            </Button>
          </div>
        ),
      },
      {
        title: 'Steps',
        key: 'steps',
        render: (_, service) => expandedStepsRender(service, stepsData),
      },
    ];

    const apiServiceData = record.apiService.map((service, index) => ({
      key: index,
      serviceUsage: service.serviceUsage,
      serviceId: service.serviceId,
      loading: service.loading || false,
    }));

    return <CustomTable columns={apiServiceColumns} data={apiServiceData} rowKey="key" />;
  };

  const handleDelete = async (row) => {
    // setLoadingRows((prev) => ({ ...prev, [row.index]: true }));

    const json = { _id: row?._id };

    try {
      const result = await HitApi(json, deleteDevice);
      showCustomAlert(result)
      loadData()
    } catch (err) {
      console.error(err);
    } finally {
      // setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
  };

  const columns = [
    { title: '#', dataIndex: 'index', key: 'index' },
    { title: '#', dataIndex: '_id', key: '_id' },
    { title: 'Device Name', dataIndex: 'deviceName', key: 'deviceName' },
    { title: 'Device Type', dataIndex: 'deviceType', key: 'deviceType' },
    { title: 'Make', dataIndex: 'make', key: 'deviceType' },
    { title: 'Model', dataIndex: 'model', key: 'deviceType' },
    {
      title: 'Host:port', dataIndex: 'host', key: 'host',
      render: (_, record) => (
        <div>
          <label>{record?.host + ':' + record?.port}</label>
        </div>
      )
    },
    {
      title: 'NTP Host:port', dataIndex: 'host', key: 'host',
      render: (_, record) => (
        <div>
          <label>{record?.ntpServerHost + ':' + record?.ntpServerPort}</label>
        </div>
      )
    },
    {
      title: 'Broker IP:port', dataIndex: 'host', key: 'host',
      render: (_, record) => (
        <div>
          <label>{record?.brokerDetailsId?.brokerIp + ':' + record?.brokerDetailsId?.brokerPort}</label>
        </div>
      )
    },
    { title: 'protocol', dataIndex: 'protocol', key: 'protocol' },
    {
      title: 'Device Profile', dataIndex: 'profileUsage', key: 'profileUsage',
      render: (_, record) => (
        <div>
          <label>{record?.deviceProfile?.profileUsage}</label>
        </div>
      )
    },
    {
      title: 'Device User Identify', dataIndex: 'deviceUserIdentify', key: 'deviceUserIdentify',
      render: (_, record) => <label>{record?.deviceUserIdentify || '---'}</label>
    },
    {
      title: 'Mac Address', dataIndex: 'macAddress', key: 'macAddress',
      render: (_, record) => <label>{record?.macAddress || '---'}</label>
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <TableActions
          screen={'siteMaster'}
          enableSetting
          row={record}
          href={routes?.panel?.deviceManager?.editDevice}
          type={'device'}
          onSettingClick={() => handleExpand(record._id, !expandedRowKeys.includes(record._id))}
          onDelete={() => handleDelete(record)}
          checkKeys={[]}
          
        />
      ),
    },
  ];



  return (
    <>
      <PageHeader btnText={'Add Device'} href={routes?.panel?.deviceManager?.addDevice} disbleExport setState={setDevicesApiJson} selectionType={'add-device'} json={reduxDevices?.searchJson} />
      {reduxDevices?.doc?.content?.length > 0 ? (
        <CustomTable
          columns={columns}
          data={reduxDevices?.doc?.content}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => Array.isArray(record.apiService) && record.apiService.length > 0,
            expandedRowKeys,
            onExpand: (expanded, record) => handleExpand(record._id, expanded),
          }}
          rowKey="_id"  // Use _id as the row key
          variant="elegant"
        />
      ) : null}
    </>
  );
};

export default Devices;
