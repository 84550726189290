import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { addZone, searchGeneral, updateZone } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { zoneMasterVariable as variable } from '../../../Constant/variables/master/zone-master/zone-master.variable';
import { useState } from 'react';
import { setZoneMasterApiJson } from '../../../Store/Action/master/zone-master/zone-master-action';
import { zoneMasterSchema } from '../../../Utils/validators/master/zone-master/zone-master-scheema';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import { _id } from '../../../Constant/Common/common-variable';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';

export default function AddZoneMaster({ row, closeModal, ApiHit }) {
    var dispatch = useDispatch()
    const reduxZone = useSelector(state => state.ZoneMasterReducer)
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const { errors, validate } = useValidation(zoneMasterSchema);
    const { showCustomAlert } = useCustomAlertController();
    const [loading, setLoading] = useState(false);
    const selectionType = 'add-zone'

    useEffect(() => {
        if (row?._id) {
            loadDefault(row)
        }
    }, [])

    const loadDefault = (row) => {
        var json = reduxZone?.apiJson
        var selected = reduxSelect?.selected
        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        dispatch(setSearchableSelectSelectedData([...selected,
        { label: row?.['status'], label: row?.['status'], name: 'status', selectionType: selectionType }
        ]))
        dispatch(setZoneMasterApiJson(json))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxZone?.apiJson
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true)
            if (row?._id) {
                Object.assign(json, { _id: row?._id})
                HitApi(json, updateZone).then((result) => {
                    showCustomAlert(result)
                    if (ApiHit) { ApiHit() }
                    handleClose()
                })
            } else {
                Object.assign(json, { _id: row?._id, status: row?.status || 'active' })
                HitApi(json, addZone).then((result) => {
                    showCustomAlert(result)
                    if (ApiHit) { ApiHit() }
                    handleClose()
                })
            }
        } else {

        }
    };

    const handleStatusChange = (e) => {
        let { value } = e
        var json = reduxZone?.apiJson
        Object.assign(json, { status: value })
        dispatch(setZoneMasterApiJson(json))
    }

    const handleClose = () => {
        closeModal()
        dispatch(setZoneMasterApiJson({}))
    }
    return (
        <div className='p-10'>
            <form onSubmit={handleSubmit}>
                <div className="space-y-5 lg:space-y-6">
                    <CustomInput important={true} name="value" label="Zone Name" validate={validate} error={errors} reduxState={reduxZone?.apiJson} setAction={setZoneMasterApiJson} />
                    {row?.[_id] && <SearchableSelect name="status" label="Status" selectionType={selectionType} api={searchGeneral} dynamicSearch={{ 'usedBy': 'status' }} getFieldName={'value'} validate={validate} error={errors} reduxState={reduxZone?.apiJson} setAction={setZoneMasterApiJson} onChange={handleStatusChange} />}
                    <div className='flex gap-3 justify-end'>
                        <CustomButton text={'Cancel'} variant='flat' onClick={handleClose} />
                        <CustomButton type={'submit'} className={''} text={row?._id ? 'Update' : 'Submit'} loading={loading} />
                    </div>
                </div>
            </form>
        </div>
    )
}
