import React, { useEffect, useState } from 'react'
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect'
import { searchBuilding, searchUser, updateBuilding, updateZone } from '../../../Constant/Api/Api'
import CustomButton from '../../../Component/ui/buttons/custom-button'
import SearchableMultiSelect from '../../../Component/ui/form/select/searchable-multi-select'
import { useDispatch, useSelector } from 'react-redux'
import { setBuildingMasterApiJson } from '../../../Store/Action/master/building-master/building-master-action'
import useValidation from '../../../Hooks/useValidation'
import useCustomAlertController from '../../../Hooks/use-custom-alert'
import { validationSchema } from '../../../Utils/validators/validationSchema'
import { HitApi } from '../../../Store/Action/Api/ApiAction'
import { useModal } from '../../../shared/modal-views/use-modal'
import useDynamicLoading from '../../../Hooks/use-dynamic-loading'


export const builingMasterSchema = {
    usedBy: validationSchema.string()?.optional()
};

export default function BuildingSearchAndAdd({ row, ApiHit }) {
    const dispatch = useDispatch()
    const reduxBuilding = useSelector(state => state.BuildingMasterReducer)
    const { errors, validate } = useValidation(builingMasterSchema);
    const { showCustomAlert } = useCustomAlertController()
    const { openModal, closeModal } = useModal();
    const [selectedBuilding, setSelectedBuilding] = useState(null)
    const { loadingState, setDynamicLoading } = useDynamicLoading()

    useEffect(() => {
        
    }, [])
    
    const handleSubmit = () => {
        var json = row
        Object.assign(json, { usedBy: selectedBuilding?.map((ele) => ele?._id) })
        const validationErrors = validate(json);


        if (Object.keys(validationErrors).length === 0) {
            setDynamicLoading({ addBuilding: true })
            HitApi(json, updateZone).then((res) => {
                showCustomAlert(res)
                closeModal()
                setDynamicLoading({ addBuilding: false })
                if(ApiHit){ApiHit()}
            })
        }

    }

    const handleChange = (e) => {

        var json = row
        var usedById = []
        e?.map((ele) => {
            usedById?.push({ _id: ele?._id })
        })

        setSelectedBuilding(usedById)
    }


    // callBackJson={{ _id: 'value', buildingName: 'label'}}
    // callBackJson={{ _id: 'this value will be stored in db', buildingName: 'this value will be shown to user'}}

    return (
        <div className='p-10 '>
            <div className='h-80 flex justify-between flex-col'>
                <SearchableMultiSelect name="usedBy" label={'Search Building'} validate={validate} error={errors} getFieldName={'buildingName'} api={searchBuilding} callBackJson={{ _id: 'value', buildingName: 'label'}} reduxState={row} onChange={handleChange} />
                <CustomButton type={'submit'} text={'Add Building'} onClick={handleSubmit} loading={loadingState?.doc?.addBuilding} />
            </div>
        </div>
    )
}
