import { CreatePagination, GetPageCount } from "../../../../Utils/Utils"

export const CompileDifferenceData = (data, pagination) => {
    return new Promise((resolve, reject) => {
        Object.assign(pagination, { number: isNaN(pagination?.number) ? 1 : pagination?.number })

        console.log('data', data);

        var mergeData = [
            ...data?.extraTags.map((ele) => ({ ...ele, checkStatus: 'extra' })),
            ...data?.missingTags.map((ele) => ({ ...ele, checkStatus: 'missing' }))
        ];
        mergeData?.map((ele, index) => Object.assign(ele, { index: GetPageCount(pagination, index) }))

        var MainData = CreatePagination(mergeData, 10)

        var finalData = {
            data: MainData,
            totalElements: mergeData?.length,
            totalPages: MainData?.length
        }
        resolve(finalData)
    })
}