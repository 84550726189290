import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { addBatch, searchProduct, updateBatch } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { inventoryMasterVariable as variable } from '../../../Constant/variables/master/inventory-master/inventory-master.variable';
import { setInventoryMasterApiJson, setInventoryMasterData } from '../../../Store/Action/master/inventory-master/inventory-master-action';
import { inventoryMasterSchema } from '../../../Utils/validators/master/inventory-master/inventory-master-scheema';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import useAlertController from '../../../Hooks/use-alert-controller';
import { useNavigate } from 'react-router-dom';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';


export default function AddInventoryMaster({ row, closeModal, ApiHit }) {
    const { showCustomAlert } = useAlertController();
    var dispatch = useDispatch()
    const reduxInventory = useSelector(state => state.InventoryMasterReducer)
    const { errors, validate } = useValidation(inventoryMasterSchema);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    useEffect(() => {
        if (row?._id) {
            loadDefault(row)

            var json = [{ name: 'product_id', value: row?.product_id, label: row?.product_id?.productName }]
            dispatch(setSearchableSelectSelectedData(json))
        }
    }, [])

    const loadDefault = (row) => {
        var json = reduxInventory?.apiJson
        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        var newJson = {
            "product_id": row.product_id?._id
        }
        Object.assign(json, newJson)

        dispatch(setInventoryMasterApiJson(json))
    }


    const handleSubmit = (e) => {

        e.preventDefault();
        var json = reduxInventory?.apiJson;
        const validationErrors = validate(json);


        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const apiCall = row?._id ? updateBatch : addBatch;
            const updatedJson = { ...json, _id: row?._id, status: json?.status || 'active', movementStatus: json?.movementStatus || 'active' };

            HitApi(updatedJson, apiCall).then((result) => {
                setLoading(false);
                if (result?.status === 200 || result?.status === 201) {
                    if (ApiHit) { ApiHit() }

                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                    });
                    navigate('/master/inventory');
                    handleClose()


                } else if (result?.status === 409) {
                    showCustomAlert({
                        type: 'error',
                        title: 'Error',
                        message: result?.error?.message
                    });
                } else {
                    alert(result.message);
                }
            });
        }
    }


    const handleOnChange = (e) => {
        const { _id, label, value } = e

        let oldJson = reduxInventory?.apiJson
        let newJson = { ...oldJson, "product_id": e?._id }
        dispatch(setInventoryMasterApiJson(newJson))

    }



    const handleClose = () => {

        dispatch(setInventoryMasterData(null))
        dispatch(setInventoryMasterApiJson({}))
        closeModal()
    }



    return (
        <div className='p-10'>
            <form onSubmit={handleSubmit}>
                <div className="space-y-5 lg:space-y-6">
                    <SearchableSelect name="product_id" label="Product" api={searchProduct} getFieldName={'productName'} onChange={handleOnChange} error={errors} validate={validate} />
                    <CustomInput important={true} name="batchName" label="Batch Name" value={reduxInventory?.apiJson?.batchName} error={errors} reduxState={reduxInventory?.apiJson} setAction={setInventoryMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="batchNumber" label="Batch Number" value={reduxInventory?.apiJson?.batchNumber} error={errors} reduxState={reduxInventory?.apiJson} setAction={setInventoryMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="totalInventory" type={'number'} label="Total Inventory" value={reduxInventory?.apiJson?.totalInventory} error={errors} reduxState={reduxInventory?.apiJson} setAction={setInventoryMasterApiJson} validate={validate} />
                    <div className='flex gap-3 justify-end'>
                        <CustomButton text={'Cancel'} variant='flat' onClick={closeModal} />
                        <CustomButton type={'submit'} text={row?._id ? 'Update' : 'Submit'} loading={loading} />
                    </div>
                </div>
            </form>
        </div>
    )
}
