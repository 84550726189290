import { inboundText, outboundText } from "../../Constant/Common/common-variable"

export const CompileOutboundJson = (json) => {
    return new Promise((resolve, reject) => {
      var tj = { ...json }
      var tpIds = []
      json?.productIds?.map((ele) => {
        let tpEntry = {
          quantity: ele?.quantity,
          status: "ORDER_INITIATED",
          productId: ele?.productId?._id
        };
        if (json?.orderType === inboundText) {
          tpEntry.zoneIds = ele?.zoneIds?._id;
          tpEntry.locationIds = ele?.locationIds?._id;
        }
        tpIds.push(tpEntry);

      })
      var tvIds = []
      json?.vehicleIds?.map((ele) => {
        tvIds.push({
          vehicleNumber: ele?.vehicleId?.vehicleNumber,
          vehicleId: ele?.vehicleId?._id,
        })
      })
      tj.productIds = tpIds
      tj.vehicleIds = tvIds

    //   Object.assign(tj, { orderDateTime: new Date(json?.orderDateTime)?.getTime(), expectedArrival: new Date(json?.expectedArrival)?.getTime() })
      resolve(tj)
    })
  }