import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { HeaderCell } from "../../../Component/ui/table";
import { Text } from "rizzui";
import { HitApi } from "../../../Store/Action/Api/ApiAction";
import { getDocCount } from "../../../Constant/Api/Api";

export const GetProductColumn = (parentRow) => {
    const [rows, setRows] = useState({});

    const updateRowPendingQty = (rowIndex, newPendingQty) => {
        setRows((prevRows) => ({
            ...prevRows,
            [rowIndex]: {
                ...prevRows[rowIndex],
                pendingQty: newPendingQty,
            },
        }));
    };

    const renderCell = (value, row, content) => (
        rows[row.index]?.loading ? <Skeleton /> : content
    );

    const staticColumns = [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Code" />,
            dataIndex: 'productCode',
            key: 'productCode',
            width: 50,
            render: (value, row) => renderCell(value, row, <Text>{row?.productId?.productCode || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Name" />,
            dataIndex: 'productName',
            key: 'productName',
            width: 50,
            render: (value, row) => renderCell(value, row, <Text>{row?.productId?.productName || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Grade" />,
            dataIndex: 'grade',
            key: 'grade',
            width: 50,
            render: (value, row) => renderCell(value, row, <Text>{row?.productId?.grade || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Qty" />,
            dataIndex: 'quantity',
            key: 'quantity',
            width: 50,
            render: (value, row) => renderCell(value, row, <Text>{row?.quantity || '---'}</Text>),
        }
    ];

    const dynamicColumns = generateDynamicColumns(renderCell, parentRow, updateRowPendingQty, rows);

    return [...staticColumns, ...dynamicColumns];
};

const generateDynamicColumns = (renderCell, parentRow, updateRowPendingQty, rows) => [
    {
        title: <HeaderCell title="Picked Qty" />,
        dataIndex: 'pickedQty',
        key: 'pickedQty',
        width: 50,
        render: (value, row) => renderCell(value, row, <InventoryDisplay row={row} parentRow={parentRow} updateRowPendingQty={updateRowPendingQty} />),
    },
    {
        title: <HeaderCell title="Pending Qty" />,
        dataIndex: 'pendingQty',
        key: 'pendingQty',
        width: 50,
        render: (value, row) => renderCell(value, row, <Text>{rows[row.index]?.pendingQty || row.pendingQty || '---'}</Text>),
    }
];

const InventoryDisplay = ({ row, parentRow, updateRowPendingQty }) => {
    const [inventoryData, setInventoryData] = useState({
        pickedQty: 0,
        pendingQty: row?.quantity || 0,
        loading: true,
    });

    useEffect(() => {
        if (row?.productId?._id) {
            const json = {
                page: 1,
                limit: 1,
                search: {
                    product_id: row?.productId?._id,
                    orderId: parentRow?._id,
                    opreationStatus: parentRow?.orderStatus,
                },
                collectionName: "tagCollectiontrail",
            };

            HitApi(json, getDocCount)
                .then((result) => {
                    if (result) {
                        const pickedQty = result?.count || 0;
                        const pendingQty = row?.quantity - pickedQty;

                        setInventoryData({
                            pickedQty,
                            pendingQty,
                            loading: false,
                        });

                        if (updateRowPendingQty) {
                            updateRowPendingQty(row.index, pendingQty);
                        }
                    }
                })
                .catch(() => {
                    setInventoryData((prev) => ({ ...prev, loading: false }));
                });
        } else {
            setInventoryData((prev) => ({ ...prev, loading: false }));
        }
    }, [row, parentRow, updateRowPendingQty]);

    if (inventoryData.loading) {
        return <Skeleton />;
    }

    return (
        <Text>
            {inventoryData.pickedQty}
        </Text>
    );
};

export default GetProductColumn;
