/* eslint-disable no-duplicate-imports */
import React, { useMemo, useState } from 'react';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SortableOverlay } from './dnd-sortable-overly';
import SortableItem, { DragHandle } from './dnd-sortable-item';


const SortableList = ({ items, onChange, renderItem, children }) => {
  const [active, setActive] = useState(null);

  const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>{children}</SortableContext>
      {/* Uncomment below if you want to use SortableOverlay */}
      {/* {activeItem ? <SortableOverlay>{renderItem(activeItem)}</SortableOverlay> : null} */}
    </DndContext>
  );
};

// Add Item and DragHandle to SortableList as properties
SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
SortableList.Overlay = SortableOverlay;

export default SortableList;
