import { GetPageCount } from "../../../Utils/Utils"

export const CompileOrderData = (data, pagination) => {
    return new Promise((resolve, reject) => {

        var td = {
            content: data?.content?.map((ele, index) => Object.assign(ele, { index: GetPageCount(pagination, index) })),
            totalElements: data?.totalElements, number: data?.number + 1, totalPages: data?.totalPages,
        }
        resolve(td)
    })

}

export const CompileDynamicDataLoad = (data) =>{
    return new Promise((resolve, reject)=>{
        var td = []
        data?.content?.map((ele, index) =>
            td.push({label:ele?.value, value : ele?._id, _id:ele?._id, ele : ele})
        )
        resolve(td)
    })
}