import React, { useEffect, useState } from 'react'
import PageHeader from '../../../shared/page-header'
import { ScreenName } from '../../../Constant/Screen/Screen'
import { setCustomerMasterData } from '../../../Store/Action/master/customer-master/customer-master-action';
import { useDispatch, useSelector } from 'react-redux';
import { useColumn } from '../../../Hooks/use-column';
import { useMemo } from 'react';
import { GetCustomerMasterColumns, getCustomerMasterColumns } from './customer-column';
import { useModal } from '../../../shared/modal-views/use-modal';
import { routes } from '../../../config/routes';
import useAlertController from '../../../Hooks/use-alert-controller';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { searchCustomer } from '../../../Constant/Api/Api';
import { CompileCustomerMaster } from './promise/customer-master-promise';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import { deactiveText } from '../../../Constant/Common/common-variable';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { TableClass } from '../../../Constant/Classes/Classes';



function CustomerMaster() {
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const dispatch = useDispatch()

  const reduxCustomer = useSelector(state => state.CustomerMasterReducer)

  const { showCustomAlert } = useAlertController();

  useEffect(() => {
    if (reduxCustomer?.doc === null) {
      loadData('init')
    }
  }, [])

  const loadData = (type) => {
    var json = reduxCustomer?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
      Object.assign(json.search, { status: { "$ne": deactiveText } })
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true)
    HitApi(json, searchCustomer).then((result) => {
      if (result?.success !== false) {
        CompileCustomerMaster(result, reduxPagination?.doc).then((compiledData) => {
          dispatch(setCustomerMasterData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }));
        });
      } else {
        dispatch(setCustomerMasterData([]));
      }
      setLoading(false)
    });
  }

  const columns = useMemo(() => GetCustomerMasterColumns(loadData))
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

  return (
    <div>
      <div>
        <PageHeader
          screen={ScreenName?.customerMaster}
          href={routes?.panel?.master?.createCustomer}
          columns={columns}
          checkedColumns={checkedColumns}
          setCheckedColumns={setCheckedColumns}
          json={reduxCustomer?.searchJson}
          setAction={setCustomerMasterData}
          ApiHit={loadData}
        />
      </div>
      <div className='w-full overflow-scroll'>
        <ControlledTable
          screen={ScreenName?.productMaster}
          variant="modern"
          isLoading={loading}
          showLoadingText={true}
          data={reduxCustomer?.doc?.content}
          columns={visibleColumns}
          className={TableClass}
          json={reduxCustomer?.searchJson}
          setAction={setCustomerMasterData}
          ApiHit={loadData}
        />
      </div>
    </div>

  )
}

export default CustomerMaster