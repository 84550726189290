import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomDatePicker from '../../../Component/ui/date-and-time/date-picker'
import { setOrderApiJson } from '../../../Store/Action/order/order-action'
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect'
import CustomInput from '../../../Component/ui/form/input/custom-input'
import { RoundedCard } from '../../../Constant/Classes/Classes'
import { Button } from 'rizzui'
import { Colors } from '../../../Constant/Colors/Color'
import { PiPlusCircleBold, PiTipJar } from 'react-icons/pi'
import cn from '../../../Utils/class-names'
import AddedProduct from './add-product/added-product'
import { useModal } from '../../../shared/modal-views/use-modal'
import SearchAndAddProduct from './add-product/search-and-add-product'
import CustomSwitch from '../../../Component/ui/form/switch/custom-switch'
import CustomButton from '../../../Component/ui/buttons/custom-button'
import { HitApi } from '../../../Store/Action/Api/ApiAction'
import { addOrder, searchBuilding, updateOrder } from '../../../Constant/Api/Api'
import { _id, inboundText, outboundText } from '../../../Constant/Common/common-variable'
import BillingAndShipping from './billing-and-shipping/billing-and-shipping'
import { getAuthenticatedUser } from '../../../Storage/Storage'
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action'
import AddedVehicle from './add-vehicle/added-vehicle'
import SearchAndAddVehicle from './add-vehicle/search-and-add-vehicle'
import { TimeDiffDay } from '../../../Utils/Utils'
import useValidation from '../../../Hooks/useValidation'
import { orderSchema } from '../../../Utils/validators/order/order.schema'
import useDynamicLoading from '../../../Hooks/use-dynamic-loading'
import { routes } from '../../../config/routes'
import { searchOrder } from '../../../Constant/Api/Api'

export default function CreateOrder() {
  const dispatch = useDispatch()
  const reduxOrder = useSelector(state => state.OrderReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const { errors, validate } = useValidation(orderSchema(reduxOrder?.apiJson));
  const { loadingState, setDynamicLoading } = useDynamicLoading()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const user = getAuthenticatedUser()
  const { openModal } = useModal();

  const [mode, _id, orderType] = window.location.href?.split("/")?.slice(4, 7);

  useEffect(() => {




    if (mode === 'edit') {
      loadOrderData(_id)
    } else {
      if (_id && orderType && !reduxOrder?.dispatchFrom && user) {

        loadData(_id, orderType)
      }
    }

    console.log('reduxSelect', reduxSelect);


  }, [])

  const loadOrderData = (_id) => {
    var json = { page: 1, limit: 1, search: { _id: _id } }

    HitApi(json, searchOrder).then((result) => {

      var res = result?.content?.[0]
      if (res) {
        // loadData(res?.dispatchFrom, res?.orderType)
        handleLoadAndMergeData(res)
      }
    })
  }

  const handleLoadAndMergeData = (res) => {
    var oldSelectData = reduxSelect?.selected
    var data = { ...res }
    Object.assign(data, {
      shipTo: res?.dispatchTo,
      shipToName: res?.dispatchToName,
      saleType: res?.saleType
    })

    var tempSelected = [
      ...oldSelectData,
      { label: data?.dispatchFromName, value: data?.dispatchFrom, name: 'billToName', selectionType: 'billToName' },
      { label: data?.dispatchToName, value: data?.dispatchTo, name: 'shipToName', selectionType: 'shipToName' },
      { label: data?.dispatchFromName, value: data?.dispatchFrom, name: 'billTo', selectionType: 'billToName' },
      { label: data?.dispatchToName, value: data?.dispatchTo, name: 'shipTo', selectionType: 'shipToName' },
      { label: data?.saleType, value: data?.saleType, name: 'saleType', selectionType: 'saleType' },
    ]

    console.log('tempSelected', tempSelected);

    dispatch(setSearchableSelectSelectedData(tempSelected))
    dispatch(setOrderApiJson(data))
  }

  const loadData = (_id, orderType) => {
    var json = { page: 1, limit: 1, search: { _id: _id } }
    var apiJson = reduxOrder?.apiJson
    // var selected = reduxSelect?.selected

    let selected = reduxSelect?.selected || [];




    HitApi(json, searchBuilding).then((result) => {

      var res = result?.content?.[0]
      if (res && orderType === inboundText) {
        Object.assign(apiJson, {
          dispatchFrom: _id,
          dispatchFromName: res?.buildingName,
          orderStatus: 'ORDER_INITIATED',
          orderType: orderType,
          billTo: _id,
          billToName: res?.buildingName,
          shipTo: _id,
          shipToName: res?.buildingName,
          currentLocation: _id
        })

        // MArtch and compare, if found same data then do nothing, else add old data

        const tempSelected = [
          { label: res?.buildingName, value: res?._id, name: 'billToName', selectionType: 'billToName' },
          { label: res?.buildingName, value: res?._id, name: 'shipToName', selectionType: 'shipToName' },
        ];

        // Function to update selected data
        tempSelected.forEach((newItem) => {
          const index = selected.findIndex(
            (item) => item.name === newItem.name && item.selectionType === newItem.selectionType
          );

          if (index !== -1) {
            // Replace the existing item
            selected[index] = newItem;
          } else {
            // Add new item if it doesn't exist
            selected.push(newItem);
          }
        });

        dispatch(setSearchableSelectSelectedData(selected))
        dispatch(setOrderApiJson(apiJson))
      } else if (res && orderType === 'OUTBOUND') {
        Object.assign(apiJson, { dispatchFrom: _id, dispatchFromName: res?.buildingName, orderStatus: 'ORDER_INITIATED', orderType: orderType, currentLocation: _id })
        dispatch(setOrderApiJson(apiJson))
      }
    })
  }

  const handleClick = () => {
    var json = { ...reduxOrder?.apiJson };
    if (!json?.productIds) { json.productIds = [] }
    var product = [...json.productIds];
    const newIndex = product.length;
    product.push({});
    json.productIds = product;
    dispatch(setOrderApiJson(json));
    openModal({ view: <SearchAndAddProduct index={newIndex} />, customSize: '800px', title: 'Add Product', hide: true });
  }

  const handleAddVehicle = () => {
    var json = { ...reduxOrder?.apiJson };
    if (!json?.vehicleIds) { json.vehicleIds = [] }
    dispatch(setOrderApiJson(json));
    openModal({ view: <SearchAndAddVehicle />, customSize: '800px', title: 'Add Vehicle', hide: true });
  }

  const handleSwitchClick = (e) => {
    var json = reduxOrder?.apiJson
    if (e === false) {
      delete json?.batchNumber
      dispatch(setOrderApiJson(json));
    }

  }

  const handleSubmit = () => {
    var json = { ...reduxOrder?.apiJson }

    Object.assign(json, { status: 'Active' })
    CompileJson(json).then((FinalJson) => {

      const validationErrors = validate(FinalJson);
      if (Object.keys(validationErrors).length === 0) {

        setDynamicLoading({ fullPage: true })
        HitApi(FinalJson, mode === 'edit' ? updateOrder : addOrder).then((result) => {

          if (result) {
            setDynamicLoading({ fullPage: false })
            setTimeout(() => {
              if (json?.orderType === inboundText) {
                window.location.href = routes?.panel?.order?.inboundOrder
              } else {
                window.location.href = routes?.panel?.order?.outboundOrder
              }
            }, 200);
          }
        })
      }
    })

  }

  const CompileJson = (json) => {
    return new Promise((resolve, reject) => {
      var tj = { ...json }
      var tpIds = []
      json?.productIds?.map((ele) => {
        let tpEntry = {
          quantity: ele?.quantity,
          status: "ORDER_INITIATED",
          productId: ele?.productId?._id
        };
        if (json?.orderType === inboundText) {
          tpEntry.zoneIds = ele?.zoneIds?._id;
          tpEntry.locationIds = ele?.locationIds?._id;
        }
        tpIds.push(tpEntry);

      })
      var tvIds = []
      json?.vehicleIds?.map((ele) => {
        tvIds.push({
          vehicleNumber: ele?.vehicleNumber,
          vehicleId: ele?.vehicleId?._id,
        })
      })

      if (json?.orderType === inboundText) {
        Object.assign(tj, {
          dispatchTo: json?.dispatchFrom,
          dispatchToName: json?.dispatchFromName,
        })
      } else if (json?.orderType === outboundText) {
        Object.assign(tj, {
          dispatchTo: json?.billTo,
          dispatchToName: json?.billToName,
        })
      }

      tj.productIds = tpIds
      tj.vehicleIds = tvIds
      Object.assign(tj, { orderDateTime: new Date(json?.orderDateTime)?.getTime(), expectedArrival: new Date(json?.expectedArrival)?.getTime() })
      resolve(tj)
    })
  }


  const handleStartDate = (date, name) => {
    setStartDate(date)
    var json = reduxOrder?.apiJson
    Object.assign(json, { [name]: date })

    if (json?.orderDateTime && json?.expectedArrival) {
      var TimeDiff = TimeDiffDay(json?.expectedArrival, json?.orderDateTime)
      if (TimeDiff <= -1) {
        delete json?.expectedArrival
      }


    }

    dispatch(setOrderApiJson(json))

  }
  const handleEndDate = (date, name) => {
    var json = reduxOrder?.apiJson
    Object.assign(json, { [name]: date })
    dispatch(setOrderApiJson(json))

  }

  // 

  return (
    <div>
      {
        reduxOrder?.apiJson?.dispatchFrom && <div>
          <div className={cn(RoundedCard, 'my-5')}>
            <div className='grid grid-cols-4 gap-4 '>
              <CustomDatePicker name={'orderDateTime'} type={'normalDate'} validate={validate} error={errors} minDate={Date.now()} onChange={(date) => handleStartDate(date, 'orderDateTime')} label={'Order Date'} reduxState={reduxOrder?.apiJson} setAction={setOrderApiJson} />
              <CustomDatePicker name={'expectedArrival'} type={'normalDate'} validate={validate} error={errors} minDate={startDate || Date.now()} onChange={(date) => handleEndDate(date, 'expectedArrival')} label={'Expected Arrival Date'} reduxState={reduxOrder?.apiJson} setAction={setOrderApiJson} />
              <CustomInput name={'orderStatus'} label={'Order Status'} validate={validate} error={errors} reduxState={reduxOrder?.apiJson} disabled />
              <CustomInput name={'orderType'} label={'Order Type'} validate={validate} error={errors} reduxState={reduxOrder?.apiJson} disabled />
            </div>
          </div>
          <div><BillingAndShipping orderType={orderType} validate={validate} errors={errors} /></div>
          {/* Add Product */}
          <div className={cn(RoundedCard, 'my-5 ')}>
            <div className='flex justify-between items-end'>
              <div className='flex space-x-10'>
                <CustomSwitch name="enableBatch" label={'Batch'} position={'top'} value={reduxOrder?.apiJson?.enableBatch} reduxState={reduxOrder?.apiJson} setAction={setOrderApiJson} onClick={handleSwitchClick} />
                {reduxOrder?.apiJson?.enableBatch && <CustomInput name={'batchNumber'} validate={validate} error={errors} label={'Batch Number'} reduxState={reduxOrder?.apiJson} setAction={setOrderApiJson} />}
              </div>
              <div> <Button as="span" type="button" className="w-full @lg:w-auto cursor-pointer" onClick={() => handleClick()} style={{ background: Colors.LOGINRED }} > <PiPlusCircleBold className="me-2 h-4 w-4" /> Add Product </Button> </div>
            </div>
            {errors?.['productIds'] && <span className='text-red text-[13px] mt-0.5 rizzui-input-error-text'>{errors?.['productIds']}</span>}
            <div><AddedProduct validate={validate} error={errors} /></div>
          </div>
          {reduxOrder?.apiJson?.orderType === outboundText && <div className={cn(RoundedCard, 'my-5 ')}>
            <div className='flex justify-end items-end'>
              <div> <Button as="span" type="button" className="w-full @lg:w-auto cursor-pointer" onClick={() => handleAddVehicle()} style={{ background: Colors.LOGINRED }} > <PiPlusCircleBold className="me-2 h-4 w-4" /> Add Vehicle </Button> </div>
            </div>
            {errors?.['vehicleIds'] && <span className='text-red text-[13px] mt-0.5 rizzui-input-error-text'>{errors?.['vehicleIds']}</span>}
            <div><AddedVehicle /></div>
          </div>}
          <div className='flex gap-3 justify-end'>
            <CustomButton type={'submit'} className={''} text={mode === 'edit' ? 'Update' : 'Submit'} loading={loadingState?.doc?.fullPage} onClick={handleSubmit} />
          </div>
        </div>
      }
    </div>
  )
}
