import { GetFullYear, GetPageCount } from "../../../Utils/Utils"

export const CompileDashboardData = (data, pagination) => {
    return new Promise((resolve, reject) => {
        Object.assign(pagination, { number: isNaN(pagination?.number) ? 1 : pagination?.number })
        var td = {
            content: data?.content?.map((ele, index) => Object.assign(ele, { index: GetPageCount(pagination, index), dateTime: GetFullYear(ele?.updatedAt, ['date', 'month', 'year']) })),
            totalElements: data?.totalElements, number: data?.number + 1, totalPages: data?.totalPages,
        }
        resolve(td)
    })
}

export const MergeOrConditions = (data, pagination) => {
    return new Promise((resolve, reject) => {
        const mergedOrConditions = new Map();
        let searchConditions = {};

        console.log('data inside merge conditon', data);

        // Loop through each item in data
        data.forEach((item) => {
            if (item.isParent) {
                // Skip parent elements
                searchConditions = item?.query?.search
                return;
            }

            // Collect $or conditions from the root item
            if (item.query?.search?.$or) {
                item.query.search.$or.forEach((condition) => {
                    const key = JSON.stringify(condition);
                    if (!mergedOrConditions.has(key)) {
                        mergedOrConditions.set(key, condition);
                    }
                });
                // Add other search conditions if present
                searchConditions = { ...searchConditions, ...item.query.search };
                delete searchConditions.$or; // Remove $or to avoid duplication
            }

            // Collect $or conditions from each child if available
            if (Array.isArray(item.child)) {
                item.child.forEach((child) => {
                    if (child.query?.search?.$or) {
                        child.query.search.$or.forEach((condition) => {
                            const key = JSON.stringify(condition);
                            if (!mergedOrConditions.has(key)) {
                                mergedOrConditions.set(key, condition);
                            }
                        });
                        // Add other search conditions from children if present
                        searchConditions = { ...searchConditions, ...child.query.search };
                        delete searchConditions.$or; // Remove $or to avoid duplication
                    }
                });
            }
        });

        console.log('searchConditions', searchConditions, data);

        // Prepare the final merged query object with or without $or based on its presence
        const finalSearch = mergedOrConditions.size > 0
            ? { $or: Array.from(mergedOrConditions.values()), ...searchConditions }
            : searchConditions;

        // Construct the merged query with pagination defaults if missing
        const mergedQuery = {
            page: Number.isNaN(Number(pagination?.number)) ? 1 : pagination?.number,
            limit: pagination?.limit || 10,  // default limit if not provided
            search: finalSearch
        };
        resolve(mergedQuery);
    });
};
