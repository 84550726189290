import React, { useEffect, useState } from 'react'
import Building from './building/building'
import Zone from './zone/zone'
import Location from './location/location'
import Reader from './readers/reader'
import { useSelector } from 'react-redux'
import ReaderProfile from './reader-profile/reader-profile'

export default function MappingMaster() {
    const reduxMappingMaster = useSelector(state => state.MappingMasterReducer)

    useEffect(()=>{

    },[reduxMappingMaster])
    
    return (
        <div className='grid grid-cols-5 gap-4'>
            <div><Building /></div>
            {reduxMappingMaster?.mappingJson?.building?.show?<div><Zone /></div> : null}
            {reduxMappingMaster?.mappingJson?.zone?.show?<div ><Location /></div> : null}
            {reduxMappingMaster?.mappingJson?.location?.show?<div ><Reader /></div> : null}
            {reduxMappingMaster?.mappingJson?.reader?.show?<div ><ReaderProfile /></div> : null}
        </div>
    )
}
