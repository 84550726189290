import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setServiceRequestData } from '../store/Action/ServiceMasterAction';
import CustomJsonEditor from '../../../../Component/ui/editor/json-editor';
import CustomXmlEditor from '../../../../Component/ui/editor/xml-editor';
import { Colors } from '../../../../Constant/Colors/Color';

function Body() {

    const ServiceMasterReducer = useSelector(state => state.ServiceMasterReducer);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('');

    const tabs = ['raw', 'XML'];

    useEffect(() => {
        if (activeTab === '') {
            if (ServiceMasterReducer?.requestDoc?.request?.body?.options?.raw?.language === 'XML' || ServiceMasterReducer?.requestDoc?.request?.body?.options?.raw?.language === 'xml') {
                setActiveTab('XML')
            }
            else {
                setActiveTab('raw')
            }
        }
    }, [activeTab, ServiceMasterReducer])

    const onChange = (value) => {
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.body.options.raw.language = activeTab
        oldJson.request.body.raw = activeTab === 'raw' ? value : value?.toString()
        dispatch(setServiceRequestData(oldJson))
    }

    const onChangeMode = (tab) => {
        setActiveTab(tab)
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.body.mode = tab
        oldJson.request.body.options.raw = { language: tab }
        dispatch(setServiceRequestData(oldJson))
    }



    return (
        <div>
            <div className="flex gap-10 ml-5 mt-2">
                {tabs.map((tab) => (
                    <div className='flex items-center gap-2'>
                        <p>{tab}</p>
                        <input style={{ color: Colors.LOGINRED }} onClick={() => onChangeMode(tab)} type='radio' checked={activeTab === tab} />
                    </div>
                ))}
            </div>
            <div className='m-5'>
                {
                    activeTab !== '' &&
                        activeTab === 'raw' ?
                        <CustomJsonEditor onChange={(value) => onChange(value, 'input')} json={ServiceMasterReducer?.requestDoc?.request?.body?.raw === "" ? {} : ServiceMasterReducer?.requestDoc?.request?.body?.raw} />
                        :
                        activeTab === 'XML' || activeTab === 'xml' ?
                            <CustomXmlEditor onChange={(value) => onChange(value, 'input')} xml={ServiceMasterReducer?.requestDoc?.request?.body?.raw === "" ? "" : ServiceMasterReducer?.requestDoc?.request?.body?.raw} />
                            :
                            ''
                }
            </div>
        </div>
    )
}

export default Body