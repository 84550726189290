import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { deleteCustomer, deleteGeneral, updateCustomer } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import AddCustomeMaster from '../../../Form/master/customer-master/add-customer-master';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes';
import useCustomAlertController from '../../../Hooks/use-custom-alert';
import { useModal } from '../../../shared/modal-views/use-modal';
import { deactiveText } from '../../../Constant/Common/common-variable';
import Status from '../../../Component/ui/common/status';


export const GetCustomerMasterColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({})
  const navigate = useNavigate()


  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, {status:deactiveText})
    try {
      const result = await HitApi(row, updateCustomer);
      showCustomAlert(result)
      
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
    
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

    return [
      {
        title: <HeaderCell title="#" />,
        dataIndex: 'index',
        key: 'index',
        width: 10,
        render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
      },
      {
        title: <HeaderCell title="Customer Name" className="font-extrabold" />,
        dataIndex: 'customerName',
        key: 'customerName',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Code" className="font-extrabold" />,
        dataIndex: 'customerCode',
        key: 'customerCode',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Group" className="font-extrabold" />,
        dataIndex: 'customerGroup',
        key: 'customerGroup',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Email" className="font-extrabold" />,
        dataIndex: 'customerEmail',
        key: 'customerEmail',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Type" className="font-extrabold" />,
        dataIndex: 'customerType',
        key: 'customerType',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Add1" className="font-extrabold" />,
        dataIndex: 'customerAddress1',
        key: 'customerAddress1',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Add2" className="font-extrabold" />,
        dataIndex: 'customerAddress2',
        key: 'customerAddress2',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Landmark" className="font-extrabold" />,
        dataIndex: 'customerLandmark',
        key: 'customerLandmark',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Country" className="font-extrabold" />,
        dataIndex: 'customerCountry',
        key: 'customerCountry',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer State" className="font-extrabold" />,
        dataIndex: 'customerState',
        key: 'customerState',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer City" className="font-extrabold" />,
        dataIndex: 'customerCity',
        key: 'customerCity',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer PostCode" className="font-extrabold" />,
        dataIndex: 'customerPostCode',
        key: 'customerPostCode',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Contact" className="font-extrabold" />,
        dataIndex: 'customerContact',
        key: 'customerPostCode',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer Gst" className="font-extrabold" />,
        dataIndex: 'customerGst',
        key: 'customerGst',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer PAN" className="font-extrabold" />,
        dataIndex: 'customerPan',
        key: 'customerPan',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer TAN" className="font-extrabold" />,
        dataIndex: 'customerTan',
        key: 'customerTan',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Customer VAT" className="font-extrabold" />,
        dataIndex: 'customerVat',
        key: 'customerVat',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Status" className="font-extrabold" />,
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (value, row) => <Status value={value}/>
      },
      {
        title: <HeaderCell title="Actions" className="font-extrabold" />,
        dataIndex: 'action',
        key: 'action',
        width: 300,
        render: (_, row) => renderCell(null, row, (
          <TableActions
            screen={ScreenName.customerMaster}
            row={row}
            href={routes?.panel?.master?.editCustomer}
            onDelete={(rowData) => handleDelete(rowData)}
            checkKeys={[]}
          />
        )),
      },
    ];
  
}
