import { compileChartData } from "../../../Utils/Utils";


export const CompileChartData = (data, type, date, SingleBarChartOptions, title) => {
    return new Promise((resolve, reject) => {

        if(data){
            const newData = compileChartData(data, type, date);
            const categories = newData.map(ele => ele?.label);
            const values = newData.map(ele => ele?.length);
    
            const newChartOption = {
                ...SingleBarChartOptions,
                xAxis: {
                    ...SingleBarChartOptions.xAxis,
                    categories: categories,
                },
                title: {
                    text: title || ''
                },
                series: [{
                    ...SingleBarChartOptions.series[0],
                    data: values,
                }],
            };
            resolve(newChartOption)
        }else{
            resolve(SingleBarChartOptions)
        }

        

    })
}