// export const IP = "api.hindalco.headsupcorporation.com"
export const IP = "192.168.0.104:9090"
export const BaseUrl = `http://${IP}/device/api`



export const Protocol = 'https://'




export const searchApiService = `${BaseUrl}/searchApiService`
export const addApiService = `${BaseUrl}/addApiService`
export const updateApiService = `${BaseUrl}/updateApiService`
export const deleteApiService = `${BaseUrl}/deleteApiService`

export const searchRequest = `${BaseUrl}/searchRequest`
export const addRequest = `${BaseUrl}/addRequest`
export const deleteRequest = `${BaseUrl}/deleteRequest`
export const updateRequest = `${BaseUrl}/updateRequest`




