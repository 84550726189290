import { HitApi } from "../../../../Store/Action/Api/ApiAction";
import { searchApiService, searchRequest } from "../../api-service/constants/constant";

export const CompileDevices = (data) => {
    return new Promise((resolve, reject) => {
        
        var td = {
            content: data?.content?.map((ele, index) => Object.assign(ele, { index: index + 1, loading:false})),
            totalElements: data?.totalElements, number: data?.number + 1, totalPages: data?.totalPages,
        }
        resolve(td)
    })
}

export const GetService = (ele, handleResult) => {
    return new Promise((resolve, reject) => {
      var tempGlobalArr = [];
      var requests = [];
      var json = { page: 1, limit: 1, search: { _id: ele?._id } };
  
      HitApi(json, searchApiService).then((res) => {
        if (res?.content?.[0]) {
          tempGlobalArr = res?.content[0]?.globalVariables;
          requests = res?.content?.[0]?.requests;
  
          // Process each request one by one
          CallApi(requests, handleResult).then(() => {
            resolve(); // Once all requests are processed, resolve the main promise
          }).catch(error => reject(error)); // In case any request fails
        } else {
          reject('No content found'); // Reject if no content is found
        }
      }).catch(error => reject(error)); // Reject if initial API call fails
    });
  };
  
  const CallApi = (AllRequests, handleResult) => {
    return new Promise((resolve, reject) => {
      AllRequests?.forEach((element, index) => {
        // Add delay for each request
        setTimeout(() => {
          HandleRequestCall(element).then((res) => {
            handleResult(res); // Call the handler for each result one by one
          }).catch(error => console.error(error)); // Handle individual request error
  
          if (index === AllRequests.length - 1) {
            resolve(); // Once the last request is done, resolve the promise
          }
        }, index * 2000); // 2-second delay between requests
      });
    });
  };
  
  const HandleRequestCall = (request) => {
    const json = { page: 1, limit: 1, search: { _id: request?.requestId } };
    return new Promise((resolve, reject) => {
      HitApi(json, searchRequest).then((res) => {

        resolve(res); // Resolve each API call's result
      }).catch(error => reject(error)); // Reject if the API call fails
    });
  };
  