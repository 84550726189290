import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CycleCountInner from './cycle-count/cycle-count-inner'
import Inventory from './inventory/inventory'
import Difference from './difference/difference'

export default function CycleCount() {

    const dispatch = useDispatch()
    const reduxCycleCount = useSelector(state => state.CycleCountReducer)

    useEffect(() => {


    }, [])

    return (
        <div className='grid grid-cols-3 gap-4'>
            <div><Inventory/></div>
            <div><CycleCountInner/></div>
            <div><Difference/></div>
        </div>
    )
}
