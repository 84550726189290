export const SET_INBOUND_DATA = 'SET_INBOUND_DATA'
export const SET_INBOUND_API_JSON = 'SET_INBOUND_API_JSON'
export const SET_INBOUND_SEARCH_JSON = 'SET_INBOUND_SEARCH_JSON'
 export const SET_INBOUND_PRODUCT = 'SET_OUTBOUND_PRODUCT'
 export const SET_INBOUND_ADDED_VEHICLE = 'SET_INBOUND_ADDED_VEHICLE'
 export const SET_INBOUND_INVNETORY = 'SET_INBOUND_INVNETORY'




export const setInboundData = (data) => ({ type: SET_INBOUND_DATA, value: data})
export const setInboundApiJson = (data) => ({ type: SET_INBOUND_API_JSON, value: data })
export const setInboundSearchJson = (data) => ({ type: SET_INBOUND_SEARCH_JSON, value: data })
export const setInboundProduct = (data) => ({ type: SET_INBOUND_PRODUCT, value: data })
export const setInboundAddedVehicle = (data) => ({ type: SET_INBOUND_ADDED_VEHICLE, value: data})
export const setInboundInventory = (data) => ({ type: SET_INBOUND_INVNETORY, value: data })






