'use client';

import { NavLink } from "react-router-dom";
import SearchWidget from "../Component/search/search";
import StickyHeader from "./sticky-header";
import HeaderMenuRight from "./header-menu-right";


export default function Header() {
  return (
    <StickyHeader className="z-[990] 2xl:py-5 3xl:px-8 4xl:px-10">
      <div className="flex w-full max-w-2xl items-center">
        <SearchWidget />
      </div>
      <HeaderMenuRight />
    </StickyHeader>
  );
}
