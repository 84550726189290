import React, { useMemo } from 'react'
import ControlledTable from '../../../../Component/ui/table/custom-table'
import { GetAddedBuildingColumns, getBuildingZoneMasterColumns } from './zone-building-column'
import { useColumn } from '../../../../Hooks/use-column';
import { TableClass } from '../../../../Constant/Classes/Classes';

export default function ZoneBuilding({ row, ApiHit }) {
    const columns = useMemo(() => GetAddedBuildingColumns(row, ApiHit))
    const { visibleColumns } = useColumn(columns);

    return (
        <div className='p-10'>
            <ControlledTable
                variant="modern"
                isLoading={false}
                data={row?.usedBy}
                columns={visibleColumns}
                className={TableClass}
                disablePagination={true}
            />
        </div>
    )
}
