import { FileInput } from "rizzui";

export default function Upload({ label, onChange,error }) {
    return (
        <div>
            <label className="block text-[13px] font-bold">{label}</label>
            <FileInput onChange={onChange} />
            {error && <div className="text-red text-[13px] mt-0.5 rizzui-input-error-text">{error}</div>}
        </div>)
        ;
}