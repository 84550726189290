import React, { useEffect, useMemo } from 'react'
import PageHeader from '../../../shared/page-header'
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../../shared/modal-views/use-modal';
import { useColumn } from '../../../Hooks/use-column';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { searchTag } from '../../../Constant/Api/Api';
import { TableClass } from '../../../Constant/Classes/Classes';
import AddTagMaster from '../../../Form/master/tag-master/add-tag-master';
import { getTagMasterColumns } from './tag-cloumn';
import { CompileTagMaster } from './promise/tag-master-promise';
import { setTagMasterApiJson, setTagMasterData } from '../../../Store/Action/master/tag-master/tag-master-action';
import { routes } from '../../../config/routes';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { deactiveText } from '../../../Constant/Common/common-variable';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import { ScreenName } from '../../../Constant/Screen/Screen';

function TagMaster() {
  const dispatch = useDispatch()
  const reduxTag = useSelector(state => state.TagMasterReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const { openModal, closeModal } = useModal();
  const columns = useMemo(() => getTagMasterColumns())
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);
  const { loadingState, setDynamicLoading } = useDynamicLoading()

  useEffect(() => {
    if (reduxTag?.doc === null) {
      loadData('init')
    }
  }, [reduxTag])

  const loadData = (type) => {
    var json = reduxTag?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
      Object.assign(json.search, { status: { "$ne": deactiveText } })
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setDynamicLoading({ tag: true })
    HitApi(json, searchTag).then((result) => {

      if (result?.success !== false) {
        CompileTagMaster(result, reduxPagination?.doc).then((compiledData) => {
          dispatch(setTagMasterData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements
          }));
        });
      } else {
        dispatch(setTagMasterData([]));
        dispatch(setPagination({limit: json?.limit,totalPages: 1,number: 1,totalElements: 1}));
      }
      setDynamicLoading({ tag: false })
    });
  }

  return (
    <div>
      <PageHeader
        screen={ScreenName.tagMaster}
        disableCreate
        href={routes?.panel?.master?.addTagMaster}
        columns={columns}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        json={reduxTag?.searchJson}
        setAction={setTagMasterApiJson}
        ApiHit={loadData}
      />
      <ControlledTable
        variant="modern"
        isLoading={loadingState?.doc?.tag}
        data={reduxTag?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxTag?.searchJson}
        setAction={setTagMasterApiJson}
        ApiHit={loadData}
      />
    </div>
  )
}

export default TagMaster