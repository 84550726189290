import React from 'react'
import PageHeader from '../../shared/page-header'
import { ScreenName } from '../../Constant/Screen/Screen'
import AddUploadApk from '../../Form/apk-upload/add-upload-apk'
import { useModal } from '../../shared/modal-views/use-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { HitApi } from '../../Store/Action/Api/ApiAction';
import {  searchFile } from '../../Constant/Api/Api';
import { CompileApkUpload } from './promise/apk-upload-promise';
import { setPagination } from '../../Store/Action/Pagination/PaginationAction';
import { setApkUploadApiJson, setApkUploadData } from '../../Store/Action/apk-upload/apk-action';
import { useEffect } from 'react';
import { ApkUploadColumns } from '../../Store/Action/apk-upload/apk-column';
import { useColumn } from '../../Hooks/use-column';
import { useMemo } from 'react';
import ControlledTable from '../../Component/ui/table/custom-table';
import { TableClass } from '../../Constant/Classes/Classes';

function ApkUpload() {
  const reduxapkUpload = useSelector(state => state.ApkUploadReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { openModal, closeModal } = useModal();

  const loadData = (type) => {
    var json = reduxapkUpload?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true)
    HitApi(json, searchFile).then((result) => {
      if (result?.success !== false) {
        CompileApkUpload(result).then((compiledData) => {
          dispatch(setApkUploadData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }));
        });
      } else {
        dispatch(setApkUploadData([]));
        dispatch(setPagination({limit: json?.limit,totalPages: 1,number: 1,totalElements: 1}));
      }
      setLoading(false)
    });
  }
  const columns = useMemo(() => ApkUploadColumns(loadData))
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);
  useEffect(() => {
    if (reduxapkUpload?.doc === null) {
      loadData('init')
    }
  }, [])
  return (
    <div>
      <PageHeader
        screen={ScreenName?.uploadapk}
        children={<AddUploadApk closeModal={closeModal} ApiHit={loadData} />}
        title={'Upload Apk'}
        customSize={400}
        columns={columns}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        json={reduxapkUpload?.searchJson}
        setAction={setApkUploadApiJson}
        ApiHit={loadData}
      />
      <ControlledTable
        screen={ScreenName?.uploadapk}
        variant="modern"
        isLoading={loading}
        showLoadingText={true}
        data={reduxapkUpload?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxapkUpload?.searchJson}
        setAction={setApkUploadApiJson}
        ApiHit={loadData}
        />
    </div>
  )
}

export default ApkUpload