import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setDashboardDifferenceData } from '../../../../Store/Action/dashbaord/cycle-count/cycle-count-action';
import ControlledTable from '../../../../Component/ui/table/custom-table';
import { GetDifferenceColumn } from './difference-column';
import { useColumn } from '../../../../Hooks/use-column';
import { generateCycleCount } from '../../../../Constant/Api/Api';
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { CompileDifferenceData } from './difference-promiss';
import { CreatePagination, GetPageCount } from '../../../../Utils/Utils';

export default function Difference() {
  const dispatch = useDispatch()
  const reduxCycleCount = useSelector(state => state.CycleCountReducer)
  const reduxDashboard = useSelector((state) => state.DashboardReducer);
  const reduxPagination = useSelector(state => state.PaginationReducer);

  useEffect(() => {
    if (reduxDashboard?.apiJson?.selectedLocation?._id && reduxDashboard?.apiJson?.selectedBuilding?._id && reduxCycleCount?.difference === null) {
      loadDifference()
    }

  }, [reduxCycleCount])

  const loadDifference = () => {

    var json = { locationIds: reduxDashboard?.apiJson?.selectedLocation?._id, currentLocation :  reduxDashboard?.apiJson?.selectedBuilding?._id}
    
    HitApi(json, generateCycleCount).then((res) => {
      if(res?.data){
        CompileDifferenceData(res?.data, reduxPagination?.doc).then((CompiledData)=>{
          dispatch(setDashboardDifferenceData(CompiledData))
        })
      }
    })
  }

  const columns = useMemo(() => GetDifferenceColumn())
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

  return (
    <div>
      <ControlledTable
        columns={visibleColumns}
        data={reduxCycleCount?.difference?.data?.[reduxPagination?.doc?.number-1]}

      />
    </div>
  )
}
