import { ACTIVE, CANCEL, CANCELLED, DISPATCH, DISPATCHED, HOLD, IN_BUILDING, IN_TRANSIT, LOADING, ORDER_INITIATED, ORDER_PICKED, ORDER_PICKING, ORDER_RECEIVED_PARTIALLY, ORDER_RECEIVING, TOLLING, UNHOLD, UNLOADING, deactiveText, inboundText, incommingOrder, incommingOrderText, outboundText } from '../../Constant/Common/common-variable';

export const getStatusOptions = (status, trailRes = []) => {
    switch (status) {
        case ORDER_INITIATED:
            return [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
                { label: HOLD, value: HOLD, movementStatus: IN_BUILDING },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
            ];
        case ORDER_RECEIVING:
        case ORDER_RECEIVED_PARTIALLY:
            return [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
                { label: DISPATCH, value: DISPATCH, movementStatus: IN_TRANSIT },
                { label: TOLLING, value: TOLLING, movementStatus: IN_BUILDING },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
            ];
        case ORDER_PICKED:
        case 'ORDER_PICKED_PARTIALLY':
            return [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
                { label: DISPATCH, value: DISPATCH, movementStatus: IN_TRANSIT },
                { label: HOLD, value: HOLD, movementStatus: IN_BUILDING },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
            ];

        case LOADING:
        case UNLOADING:
            return [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
                { label: HOLD, value: HOLD, movementStatus: IN_BUILDING },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
            ];
        case ACTIVE:
            return [

                { label: 'DISPATCH1212212', value: DISPATCH, movementStatus: IN_TRANSIT },
                { label: HOLD, value: HOLD, movementStatus: IN_BUILDING },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
            ];
        case TOLLING:
            return [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
                { label: DISPATCH, value: DISPATCH, movementStatus: IN_TRANSIT },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
                { label: TOLLING, value: TOLLING, movementStatus: IN_BUILDING },
            ];
        case DISPATCH:
            return [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
                { label: DISPATCH, value: DISPATCH, movementStatus: IN_TRANSIT },
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
                { label: TOLLING, value: TOLLING, movementStatus: IN_BUILDING },
            ];
        default:
            return [];
    }
};