export const Columns = [
    {
        _id: 0,
        title: 'Inventory',
        useTitle : true,
        keys: [
            { _id: 0, title: '#', dataIndex: 'index' },
            { _id: 0, title: 'Building Name', dataIndex: 'tagInfo' },
            { _id: 0, title: 'Opreation Status', dataIndex: 'opreationStatus' },
            { _id: 0, title: 'Batch Number', dataIndex: 'batchNumber' },
            { _id: 0, title: 'Tag Type', dataIndex: 'tagType' },
            { _id: 0, title: 'Tag Number', dataIndex: 'rfidTag' },
            { _id: 0, title: 'Status', dataIndex: 'status' },
            { _id: 0, title: 'Weight', dataIndex: 'tagWeight' },
            { _id: 0, title: 'Movement Status', dataIndex: 'movementStatus' },
            { _id: 0, title: 'Date & Time', dataIndex: 'dateTime' },
            { _id: 0, title: 'Actions', dataIndex: 'action' },
        ]
    },{
        _id: 1,
        title: 'Orders',
        keys: [
            { _id: 0, title: '#', dataIndex: 'index' },
            { _id: 0, title: 'Dispatch From', dataIndex: 'dispatchFromName' },
            { _id: 0, title: 'Dispatch To', dataIndex: 'dispatchToName' },
            { _id: 0, title: 'Order Creation Date', dataIndex: 'orderDateTime' },
            { _id: 0, title: 'Expected Arrival Date', dataIndex: 'expectedArrival' },
            { _id: 0, title: 'Batch No.', dataIndex: 'batchNumber' },
            { _id: 0, title: 'Order Type', dataIndex: 'orderType' },
            { _id: 0, title: 'Order Status', dataIndex: 'orderStatus' },
            { _id: 0, title: 'Movement Status', dataIndex: 'movementStatus' },

        ]
    },{
        _id: 2,
        title: 'Errors',
        keys: [
            { _id: 0, title: '#', dataIndex: 'index' },
            { _id: 0, title: 'Tag Number', dataIndex: 'rfidTag' },
            { _id: 0, title: 'Opreation Status', dataIndex: 'opreationStatus' },
            { _id: 0, title: 'Tag Type', dataIndex: 'tagType' },
            { _id: 0, title: 'Message', dataIndex: 'errorMsg' },
        ]
    }
]