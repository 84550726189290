import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { searchTag } from '../../../../Constant/Api/Api';
import { CompileInventoryData } from './inventory-promiss';
import { setNestedPagination, setPagination } from '../../../../Store/Action/Pagination/PaginationAction';
import { setDashboardInventoryData } from '../../../../Store/Action/dashbaord/cycle-count/cycle-count-action';
import { INVENTORY } from '../../../../Constant/Common/common-variable';
import { GetInventoryColumn } from './inventory-column';
import { useColumn } from '../../../../Hooks/use-column';
import ControlledTable from '../../../../Component/ui/table/custom-table';

export default function Inventory() {
    const dispatch = useDispatch()
    const reduxCycleCount = useSelector(state => state.CycleCountReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer);
    const reduxDashboard = useSelector((state) => state.DashboardReducer);

    useEffect(() => {
        if (reduxCycleCount?.inventory === null) {
            loadData('init')
        }
        
    }, [reduxCycleCount])

    const loadData = (type) => {
        var json = reduxCycleCount?.inventoryJson
        if (type === 'init') {
            Object.assign(json, { page: 1, limit: 10 });
        } else {
            Object.assign(json, { page: reduxPagination?.nestedPagination?.['inventory']?.number, limit: reduxPagination?.nestedPagination?.['inventory']?.limit });
        }
        Object.assign(json.search, { tagType: INVENTORY })
        Object.assign(json.search, { locationIds: reduxDashboard?.apiJson?.selectedLocation?._id, currentLocation :  reduxDashboard?.apiJson?.selectedBuilding?._id})
        console.log('Inventory json', json);

        HitApi(json, searchTag).then((result) => {
            CompileInventoryData(result, reduxPagination?.doc).then((CompiledData) => {
                dispatch(setDashboardInventoryData(CompiledData));
                var oldNestedPagination = {...reduxPagination?.nestedPagination}
                Object.assign(oldNestedPagination,{['inventory']:{
                    limit: json?.limit,
                    totalPages: CompiledData?.totalPages,
                    number: CompiledData?.number,
                    totalElements: CompiledData?.totalElements,
                }})
                dispatch(setNestedPagination(oldNestedPagination));
            })
        })
    }

    const columns = useMemo(() => GetInventoryColumn())
    const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);


    return (
        <div>
            <ControlledTable
                columns={visibleColumns}
                data={reduxCycleCount?.inventory?.content}
                nested={'inventory'}
                ApiHit={loadData}
            />
        </div>
    )
}
