import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDashboardApiJson, setDashboardCardData, setDashboardChartDocs, setDashboardChartJson, setDashboardData, setDashboardSearchApiJson, setDashboardSelectedData } from '../../Store/Action/dashbaord/DashboardAction';
import SearchableSelect from '../../Component/ui/form/select/SearchableSelect';
import { searchBuilding, searchDevice, searchLocation } from '../../Constant/Api/Api';
import { getAuthenticatedUser } from '../../Storage/Storage';
import { DashboardCardData } from '../../Store/Reducer/dashbaord/dashboard-constant';
import Item from './data/item';
import { CompileDashboardData, MergeOrConditions } from './promiss/dashboard-promiss';
import { setPagination } from '../../Store/Action/Pagination/PaginationAction';
import useDynamicLoading from '../../Hooks/use-dynamic-loading';
import { HitApi } from '../../Store/Action/Api/ApiAction';
import ControlledTable from '../../Component/ui/table/custom-table';
import { GetDashboardColumns } from './dashboard-column';
import DashbaordCharts from './chart/charts';
import { cycleCountText, readerText } from '../../Constant/Common/common-variable';
import ReaderItem from './reader/reader-item';
import ProfileItem from './reader/profile-item';
import DataReceivedByReader from './reader/data-received-by-reader';
import CycleCount from './cycle-count/cycle-count';
import CycleCountLocationItem from './cycle-count/cycle-count-location-item';
import { setDashboardCycleCountData, setDashboardDifferenceData, setDashboardInventoryData } from '../../Store/Action/dashbaord/cycle-count/cycle-count-action';

export default function Dashboard() {
  const dispatch = useDispatch();
  const reduxDashboard = useSelector((state) => state.DashboardReducer);
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const reduxMqtt = useSelector(state => state.MQTTReducer)
  const user = getAuthenticatedUser();
  const [selectedParent, setSelectedParent] = useState(null);
  const { loadingState, setDynamicLoading } = useDynamicLoading()

  useEffect(() => {


    console.log('reduxDashboard?.apiJson', reduxDashboard?.apiJson);


  }, [reduxDashboard, selectedParent]);

  const loadData = async (ele, type, temp_selected) => {
    let json = { ...reduxDashboard?.apiJson };

    console.log('loadData json', json, reduxDashboard?.selectedData);


    let selectedData = temp_selected ? temp_selected : reduxDashboard?.selectedData
    if (type === 'init') {
      dispatch(setDashboardChartDocs(null));
    }

    MergeOrConditions(selectedData, reduxPagination?.doc).then((CompiledJson) => {

      console.log('CompiledJson', CompiledJson);

      HitApi(CompiledJson, ele?.api).then((res) => {
        CompileDashboardData(res, reduxPagination?.doc).then((CompiledData) => {
          dispatch(setDashboardData(CompiledData));
          dispatch(setPagination({
            limit: CompiledJson?.limit,
            totalPages: CompiledData?.totalPages,
            number: CompiledData?.number,
            totalElements: CompiledData?.totalElements,
            screen : reduxDashboard?.apiJson?.selectedChildElement?._id
          }));
          setDynamicLoading({ fullPage: false });

          if (type === 'init') {
            loadChartData(ele, CompiledData?.totalElements, CompiledJson);
          }

        })
      })
    });
  };

  const handleChange = (e) => {
    dispatch(setDashboardDifferenceData(null))
    dispatch(setDashboardInventoryData(null))
    dispatch(setDashboardCycleCountData(null))

    let json = { ...reduxDashboard?.apiJson };
    json.selectedBuilding = null;
   

    dispatch(setDashboardApiJson(json));
    const { ele } = e;
    setTimeout(() => {
      json = { ...reduxDashboard?.apiJson, selectedBuilding: ele };
      delete json?.selectedElement
      delete json.selectedLocation
      dispatch(setDashboardApiJson(json));
      setSelectedParent(ele);
    }, 0);
  };

  let item;

  if (reduxDashboard?.apiJson?.selectedBuilding?._id) {
    item = DashboardCardData?.map((ele) => {
      return <Item key={ele.id} ele={ele} onClick={() => handleParentClick(ele)} />;
    });
  }

  let childItem;

  if (reduxDashboard?.apiJson?.selectedElement?.serverKeys !== cycleCountText) {
    childItem = reduxDashboard?.apiJson?.selectedElement?.child?.map((ele) => {
      return ele?.serverKeys === readerText ? <ReaderItem key={ele.id} ele={ele} /> /* All reader items  */ : <Item key={ele.id} ele={ele} onClick={() => handleChildClick(ele)} />;
    });
  }

  let deviceProfileItem;

  if (reduxDashboard?.apiJson?.deviceProfile) {
    deviceProfileItem = reduxDashboard?.apiJson?.deviceProfile?.capabilities?.map((ele) => {
      return <ProfileItem key={ele.id} ele={ele} />; // Anteena Items
    });
  }

  let LocationItem;

  if (reduxDashboard?.apiJson?.selectedElement?.serverKeys === cycleCountText) {
    LocationItem = reduxDashboard?.apiJson?.selectedElement?.child?.map((ele) => {
      return <CycleCountLocationItem key={ele.id} ele={ele} parent={reduxDashboard?.apiJson?.selectedBuilding} />; // Anteena Items
    });
  }

  

  const handleParentClick = (ele) => {
    // setDynamicLoading({ fullPage: true })
    dispatch(setDashboardChartDocs(null));
    dispatch(setDashboardData(null))
    // null cycle count data
    dispatch(setDashboardDifferenceData(null))
    dispatch(setDashboardInventoryData(null))
    dispatch(setDashboardCycleCountData(null))

    dispatch(setDashboardSelectedData([]));
    let json = reduxDashboard?.apiJson
    json.selectedElement = null
    json.deviceProfile = null
    json.selectedDeviceAnteena = null
    json.selectedLocation = null
    dispatch(setDashboardApiJson(json))

    setTimeout(() => {
      json = { ...reduxDashboard?.apiJson, selectedElement: ele };
      dispatch(setDashboardApiJson(json));
      const updatedCardData = reduxDashboard?.DashboardCardData?.map((parent) => ({
        ...parent,
        active: parent?.title === ele?.title,
        child: parent?.child?.map((child) => ({
          active: false,

        })),
      }));
      var temp_selected = [];
      temp_selected.push(ele)
      dispatch(setDashboardSelectedData(temp_selected));
      dispatch(setDashboardCardData(updatedCardData));
      if (ele?.ApiHit && ele?.serverKeys !== readerText && ele?.serverKeys !== cycleCountText ) {
        loadData(ele, 'init', temp_selected);
      } else if (ele?.ApiHit && ele?.serverKeys == readerText) {
        loadReaders(ele)
      }  else if (ele?.ApiHit && ele?.serverKeys == cycleCountText) {
        loadlocations(ele)
      }

    }, 0);

    setTimeout(() => {
      setDynamicLoading({ fullPage: false })
    }, 2000);
  };

  const handleChildClick = (ele) => {
    const json = reduxDashboard?.apiJson;
    const updatedCardData = reduxDashboard?.DashboardCardData?.map((parent) => {
      if (parent?.child?.some((child) => child?.title === ele?.title)) {
        return {
          ...parent,
          child: parent.child.map((child) => ({
            ...child,
            active: child?.title === ele?.title ? !child.active : child.active,
          })),
        };
      }
      return parent;
    });
    Object.assign(json, { selectedChildElement: ele });
    dispatch(setDashboardApiJson(json));
    dispatch(setDashboardCardData(updatedCardData));
    var temp_selected = [...(reduxDashboard?.selectedData || [])]; // Ensure it's an array
    let findItem = temp_selected?.find(Obj => Obj?.title === ele?.title);
    if (findItem) {
      temp_selected = temp_selected.filter(item => item?.title !== ele?.title);
    } else {
      temp_selected.push(ele);
    }
    dispatch(setDashboardSelectedData(temp_selected));

    // Optionally, handle the API call if required
    if (ele?.ApiHit) {
      loadData(ele, 'init', temp_selected);
    }
  }

  const loadChartData = (ele, limit, query) => {
    var chartJson = reduxDashboard?.chartJson
    var json = query
    // Storing Chart Json for Future Work
    Object.assign(chartJson, { query: json })
    dispatch(setDashboardChartJson(chartJson))
    // Ends Here
    HitApi(json, ele?.api).then((result) => {
      dispatch(setDashboardChartDocs(result))
    })
  }

  const loadReaders = (ele) => {
    const json = reduxDashboard?.apiJson;
    var tp = []
    HitApi(ele?.query, ele?.api).then((res) => {
      if (res?.content) {
        res?.content?.map((childEle) => {
          var tj = {
            ...childEle,
            title: childEle?.deviceName,
            displayText: childEle?.deviceName,
            serverKeys: 'Readers',
            query: ele?.query
          }
          tp.push(tj)
        })
        // Setting up child via api load
        ele.child = tp
        Object.assign(json, { selectedElement: ele });
        dispatch(setDashboardApiJson(json));
      }
    })
  }

  const loadlocations = (ele) =>{
    const apiJson = reduxDashboard?.apiJson;
    var json = { page:1, limit : 10, search:{ buildingIds : {$in:[apiJson?.selectedBuilding?._id]}} }

    var tp = []
    HitApi(json, searchLocation).then((res)=>{
      if (res?.content) {
        res?.content?.map((childEle) => {
          var tj = {
            ...childEle,
            title: childEle?.value,
            displayText: childEle?.value,
            serverKeys: cycleCountText,
            query: ele?.query
          }
          tp.push(tj)
        })
        // Setting up child via api load
        ele.child = tp
        Object.assign(apiJson, { selectedElement: ele });
        dispatch(setDashboardApiJson(apiJson));
      }
    })

  }

  const columns = useMemo(() => GetDashboardColumns(reduxDashboard?.apiJson?.selectedElement))

  return (
    <div className='space-y-5 mt-4'>
      <div className="grid grid-cols-4 gap-4">
        <SearchableSelect
          name="buildingId"
          api={searchBuilding}
          getFieldName="buildingName"
          dynamicSearch={{ userIds: { $in: [user?.userId] } }}
          onChange={handleChange}
          reduxState={reduxDashboard?.apiJson}
          setAction={setDashboardApiJson}
        />
      </div>
      <div className='flex flex-wrap gap-2 group'>{item}</div>
      { childItem && <div className='flex flex-wrap gap-2 group'>{childItem}</div> }
      { deviceProfileItem && <div className='flex flex-wrap gap-2 group'>{deviceProfileItem}</div> }
      { LocationItem && <div className='flex flex-wrap gap-2 group'>{LocationItem}</div> }
      <div className='my-4'>
        {reduxDashboard?.doc?.content && !reduxDashboard?.selectedData?.[0]?.isLoadFile && <ControlledTable
          screen={reduxDashboard?.apiJson?.selectedChildElement?._id}
          isLoading={loadingState?.doc?.fullPage}
          variant="modern"
          data={reduxDashboard?.doc?.content}
          columns={columns}
          json={reduxDashboard?.searchJson}
          setAction={setDashboardSearchApiJson}
          ApiHit={() => loadData(reduxDashboard?.apiJson?.selectedChildElement ? reduxDashboard?.apiJson?.selectedChildElement : reduxDashboard?.apiJson?.selectedElement)}
        />}
      </div>
      {reduxDashboard?.chartData?.content && !reduxDashboard?.selectedData?.[0]?.isLoadFile && <div><DashbaordCharts /></div>}
      {reduxDashboard?.apiJson?.deviceProfile && <div><DataReceivedByReader /></div>}

      {reduxDashboard?.apiJson?.selectedLocation && <CycleCount />}

    </div>
  );
}
