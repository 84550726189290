import { Title, Text, Avatar, Button, Popover } from 'rizzui';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from '../Utils/class-names';
import { routes } from '../config/routes';
import { getAuthenticatedUser, getAuthenticatedUserWithRoles, logOutAuthenticatedUser } from '../Storage/Storage';
import { useModal } from '../shared/modal-views/use-modal';

export default function ProfileMenu({ buttonClassName, avatarClassName, username = false }) {
  const { openModal } = useModal();

  return (
    <ProfileMenuPopover>
      <Popover.Trigger>
        <button className={cn('w-9 sm:w-10 rounded-full outline-none focus-visible:ring-[1.5px] focus-visible:ring-gray-400', buttonClassName)}>
          <Avatar src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-11.webp" name="John Doe" className={cn('!h-9 sm:!h-10', avatarClassName)} />
        </button>
      </Popover.Trigger>
      <Popover.Content className="z-[9999] p-0 dark:bg-gray-100">
        <DropdownMenu openModal={openModal} />
      </Popover.Content>
    </ProfileMenuPopover>
  );
}

function ProfileMenuPopover({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(false), []);

  return (
    <Popover isOpen={isOpen} setIsOpen={setIsOpen} shadow="sm" placement="bottom-end">
      {children}
    </Popover>
  );
}

const menuItems = [
  { name: 'My Profile', href: routes?.panel?.account?.myProfile },
  { name: 'Dashboard', href: routes?.panel?.dashboard }
];

const handleClick = (item, openModal) => {
  if (item.action === 'modal') openModal();
};

const handleSignOut = () => {
  // Handle sign-out logic
  logOutAuthenticatedUser()
  window.location.href = '/login'
};

function DropdownMenu({ openModal }) {
  const userDetails = getAuthenticatedUser();
  const user = getAuthenticatedUserWithRoles();

  return (
    <div className="w-64 text-left">
      <div className="flex items-center border-b px-6 pb-5 pt-6">
        <Avatar src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars-blur/avatar-11.webp" name="Albert Flores" />
        <div className="ms-3">
          <Title as="h6" className="font-semibold uppercase">{userDetails?.sub}</Title>
          <Text className="text-gray-600">{user?.roleObject?.roleName}</Text>
        </div>
      </div>
      <div className="grid px-3.5 py-3.5 font-medium text-gray-700">
        {menuItems.map(item => (
          <NavLink
            key={item.name}
            to={item.href}
            onClick={() => handleClick(item, openModal)}
            className="group my-0.5 flex items-center rounded-md px-2.5 py-2 hover:bg-gray-100"
          >
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="border-t px-6 pb-6 pt-5">
        <Button className="w-full justify-start font-medium text-gray-700" variant="text" onClick={handleSignOut}>
          Sign Out
        </Button>
      </div>
    </div>
  );
}
