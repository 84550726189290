import { SET_INBOUND_ADDED_VEHICLE, SET_INBOUND_API_JSON, SET_INBOUND_DATA, SET_INBOUND_PRODUCT, SET_INBOUND_SEARCH_JSON ,SET_INBOUND_INVNETORY} from "../../Action/inbound/inbound-action";

const initialState = {
    doc: [],
    apiJson: {},
    vehicleAdded: [],
    builddingBatch: [],
    inventories: [],
    InboundProducts: [],
    searchJson: { page: 1, limit: 10, search: {orderType:'INBOUND'} },
    timestamp: Date.now()
}

const OutboundReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INBOUND_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_INBOUND_API_JSON:
            return ({ ...state, apiJson: action.value, timestamp: Date.now() })
        case SET_INBOUND_SEARCH_JSON:
            return ({ ...state, searchJson: action.value, timestamp: Date.now() })
        case SET_INBOUND_ADDED_VEHICLE:
            return ({ ...state, vehicleAdded: action.value, timestamp: Date.now() })
        // case SET_BUILDING_BATCH:
        //     return ({ ...state, builddingBatch: action.value, timestamp: Date.now() })
        case SET_INBOUND_INVNETORY:
            return ({ ...state, inventories: action.value, timestamp: Date.now() })
        case SET_INBOUND_PRODUCT:
            return ({ ...state, InboundProducts: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default OutboundReducer;

