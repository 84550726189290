import { Text } from "rizzui";
import { HeaderCell } from "../../Component/ui/table";
import Skeleton from "react-loading-skeleton";
import { useModal } from "../../shared/modal-views/use-modal";
import useCustomAlertController from "../../Hooks/use-custom-alert copy";
import { useState } from "react";
import { Columns } from "./column/column-constant";


export const GetDashboardColumns = (ele) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({});

  const columns = Columns.find(Obj => Obj?.title === ele?.serverKeys)
  
  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  const dynamicColumns = columns?.keys.map((key) => {
    const isActionColumn = key.dataIndex === 'action';
    return {
      title: <HeaderCell title={key.title} className="font-extrabold" />,
      dataIndex: key.dataIndex,
      key: key.dataIndex,
      width: key.dataIndex === 'index' ? 10 : 200,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      ))
    };
  });
  return dynamicColumns || [];
};