import { Text } from 'rizzui';
import { HeaderCell } from '../../../Component/ui/table';

export const getTagMasterColumns = () => [
  {
    title: (
      <HeaderCell title="#" />
    ),
    dataIndex: 'index',
    key: 'index',
    width: 10,
    render: (value, row, index) => <Text>{value|| '---'}</Text>,
  },
  {
    title: (
      <HeaderCell title="_id" />
    ),
    dataIndex: '_id',
    key: '_id',
    width: 10,
    render: (value) => <Text>{ value?.substring(value?.length - 5)  || '---'}</Text>,
  },
  {
    title: <HeaderCell title="Tag Type" className={'font-extrabold'} />,
    dataIndex: 'tagType',
    key: 'tagType',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },
  {
    title: <HeaderCell title="Tag Info"  className={'font-extrabold'}/>,
    dataIndex: 'tagInfo',
    key: 'tagInfo',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },
  {
    title: <HeaderCell title="RFID Tag"  className={'font-extrabold'}/>,
    dataIndex: 'rfidTag',
    key: 'rfidTag',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },{
    title: <HeaderCell title="Batch No."  className={'font-extrabold'}/>,
    dataIndex: 'batchNumber',
    key: 'batchNumber',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },{
    title: <HeaderCell title="Building Id"  className={'font-extrabold'}/>,
    dataIndex: 'buildingIds',
    key: 'buildingIds',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },{
    title: <HeaderCell title="Current Location"  className={'font-extrabold'}/>,
    dataIndex: 'currentLocation',
    key: 'currentLocation',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },{
    title: <HeaderCell title="Movement Status"  className={'font-extrabold'}/>,
    dataIndex: 'movementStatus',
    key: 'movementStatus',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },{
    title: <HeaderCell title="Operation Status"  className={'font-extrabold'}/>,
    dataIndex: 'opreationStatus',
    key: 'opreationStatus',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },{
    title: <HeaderCell title="Tag Weight"  className={'font-extrabold'}/>,
    dataIndex: 'tagWeight',
    key: 'tagWeight',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },
];
