export const BuildingToZoneMapping = (json) => {
  return {
    sourceId: json?.mappingJson?.building?._id,
    mappingId: json?.mappingJson?.zone?._id,
    "sourceCollection": "buildingCollection",
    "destinationCollection": "zoneCollection",
    "source": "buildingIds",
    "mapping": "zoneIds"
  }

}
export const ZoneToLocationMapping = (json) => {
  return {
    sourceId: json?.mappingJson?.zone?._id,
    mappingId: json?.mappingJson?.location?._id,
    "sourceCollection": "zoneCollection", "destinationCollection": "locationCollection",
    "source": "zoneIds", "mapping": "locationIds"
  }

}


export const BuildingToLocationMapping = (json) => {
  return {
    sourceId: json?.mappingJson?.building?._id,
    mappingId: json?.mappingJson?.location?._id,
    "sourceCollection": "buildingCollection", "destinationCollection": "locationCollection",
    "source": "buildingIds", "mapping": "locationIds"
  }

}

export const LocationToReaderMapping = (json) => {
  return {
    sourceId: json?.mappingJson?.location?._id,
    mappingId: json?.mappingJson?.reader?._id,
    "sourceCollection": "locationCollection", "destinationCollection": "deviceMangerCollection",
    "source": "locationIds", "mapping": "readerIds"
  }

}

export const ZoneToReaderMapping = (json) => {
  return {
    sourceId: json?.mappingJson?.zone?._id,
    mappingId: json?.mappingJson?.reader?._id,
    "sourceCollection": "zoneCollection", "destinationCollection": "deviceMangerCollection",
    "source": "zoneIds", "mapping": "readerIds"
  }

}

export const BuildingToReaderMapping = (json) => {
  return {
    sourceId: json?.mappingJson?.building?._id,
    mappingId: json?.mappingJson?.reader?._id,
    "sourceCollection": "buildingCollection", "destinationCollection": "deviceMangerCollection",
    "source": "buildingIds", "mapping": "readerIds"
  }

}