
export const SET_DASHBOARD_API_JSON = 'SET_DASHBOARD_API_JSON'
export const SET_DASHBOARD_SEARCH_API_JSON = 'SET_DASHBOARD_SEARCH_API_JSON'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const SET_DASHBOARD_CARD_DATA = 'SET_DASHBOARD_CARD_DATA'
export const SET_DASHBOARD_CHART_DOCS = 'SET_DASHBOARD_CHART_DOCS'
export const SET_DASHBOARD_CHART_JSON = 'SET_DASHBOARD_CHART_JSON'
export const SET_DASHBOARD_SELECTED_DATA = 'SET_DASHBOARD_SELECTED_DATA'

export const setDashboardData = (data) => ({ type: SET_DASHBOARD_DATA, value: data });
export const setDashboardApiJson = (data) => ({ type: SET_DASHBOARD_API_JSON, value: data });
export const setDashboardSearchApiJson = (data) => ({ type: SET_DASHBOARD_SEARCH_API_JSON, value: data });
export const setDashboardChartDocs = (data) => ({ type: SET_DASHBOARD_CHART_DOCS, value: data });
export const setDashboardChartJson = (data) => ({ type: SET_DASHBOARD_CHART_JSON, value: data });

export const setDashboardCardData = (data) => ({ type: SET_DASHBOARD_CARD_DATA, value: data });
export const setDashboardSelectedData = (data) => ({ type: SET_DASHBOARD_SELECTED_DATA, value: data });

