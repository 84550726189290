import React, { useEffect, useState } from 'react'
import CustomButton from '../../../../Component/ui/buttons/custom-button'
import CustomInput from '../../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import { setConfigurationData, setConfigurationJson } from '../../../../Store/Action/device-master/configuration/configuration-action'
import useValidation from '../../../../Hooks/useValidation'
import { addConfigurationSchema } from '../../../../Utils/validators/device-manager/configuration/add-configuration.schema'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { addConfig, searchConfig, searchGeneral, updateConfig } from '../../../../Constant/Api/Api'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import CustomJsonEditor from '../../../../Component/ui/editor/json-editor'
import { _id } from '../../../../Constant/Common/common-variable'
import { deviceVariable as variable } from '../../../../Constant/variables/device-manager/configuration/configuration.variable'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import cn from '../../../../Utils/class-names'
import { RoundedCard } from '../../../../Constant/Classes/Classes'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import useCustomAlertController from '../../../../Hooks/use-custom-alert'


export default function AddConfiguration() {
  const dispatch = useDispatch()
  const reduxConfiguration = useSelector(state => state.ConfigurationReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const [input, setInput] = useState({})
  const [output, setOutput] = useState()
  const { errors, validate, hide, setHide } = useValidation(addConfigurationSchema);
  const { loadingState, setDynamicLoading } = useDynamicLoading();
  const { showCustomAlert } = useCustomAlertController();

  var url = window?.location?.pathname
  var ID = url?.split('/')?.[4]

  useEffect(() => {

    if (ID && !reduxConfiguration?.apiJson?._id) {
      loadDefault(ID)
    }



  }, [reduxConfiguration, ID])

  const loadDefault = (_id) => {
    var searchJson = reduxConfiguration?.searchJson
    var json = reduxConfiguration?.apiJson
    Object.assign(json, { _id: _id })
    Object.assign(searchJson.search, { _id: _id })



    setDynamicLoading({ fullPage: true })
    HitApi(searchJson, searchConfig).then((result) => {
      if (result?.content?.[0]) {

        var row = result?.content?.[0]
        Object.assign(json, {
          configName: row?.configName,
          config: row?.config,
          useFor: row?.['useFor']
        })
        var selected = reduxSelect?.selected
        dispatch(setSearchableSelectSelectedData([...selected, { label: row?.['useFor'], label: row?.['useFor'], name: 'useFor', selectionType : 'useFor' }]))
        // useFor
        setInput(result?.content?.[0]?.config?.[0])
        dispatch(setConfigurationJson(json))
        setDynamicLoading({ fullPage: false })
      }
    })
  }

  const handleSave = async () => {
    const json = { ...reduxConfiguration?.apiJson, config: [input], ...(ID && { _id: ID }) };


    setDynamicLoading({ fullPage: true });

    try {

      const result = await HitApi(json, ID ? updateConfig : addConfig);
      result?.success !== false && showCustomAlert(result)
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setDynamicLoading({ fullPage: false });
      dispatch(setConfigurationData(null))
      dispatch(setConfigurationJson({}))
      
    }
  };


  const handleCustomChange = (e) => {
    var json = reduxConfiguration?.apiJson
    const { _id, value } = e
    Object.assign(json, { useFor: value })
    dispatch(setConfigurationJson(json))
  }

  const onChange = (value, type) => {
    try {
      const parsedJson = (value);
      if (type === 'input') {
        setInput(parsedJson);
      }
    } catch (error) {
      console.error('Invalid JSON format:', error.message);
    }
  };

  return (
    <div className={cn(RoundedCard, 'p-8')}>
      <div className='grid grid-cols-4 gap-4'>
        <CustomInput validate={validate} name="configName" label="Configuraiton Name" value={reduxConfiguration?.apiJson?.configName} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationJson} />
        <SearchableSelect validate={validate} name="useFor" label="Use For" selectionType={'useFor'} api={searchGeneral} getFieldName={'value'} dynamicSearch={{ 'usedBy': 'deviceConfiguration' }} value={reduxConfiguration?.apiJson?.roleName} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationJson} onChange={handleCustomChange} />
      </div>
      <div className='my-5'><CustomJsonEditor json={input} setData={setHide} onChange={(value) => onChange(value, 'input')} /></div>
      <div className='my-4'>
        <CustomButton type={'submit'} text={ID ? 'Update Configuration' : 'Save Configuration'} onClick={handleSave} disabled={hide} />
      </div>
    </div>
  )
}
