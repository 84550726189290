import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { addGeneral, updateGeneral } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { generalMasterVariable as variable } from '../../../Constant/variables/master/general-master/general-master.variable';
import useAlertController from '../../../Hooks/use-alert-controller';
import { generalMasterSchema } from '../../../Utils/validators/master/general-master/general-master-schema';
import { setGeneralMasterApiJson } from '../../../Store/Action/master/general-master/general-master-action';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import { usedByOption } from '../../../Constant/UsedBy';
import { useState } from 'react';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';





export default function AddGeneralMaster({ row, closeModal, ApiHit }) {
    var dispatch = useDispatch()
    const reduxGeneral = useSelector(state => state.GeneralMasterReducer)
    const { errors, validate } = useValidation(generalMasterSchema);
    const { showCustomAlert } = useAlertController();
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        if (row?._id) {
            loadDefault(row)


            var json = [{ name: 'usedBy', label: row.usedBy }]


            dispatch(setSearchableSelectSelectedData(json))
        }
        else {
            dispatch(setSearchableSelectSelectedData(null))
            dispatch(setGeneralMasterApiJson({}))

        }
    }, [])

    const loadDefault = (row) => {
        var json = reduxGeneral?.apiJson
        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        dispatch(setGeneralMasterApiJson(json))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxGeneral?.apiJson
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true)
            if (row?._id) {
                Object.assign(json, { _id: row?._id, status: json?.status || 'active', })
                HitApi(json, updateGeneral).then((result) => {
                    if (result?.success !== false) {
                        setLoading(false)
                        showCustomAlert({
                            type: 'success',
                            title: 'Success!',
                            message: 'General Details Updated Successfully',
                        });
                        if (ApiHit) { ApiHit() }
                        handleClose()
                    }
                })
            } else {
                Object.assign(json, { status: json?.status || 'active', })
                HitApi(json, addGeneral).then((result) => {
                    if (result?.success !== false) {
                        setLoading(false)
                        showCustomAlert({
                            type: 'success',
                            title: 'Success!',
                            message: 'General Details Added Successfully',
                        });
                        if (ApiHit) { ApiHit() }
                        handleClose()
                    }
                })
            }
        } else {

        }
    };


    const handleSelect = (e, name) => {
        const { label, value, _id } = e;
        const json = reduxGeneral?.apiJson;
        Object.assign(json, { [name]: value });

    };
    const handleClose = () => {
        closeModal()
        dispatch(setGeneralMasterApiJson({}))
    }

    return (
        <div className='p-10'>
            <form onSubmit={handleSubmit}>
                <div className="space-y-5 lg:space-y-6">
                    <CustomInput important={true} name="label" label="Label" value={reduxGeneral?.apiJson?.label} error={errors} reduxState={reduxGeneral?.apiJson} setAction={setGeneralMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="value" label="Value" value={reduxGeneral?.apiJson?.value} error={errors} reduxState={reduxGeneral?.apiJson} setAction={setGeneralMasterApiJson} validate={validate} />
                    <SearchableSelect name="usedBy" label="Used By" defaultOptions={usedByOption} value={reduxGeneral?.apiJson?.usedBy} error={errors} reduxState={reduxGeneral?.apiJson} setAction={setGeneralMasterApiJson} validate={validate} onChange={(e) => handleSelect(e, 'usedBy')} />
                    <div className='flex gap-3 justify-end pt-24'>
                        <CustomButton text={'Cancel'} variant='flat' onClick={closeModal} />
                        <CustomButton type={'submit'} text={row?._id ? 'Update' : 'Submit'} loading={loading} />
                    </div>
                </div>
            </form>
        </div>
    )
}
