import React, { useEffect, useState } from 'react'
import CustomInput from '../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux';
import { customerlMasterSchema } from '../../../Utils/validators/master/customer-master/customer-master-schema';
import { setCustomerMasterApiJson, setCustomerMasterData } from '../../../Store/Action/master/customer-master/customer-master-action';
import useValidation from '../../../Hooks/useValidation';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import { addCustomer, searchCustomer, searchGeneral, searchSite, updateCustomer } from '../../../Constant/Api/Api';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import { Country, State, City } from 'country-state-city';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import useAlertController from '../../../Hooks/use-alert-controller';
import { useNavigate } from 'react-router-dom';

function AddCustomeMaster() {
    const { showCustomAlert } = useAlertController();
    const reduxCustomer = useSelector(state => state.CustomerMasterReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer);
    const { errors, validate } = useValidation(customerlMasterSchema);
    const reduxUser = useSelector(state => state.UserReducer);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const url = window.location.pathname
    const urlId = url.split('/')[4]
    const [countries, setCountries] = useState(reduxCustomer?.apiJson?.customerCountry || null);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (urlId && !reduxCustomer?.apiJson?.customerCountry && !reduxCustomer?.apiJson?.customerState && !reduxCustomer?.apiJson?.customerCity) {
            loadDefault()
        }
        if (countries === null) {
            const allCountries = Country.getAllCountries().map(country => ({
                label: country.name,
                value: country.name
            }));
            setCountries(allCountries);
        }
        if (reduxCustomer?.apiJson?.customerCountry && states === null ) { 

            
            const selectedCountryName = reduxCustomer?.apiJson?.customerCountry;
            const selectedCountry = Country.getAllCountries().find(country => country.name === selectedCountryName)?.isoCode;
            const states = State.getStatesOfCountry(selectedCountry).map(state => ({
                label: state.name,
                value: state.name
            }));
            setStates(states);
        }
        if (reduxCustomer?.apiJson?.customerState && cities === null) {
            const countryISOCode = Country.getAllCountries().find(country => country.name === reduxCustomer?.apiJson?.customerCountry)?.isoCode;
            const stateISOCode = State.getStatesOfCountry(countryISOCode).find(state => state.name === reduxCustomer?.apiJson?.customerState)?.isoCode;
            const cities = City.getCitiesOfState(countryISOCode, stateISOCode).map(city => ({
                label: city.name,
                value: city.name
            }));
            setCities(cities);
        }
    }, [reduxCustomer, cities, states])

    const loadDefault = () => {
        var json = {
            page: 1,
            limit: 1,
            search: {
                _id: urlId
            }
        }
        HitApi(json, searchCustomer).then((result) => {

            var oldRedux = reduxCustomer?.apiJson

            if (result?.content[0]) {
                var json = [
                    { name: 'customerGroup', value: result?.content[0]?.customerGroup, label: result?.content[0]?.customerGroup },
                    { name: 'customerType', value: result?.content[0]?.customerType, label: result?.content[0]?.customerType },
                    { name: 'customerCountry', value: result?.content[0]?.customerCountry, label: result?.content[0]?.customerCountry },
                    { name: 'customerState', value: result?.content[0]?.customerState, label: result?.content[0]?.customerState },
                    { name: 'customerCity', value: result?.content[0]?.customerCity, label: result?.content[0]?.customerCity },
                    { name: 'customerStatus', value: result?.content[0]?.customerStatus, label: result?.content[0]?.customerStatus },
                    { name: 'siteIds', value: result?.content[0]?.siteIds?.siteName, label: result?.content[0]?.siteIds?.siteName }
                ]
                dispatch(setSearchableSelectSelectedData(json))
            }

            Object.assign(result?.content[0], { siteIds: result?.content[0]?.siteIds?._id })
            var newRedux = { ...oldRedux, ...result?.content[0] }
            dispatch(setCustomerMasterApiJson(newRedux));
        })
    }

    const handleOnChange = (e, name) => {
        if (name === 'customerCountry' && reduxCustomer?.apiJson?.customerState) {

            var oldData = reduxCustomer?.apiJson

            oldData.customerCountry = e.value

            var selectedData = [];
            var oldSelectedData = reduxSelect?.selected || [];

            if (reduxCustomer?.apiJson?.customerCity) {
                delete oldData.customerCity;
                delete oldData.customerState
                Promise.all(
                    oldSelectedData.map((ele, i) => {
                        return new Promise((resolve) => {
                            if (ele?.name !== 'customerState' && ele?.name !== 'customerCity') {
                                if (ele.name === 'customerCountry') {
                                    ele.name = name
                                    ele.value = e.value
                                    ele.label = e.value
                                }
                                selectedData.push(ele);
                            }
                            resolve();
                        });
                    })
                ).then(() => {

                    setCities(null)
                    setStates(null)
                    dispatch(setSearchableSelectSelectedData(selectedData));
                });
            } else {
                delete oldData.customerState
                Promise.all(
                    oldSelectedData.map((ele, i) => {
                        return new Promise((resolve) => {
                            if (ele?.name !== 'customerState') {
                                if (ele.name === 'customerCountry') {
                                    ele.name = name
                                    ele.value = e.value
                                    ele.label = e.value
                                }
                                selectedData.push(ele);
                            }
                            resolve();
                        });
                    })
                ).then(() => {
                    setStates(null)

                    dispatch(setSearchableSelectSelectedData(selectedData));
                });
            }




            dispatch(setCustomerMasterApiJson(oldData));
        } else {
            const { _id, value } = e;
            const newJson = { [name]: name === 'siteIds' ? _id : value };
            const updatedJson = { ...reduxCustomer?.apiJson, ...newJson };
            dispatch(setCustomerMasterApiJson(updatedJson));
        }
    }
    const handleSubmit = (e) => {
        var json = reduxCustomer?.apiJson;
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const apiCall = urlId ? updateCustomer : addCustomer;
            const updatedJson = { ...json, _id: urlId, status: json?.status || 'Active', siteIds: reduxCustomer?.apiJson?.siteIds?._id ? reduxCustomer?.apiJson?.siteIds?._id : reduxCustomer?.apiJson?.siteIds }
            HitApi(updatedJson, apiCall).then((result) => {
                setLoading(false);
                if (result?.status === 200 || result?.status === 201) {
                    dispatch(setCustomerMasterData(null))
                    dispatch(setCustomerMasterApiJson({}))
                    dispatch(setSearchableSelectSelectedData(null))

                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                    });
                    navigate('/master/customer');


                } else if (result?.status === 409) {
                    showCustomAlert({
                        type: 'error',
                        title: 'Error',
                        message: result?.error?.message
                    });
                } else {
                    alert(result.message);
                }
            });
        }
    };




    const handleClear = (key) => {
        var oldJson = { ...reduxCustomer?.apiJson }
        delete oldJson[key]
        dispatch(setCustomerMasterApiJson(oldJson))
    };

    return (
        <div className='p-10 rounded-xl bg-white mt-10' >
            <div className="space-y-5 lg:space-y-6">
                <div className='grid grid-cols-4 gap-4 '>
                    <CustomInput inputType={'text'} important={true} name="customerName" label="Customer Name" value={reduxCustomer?.apiJson?.customerName} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="customerCode" label="Customer Code" value={reduxCustomer?.apiJson?.customerCode} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <SearchableSelect searchable validate={validate} name="customerGroup" label="Customer Group" api={searchGeneral} dynamicSearch={{ 'usedBy': 'customerGroup' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setCustomerMasterApiJson} onChange={(e) => handleOnChange(e, 'customerGroup')} onClear={(e) => handleClear('customerGroup')} />
                    <CustomInput important={true} name="customerEmail" label="Customer Email" value={reduxCustomer?.apiJson?.customerEmail} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <SearchableSelect searchable validate={validate} name="customerType" label="Customer Type" api={searchGeneral} dynamicSearch={{ 'usedBy': 'customerType' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setCustomerMasterApiJson} onChange={(e) => handleOnChange(e, 'customerType')} onClear={(e) => handleClear('customerType')} />
                    <CustomInput important={true} name="customerAddress1" label="Customer Add1" value={reduxCustomer?.apiJson?.customerAddress1} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="customerAddress2" label="Customer Add2" value={reduxCustomer?.apiJson?.customerAddress2} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="customerLandmark" label="Customer Landmark" value={reduxCustomer?.apiJson?.customerLandmark} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} type={"number"} maxLength={6} name="customerPostCode" label="Customer PostCode" value={reduxCustomer?.apiJson?.customerPostCode} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <SearchableSelect searchable name="customerCountry" label="Customer Country" defaultOptions={countries} value={reduxCustomer?.apiJson?.customerCountry} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'customerCountry')}   clearable={false}/>
                    <SearchableSelect searchable name="customerState" label="Customer State" defaultOptions={states} value={reduxCustomer?.apiJson?.customerState} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'customerState')}   clearable={false} />
                    <SearchableSelect searchable name="customerCity" label="Customer City" defaultOptions={cities} value={reduxCustomer?.apiJson?.customerCity} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'customerCity')}   clearable={false}/>
                    <CustomInput important={true} name="customerGst" label="Customer Gst" value={reduxCustomer?.apiJson?.customerGst} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} type={"number"} name="customerContact" label="Customer Contact" value={reduxCustomer?.apiJson?.customerContact} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="customerPan" label="Customer PAN" value={reduxCustomer?.apiJson?.customerPan} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="customerVat" label="Customer VAT" value={reduxCustomer?.apiJson?.customerVat} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <CustomInput important={true} name="customerTan" label="Customer TAN" value={reduxCustomer?.apiJson?.customerTan} error={errors} reduxState={reduxCustomer?.apiJson} setAction={setCustomerMasterApiJson} validate={validate} />
                    <SearchableSelect name={'customerStatus'} label="Customer Status" placeholder={'Customer Status'} type={'filter'} api={searchGeneral} dynamicSearch={{ 'usedBy': 'status' }} getFieldName={'value'} onChange={(e) => handleOnChange(e, 'customerStatus')} useCustomDisplay={true} validate={validate} error={errors} onClear={(e) => handleClear('customerStatus')} />
                    <SearchableSelect searchable name="siteIds" label="Site" api={searchSite} getFieldName={'siteName'} error={errors} onChange={(e) => handleOnChange(e, 'siteIds')} validate={validate} onClear={(e) => handleClear('siteIds')} />
                </div>
                <div className='flex gap-3 justify-end'>
                    <CustomButton text={'Back'} variant='flat' onClick={() => { window.location.pathname = 'master/customer/' }} />
                    <CustomButton type={'submit'} text={urlId ? 'Update' : 'Submit'} loading={loading} onClick={handleSubmit} />
                </div>
            </div>
        </div >
    )
}

export default AddCustomeMaster