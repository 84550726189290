import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { deleteLocation, updateLocation } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import AddLocationMaster from '../../../Form/master/location-master/add-location-master';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { useModal } from '../../../shared/modal-views/use-modal';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';
import { deactiveText } from '../../../Constant/Common/common-variable';
import SearchAndAddZone from '../../../Form/master/location-master/search-and-add-zone';

export const GetLocationMasterColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({})

  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));

    Object.assign(row, {status : deactiveText})

    try {
      const result = await HitApi(row, updateLocation);
      showCustomAlert(result)
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
    },
    {
      title: <HeaderCell title="Location Name" className="font-extrabold" />,
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Bin Number" className="font-extrabold" />,
      dataIndex: 'binNumber',
      key: 'binNumber',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Zone Added" className={'font-extrabold'} />,
      dataIndex: 'usedBy',
      key: 'usedBy',
      width: 100,
      render: (usedBy) => (
        <Text className="font-medium text-gray-700">
          {usedBy?.length || 0}
        </Text>
      ),
    },
    {
      title: <HeaderCell title="Tag Ids" className={'font-extrabold'} />,
      dataIndex: 'tagIds',
      key: 'tagIds',
      width: 100,
      render: (value) => (
        <Text className="font-medium text-gray-700">
          {value}
        </Text>
      ),
    },
    {
      title: <HeaderCell title="Status" className="font-extrabold" />,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },

    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={ScreenName.locationMaster}
          row={row}
          onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit Location Master', AddLocationMaster, 400, ApiHit)}
          onDelete={(rowData) => handleDelete(rowData)}
          onaddSection={(rowData) => EditScreen(openModal, closeModal, rowData, 'Add Zone', SearchAndAddZone, 800, ApiHit)}
          checkKeys={['usedBy']}
          enableAdd={true}
        />
      )),
    },
  ];
}