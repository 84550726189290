import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetAddedVehicleColumn } from './added-vehicle-column';
import ControlledTable from '../../../../Component/ui/table/custom-table';
import { useColumn } from '../../../../Hooks/use-column';

export default function AddedVehicle() {
  
  const dispatch = useDispatch()
  const reduxOrder = useSelector(state => state.OrderReducer)

  const columns = useMemo(() => GetAddedVehicleColumn(reduxOrder?.apiJson))
  const { visibleColumns } = useColumn(columns);
  
  
  return (
    <div>
      <ControlledTable
        data={reduxOrder?.apiJson?.vehicleIds || []}
        columns={columns}
        disablePagination
      />
    </div>
  )
}
