import { ActionIcon, Text, Tooltip } from "rizzui";
import { HeaderCell } from "../../../../Component/ui/table";
import PencilIcon from "../../../../Constant/Icons/pencil";
import DeletePopover from "../../../../shared/delete-popover";
import { HitApi } from "../../../../Store/Action/Api/ApiAction";
import { useModal } from "../../../../shared/modal-views/use-modal";
import useCustomAlertController from "../../../../Hooks/use-custom-alert copy";
import { useState } from "react";
import { deactiveText } from "../../../../Constant/Common/common-variable";
import { updateBroker } from "../../../../Constant/Api/Api";
import Skeleton from "react-loading-skeleton";
import Status from "../../../../Component/ui/common/status";

export const GetBrokersColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
    const { showCustomAlert } = useCustomAlertController();
    const [loadingRows, setLoadingRows] = useState({});
  
    const handleDelete = async (row) => {
      setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
      Object.assign(row, {status : deactiveText})
      try {
        const result = await HitApi(row, updateBroker);
        showCustomAlert(result)
        if (ApiHit) { ApiHit(); }
        
      } catch (err) {
  
      } finally {
        setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
      }
      
    };
  
    const renderCell = (value, row, content) =>loadingRows[row.index] ? <Skeleton /> : content

    return [
      {
        title: <HeaderCell title="#" />,
        dataIndex: 'index',
        key: 'index',
        width: 30,
        render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
      },
      {
        title: <HeaderCell title="Broker Type" className={'font-extrabold'} />,
        dataIndex: 'brokerType',
        key: 'brokerType',
        width: 150,
        render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
      }, {
        title: <HeaderCell title="Broker Ip" className={'font-extrabold'} />,
        dataIndex: 'brokerIp',
        key: 'brokerIp',
        width: 150,
        render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
      },
      {
        title: <HeaderCell title="Broker Port" className={'font-extrabold'} />,
        dataIndex: 'brokerPort',
        key: 'brokerPort',
        width: 150,
        render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
      },
      {
        title: <HeaderCell title="Broker Secure Connection" className={'font-extrabold'} />,
        dataIndex: 'isBrokerSecureConnection',
        key: 'isBrokerSecureConnection',
        width: 150,
        render: (value, row, index) => renderCell(value, row, <Text>{value ? 'Yes' : 'No'|| '---'}</Text>),
      },
      {
        title: <HeaderCell title="Status" className={'font-extrabold'} />,
        dataIndex: 'status',
        key: 'status',
        width: 150,
        render: (value, row, index) => <Status value={value}/>,
      },
      {
        title: <HeaderCell title="Actions" className={'font-extrabold'} />,
        dataIndex: 'action',
        key: 'action',
        width: 130,
        render: (_, row) => (
          <div className="flex items-center gap-3 pe-4">
            <Tooltip size="sm" content={'Edit Broker'} placement="top" color="invert">
                <a href={'/device-manager/broker/edit/' + row._id}>
                <ActionIcon as="span" size="sm" variant="outline" className="hover:text-gray-700">
                  <PencilIcon className="h-4 w-4" />
                </ActionIcon>
              </a>
            </Tooltip>
            <DeletePopover title={`Delete Broker`}  description={`Are you sure you want to delete?`} 
             loading={loadingRows[row.index]}
              onDelete={() => handleDelete(row)} 
            />
          </div>
        ),
      },
    ]
    
}

