import React, { useState, useEffect, useCallback, useMemo } from 'react';
import mqtt from 'mqtt';
import { MQTTIP } from '../../../Constant/Api/Api';
import { useDispatch, useSelector } from 'react-redux';
import { setMqttReceivedData } from '../../../Store/Action/mqtt/mqtt-action';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { useColumn } from '../../../Hooks/use-column';
import { GetMqttReaderColumns } from './data-received-by-reader-column';
import { TableClass } from '../../../Constant/Classes/Classes';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';


const MQTTHandler = () => {
  const dispatch = useDispatch()
  const reduxDashboard = useSelector(state => state.DashboardReducer);
  const reduxMqtt = useSelector(state => state.MQTTReducer)
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [topic, setTopic] = useState('#');
  const [messageInput, setMessageInput] = useState('');

  const mqttConnect = useCallback(() => {
    let messageIndex = 1;
    // Replace with your MQTT broker's address and port
    const mqttClient = mqtt.connect(MQTTIP, {
      clientId: reduxDashboard?.apiJson?.deviceProfile?._id
    });

    mqttClient.on('connect', () => {
      setIsConnected(true);
      mqttClient.subscribe(topic);
    });

    mqttClient.on('message', (topic, message) => {
      const payload = message.toString();
      const parsed = JSON.parse(payload);
      const selectedAnteena = parseInt(reduxDashboard?.apiJson?.selectedDeviceAnteena?.value)


      setMessages((prevMessages) => {
        const index = prevMessages?.length || 0 + 1;  // Serial number based on previous message count
        const newMessage = {
          index,
          topic,
          parsed,
          timestamp: new Date().toLocaleString()
        };
        // Add the new message to the end of the array and slice to keep only the last 10 records
        const updatedMessages = [...prevMessages, newMessage]
        if(selectedAnteena!==null && !isNaN(selectedAnteena)){
          var getItems = updatedMessages?.filter((Obj)=>Obj?.parsed?.data?.antenna === selectedAnteena)
          return getItems;
        }else{
          return updatedMessages;
        }
      });
    });
    mqttClient.on('error', (err) => {
      console.error('MQTT Error:', err);
      mqttClient.end();
    });

    setClient(mqttClient);

    return () => {
      mqttClient.end();
    };
  }, [topic]);

  useEffect(() => {
    if (topic === '#') { setTopic(reduxDashboard?.apiJson?.deviceProfile?._id) }



    mqttConnect();
  }, [mqttConnect, reduxDashboard]);

  const columns = useMemo(() => GetMqttReaderColumns())
  const { visibleColumns } = useColumn(columns);



  return (
    <div className="">
      <div className="mb-4">
        <p>Reader Connection Status: {isConnected ? 'Connected' : 'Disconnected'}</p>
      </div>
      <div style={{ maxHeight: 400, overflow: 'scroll' }}>
        <ControlledTable
          variant="modern"
          // isLoading={loading}
          showLoadingText={true}
          data={messages}
          columns={visibleColumns}
          className={TableClass}
          disablePagination
        />
      </div>
      {/* <div>
        <h2 className="text-xl font-semibold mb-2">Received Messages:</h2>
        <ul className="border p-4 h-64 overflow-y-auto">
          {messages.map((msg, index) => (
            <li key={index} className="mb-2">
              <strong>{msg.topic}:</strong> {msg.payload}{' '}
              <span className="text-sm text-gray-500">({msg.timestamp})</span>
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default MQTTHandler;