import React, { useEffect, useMemo, useState } from 'react';
import PageHeader from '../../../shared/page-header';
import { routes } from '../../../config/routes';
import { useColumn } from '../../../Hooks/use-column';
import { GetBrokersColumns } from './add-broker/borker-column';
import { searchBroker } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { useSelector, useDispatch } from 'react-redux';
import { CompileBrokers } from './promiss/broker-promise';
import { setBrokersApiJson, setBrokesrData } from './store/action/brokers/brokers-action';
import { useModal } from '../../../shared/modal-views/use-modal'
import { TableClass } from '../../../Constant/Classes/Classes';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import { deactiveText } from '../../../Constant/Common/common-variable';
import { ScreenName } from '../../../Constant/Screen/Screen';
import ControlledTable from '../../../Component/ui/table/custom-table';

const Brokers = () => {  
  const dispatch = useDispatch()
  const reduxBrokers = useSelector(state => state.BrokersReducer);
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const { loadingState, setDynamicLoading } = useDynamicLoading()

  useEffect(() => {
    if (reduxBrokers?.doc === null) {
      loadData('init');
    }
  }, [reduxBrokers]);

  const loadData = (type) => {
    const json = reduxBrokers?.searchJson;
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
      Object.assign(json.search, { status: { "$ne": deactiveText } })
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setDynamicLoading({ broker: true })
    HitApi(json, searchBroker).then((result) => {

      if (result?.success !== false) {
        CompileBrokers(result, reduxPagination?.doc).then((compiledData) => {

          dispatch(setBrokesrData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements
          }));
        });
      } else {
        dispatch(setBrokesrData([]));
        dispatch(setPagination({limit: json?.limit,totalPages: 1,number: 1,totalElements: 1}));
      }
      setDynamicLoading({ broker: false })
    });
  };

  const columns = useMemo(() => GetBrokersColumns());
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);
  
  return (
    <div>
      <PageHeader
        screen={ScreenName?.broker}
        href={routes?.panel?.deviceManager?.addbroker}
        title={'Add Broker'}
        columns={columns}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        json={reduxBrokers?.searchJson}
        setAction={setBrokersApiJson}
        ApiHit={loadData}
      />
      <ControlledTable
        variant="modern"
        isLoading={loadingState?.doc?.broker}
        showLoadingText={true}
        data={reduxBrokers?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
      />
    </div>
  );
};

export default Brokers;
