import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchDeviceProfile } from '../../../../Constant/Api/Api';
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { setDeviceProfileData } from '../../../../Store/Action/device-master/device-profile/device-profile-action';
import { Title } from 'rizzui';
import CustomButton from '../../../../Component/ui/form/button/custom-button';
import cn from '../../../../Utils/class-names';

export default function ReaderProfile() {
  const dispatch = useDispatch()
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxDeviceProfile = useSelector((state) => state.DeviceProfileReducer);
  const reduxMappingMaster = useSelector(state => state.MappingMasterReducer)

  useEffect(() => {
    if (reduxMappingMaster?.mappingJson?.reader?.readerProfileId !== null) {
      loadReaderProfile()
    }



  }, [reduxMappingMaster])

  const loadReaderProfile = () => {
    var json = { page: 1, limit: 5000, search: { _id : reduxMappingMaster?.mappingJson?.reader?.readerProfileId } }
    HitApi(json, searchDeviceProfile).then((result) => {

      dispatch(setDeviceProfileData(result))
    })

  }

  let item;
  if (reduxDeviceProfile?.doc !== null) {
    item = reduxDeviceProfile?.doc?.content?.[0]?.capabilities?.map((ele, index) => {
      return <div key={index} className='group my-1.5'>
        <div className={cn('shadow-sm rounded-lg group-hover:cursor-pointer border', ele?._id === reduxMappingMaster?.mappingJson?.reader?._id ? 'bg-red-lighter text-red-main font-bold tracking-wider border border-red-main' : 'bg-white ')}>
          <div className='flex justify-between'>
            <div className='flex items-center justify-between p-3 w-full '>
              <div><label className='group-hover:cursor-pointer text-sm'>{ele?.name}</label></div>
              <div><label className='group-hover:cursor-pointer text-sm'>{ele?.userEntry}</label></div>
            </div>
          </div>
        </div>
        <label className='group-hover:cursor-pointer'>{ele?._id}</label>
      </div>
    })
  }

  return (
    <div>
      <Title as='h5'>Device Profile</Title>
      <div>
        {/* {loadingState?.doc?.reader ? <CustomButton title={'Loading...'} /> : <CustomButton title={'Map Reader'} LeftIcon={<FaPlus />} onClick={() => handleClick()} disabled={!reduxMappingMaster?.mappingJson?.zone?._id} />} */}
        <div>
          {item || 'No Data Found'}
        </div>
      </div>
    </div>
  )
}
