import { DashboardCardData } from "./dashboard-constant";
import { SET_DASHBOARD_DATA, SET_DASHBOARD_API_JSON, SET_DASHBOARD_SEARCH_API_JSON, SET_DASHBOARD_CARD_DATA, SET_DASHBOARD_CHART_DOCS, SET_DASHBOARD_CHART_JSON, SET_DASHBOARD_SELECTED_DATA } from "../../Action/dashbaord/DashboardAction";

const initialState = {
    doc: null,
    childData: null,
    chartData: null,
    apiJson: {},
    searchJson: { page: 1, limit: 10, search: {} },
    chartJson: { type: 'Weekly', date: Date.now() },
    DashboardCardData: DashboardCardData,
    selectedData: [],
    timestamp: Date.now()

}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_CHART_DOCS:
            return ({ ...state, chartData: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_CHART_JSON:
            return ({ ...state, chartJson: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_API_JSON:
            return ({ ...state, apiJson: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_SEARCH_API_JSON:
            return ({ ...state, searchJson: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_CARD_DATA:
            return ({ ...state, DashboardCardData: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_SELECTED_DATA:
            return ({ ...state, selectedData: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default DashboardReducer;
