import { SET_CYCLE_COUNT_DATA, SET_CYCLE_DIFFERENCE_DATA, SET_CYCLE_INVENTORY_DATA } from "../../../Action/dashbaord/cycle-count/cycle-count-action";


const initialState = {
    inventory: null,
    inventoryJson : { page:1, limit : 10, search:{} },
    cycleCount: null,
    cycleCountJson : { page:1, limit : 10, search:{} },
    difference: null,
    differenceJson : { page:1, limit : 10, search:{} },
    timestamp: Date.now()
}

const CycleCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CYCLE_INVENTORY_DATA:
            return ({ ...state, inventory: action.value, timestamp: Date.now() })
        case SET_CYCLE_COUNT_DATA:
            return ({ ...state, cycleCount: action.value, timestamp: Date.now() })
        case SET_CYCLE_DIFFERENCE_DATA:
            return ({ ...state, difference: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default CycleCountReducer;
