/* eslint-disable no-duplicate-imports */
import React, { createContext, useContext, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import cn from '../Utils/class-names';

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export function SortableItem({ children, id, className }) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li
        className={cn('SortableItem', className)}
        ref={setNodeRef}
        style={style}
      >
        {children}
      </li>
    </SortableItemContext.Provider>
  );
}

export function DragHandle({ className }) {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button
      className={cn('h-5 w-5 text-gray-900', className)}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <PiDotsSixVerticalBold />
    </button>
  );
}

export default SortableItem;
