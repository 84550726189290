import { Text } from 'rizzui';
import { HeaderCell } from '../../../Component/ui/table';
import AddSiteMaster from '../../../Form/master/site-master/add-site-master';
import { EditScreen } from '../../../shared/edit-screen';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { deleteSite, searchBuilding, updateSite } from '../../../Constant/Api/Api';
import { getFormattedDate } from '../../../Utils/Utils';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { _id, deactiveText } from '../../../Constant/Common/common-variable';
import { ScreenName } from '../../../Constant/Screen/Screen';
import useCustomAlertController from '../../../Hooks/use-custom-alert';
import { useModal } from '../../../shared/modal-views/use-modal';
import Status from '../../../Component/ui/common/status';

export const GetSiteMasterColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({});

  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, {status : deactiveText, updatedBy : ''})
    try {
      const result = await HitApi(row, updateSite);

      showCustomAlert(result)
      if (ApiHit) { ApiHit(); }
      
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
  };
  
  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{value|| '---'}</Text>),
    },
    {
      title: <HeaderCell title="Site Name" className="font-extrabold" />,
      dataIndex: 'siteName',
      key: 'siteName',
      width: 200,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Building" className="font-extrabold" />,
      dataIndex: 'buildings',
      key: 'buildings',
      width: 200,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Area" className="font-extrabold" />,
      dataIndex: 'area',
      key: 'area',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Created By" className="font-extrabold" />,
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Updated By" className="font-extrabold" />,
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Creation Date" className="font-extrabold" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year', 'hour', 'minute', 'second']) || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Updation Date" className="font-extrabold" />,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year', 'hour', 'minute', 'second']) || '---'}</Text>
      )),
    },{
      title: <HeaderCell title="Status" className="font-extrabold" />,
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (value, row) => <Status value={value} /> ,
    },
    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={ScreenName?.siteMaster}
          row={row}
          onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit Site Master', AddSiteMaster, 800, ApiHit)}

          onDelete={(rowData) => handleDelete(rowData)}
          checkKeys={['buildingIds','locationIds','readerIds']}
        />
      )),
    },
  ];
};
