import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { setUserApiJson } from '../../../Store/Action/user-management/user-action';
import useDeleteKeys from '../../../Hooks/use-delete-keys';
import { addUser, searchGeneral, searchRole, updateUser } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { addUserSchema } from '../../../Utils/validators/user/add-user-scheema';
import { userMasterVariable as variable } from '../../../Constant/variables/user-master/user-master.variable';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import PasswordInput from '../../../Component/ui/form/input/password-input';
import { _id } from '../../../Constant/Common/common-variable';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import useCustomAlertController from '../../../Hooks/use-custom-alert';


export default function AddUserMaster({ row, closeModal, ApiHit }) {
    var dispatch = useDispatch()
    const reduxUser = useSelector(state => state.UserReducer)
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const isEditMode = !!row?.[_id];
    const { errors, validate } = useValidation(addUserSchema(isEditMode));
    const { showCustomAlert } = useCustomAlertController();
    const { loadingState, setDynamicLoading } = useDynamicLoading()
    const selectionType = 'add-user-form'

    useEffect(() => {
        if (row?.[_id] && Object.keys(reduxUser?.apiJson)?.length === 0) {
            loadDefault(row)
        }



    }, [])

    const loadDefault = (row) => {
        var json = reduxUser?.apiJson
        var selected = reduxSelect?.selected
        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        var tj = [
            { label: row?.['gender'], label: row?.['gender'], name: 'gender', selectionType : selectionType },
            { label: row?.['roleName'], label: row?.['roleName'], name: 'roleName', selectionType : selectionType },
            { label: row?.['status'], label: row?.['status'], name: 'status', selectionType : selectionType },
        ]
        
        dispatch(setSearchableSelectSelectedData([...selected, ...tj]))




        dispatch(setUserApiJson(json))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxUser?.apiJson
        const validationErrors = validate(json);



        if (Object.keys(validationErrors).length === 0) {
            setDynamicLoading({ addUser: true })
            if (row?.[_id]) {
                Object.assign(json, { [_id]: row?.[_id] })
                HitApi(json, updateUser).then((result) => {

                    handleClose()
                    showCustomAlert(result)
                    if (ApiHit) { ApiHit() }
                    setDynamicLoading({ addUser: false })
                    showCustomAlert(result)

                })
            } else {
                Object.assign(json, { status: json?.status || 'active' })

                HitApi(json, addUser).then((result) => {
                    handleClose()

                    showCustomAlert(result)
                    if (ApiHit) { ApiHit() }
                    setDynamicLoading({ addUser: false })
                    if(result?.data){
                        
                    }
                })
            }

        } else {

        }
    };

    const handleClose = () => {
        closeModal();
        dispatch(setUserApiJson({}))
        dispatch(setSearchableSelectSelectedData([]))
        
        // var temp_selected = reduxSelect?.selected || [];
        // const existingIndex = temp_selected?.findIndex(item => item?.selectionType === selectionType);
        // if (existingIndex !== -1) {
        //     temp_selected?.splice(existingIndex, 1); // Remove the item at the found index
        //     dispatch(setSearchableSelectSelectedData([...temp_selected])); // Update the state
        // }
    }

    const handleCustomChange = (e) => {
        var json = reduxUser?.apiJson
        const { _id, value } = e
        Object.assign(json, { roleId: _id, roleName: value })
        dispatch(setUserApiJson(json))
    }

    const handleGenderChange = (e) => {
        var json = reduxUser?.apiJson
        const { _id, value } = e
        Object.assign(json, { gender: value })
        dispatch(setUserApiJson(json))
    }

    const handleStatusChange = (e) => {
        var json = reduxUser?.apiJson
        const { _id, value } = e
        Object.assign(json, { status: value })
        dispatch(setUserApiJson(json))
    }



    return (
        <div className='p-10'>
            <form onSubmit={handleSubmit}>
                <div className="space-y-5 lg:space-y-6">
                    <div className='grid grid-cols-2 gap-4'>
                        <CustomInput validate={validate} inputType={'text'} name="firstName" label="First Name" value={reduxUser?.apiJson?.firstName} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} />
                        <CustomInput validate={validate} inputType={'text'} name="lastName" label="Last Name" value={reduxUser?.apiJson?.lastName} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} />
                        <CustomInput validate={validate} inputType={'alphanumeric'} name="username" label="Username" value={reduxUser?.apiJson?.username} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} />
                        {/* <PasswordInput validate={validate} type={'text'} name="password" label="Password" value={reduxUser?.apiJson?.password} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} disabled={row?._id ? true : false} /> */}

                        <PasswordInput validate={validate} name="password" label="Password" value={reduxUser?.apiJson?.password} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} disabled={isEditMode} />
                        <SearchableSelect selectionType={selectionType} validate={validate} name="gender" label="Gender" api={searchGeneral} dynamicSearch={{ 'usedBy': 'gender' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} onChange={handleGenderChange} />
                        <SearchableSelect selectionType={selectionType} validate={validate} name="roleName" label="Role" api={searchRole} getFieldName={'roleName'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} onChange={handleCustomChange} />
                        <CustomInput validate={validate} inputType={'number'} name="contact" label="Contact No" value={reduxUser?.apiJson?.contact} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} maxLength={10} />
                        <CustomInput validate={validate} name="address" label="Address" value={reduxUser?.apiJson?.address} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} />
                        <CustomInput validate={validate} name="email" label="Email Id" value={reduxUser?.apiJson?.email} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} />
                        <CustomInput validate={validate} name="employeeId" label="Employee Id" value={reduxUser?.apiJson?.employeeId} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} />
                        <SearchableSelect selectionType={selectionType} validate={validate} name="status" label="Status" api={searchGeneral} dynamicSearch={{ 'usedBy': 'status' }} getFieldName={'value'} error={errors} reduxState={reduxUser?.apiJson} setAction={setUserApiJson} onChange={handleStatusChange} hide={!row?._id} />
                    </div>
                    <div className='flex gap-3 justify-end'>
                        <CustomButton text={'Cancel'} variant='flat' className={''} onClick={() => handleClose()} />
                        <CustomButton type={'submit'} className={''} text={row?._id ? 'Update' : 'Submit'} loading={loadingState?.doc?.addUser} />
                    </div>
                </div>
            </form>

        </div>
    )
}
