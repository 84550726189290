import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { searchCycleCount } from '../../../../Constant/Api/Api';
import { setNestedPagination, setPagination } from '../../../../Store/Action/Pagination/PaginationAction';
import { setDashboardCycleCountData } from '../../../../Store/Action/dashbaord/cycle-count/cycle-count-action';
import { useColumn } from '../../../../Hooks/use-column';
import { CompileCycleCountData } from './cycle-count-inner-promiss';
import ControlledTable from '../../../../Component/ui/table/custom-table';
import { GetCyleCountColumn } from './cycle-count-inner-column';
import { INVENTORY } from '../../../../Constant/Common/common-variable';

export default function CycleCountInner() {
  const dispatch = useDispatch()
  const reduxCycleCount = useSelector(state => state.CycleCountReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const reduxDashboard = useSelector((state) => state.DashboardReducer);

  useEffect(() => {
    if (reduxCycleCount?.cycleCount === null) {
      loadData('init')
    }

  }, [reduxCycleCount])

  const loadData = (type) => {
    var json = reduxCycleCount?.cycleCountJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: 10 });
    } else {
      Object.assign(json, { page: reduxPagination?.nestedPagination?.['cycle-count']?.number, limit: reduxPagination?.doc?.limit });
    }
    Object.assign(json.search, { tagType: INVENTORY })
    Object.assign(json.search, { locationIds: reduxDashboard?.apiJson?.selectedLocation?._id, currentLocation :  reduxDashboard?.apiJson?.selectedBuilding?._id})

    console.log('CycleCountInner json', json);

    HitApi(json, searchCycleCount).then((result) => {
      CompileCycleCountData(result, reduxPagination?.doc).then((CompiledData) => {
        dispatch(setDashboardCycleCountData(CompiledData));
        var oldNestedPagination = { ...reduxPagination?.nestedPagination }

        Object.assign(oldNestedPagination, {
          ['cycle-count']: {
            limit: json?.limit,
            totalPages: CompiledData?.totalPages,
            number: CompiledData?.number,
            totalElements: CompiledData?.totalElements,
          }
        })
        dispatch(setNestedPagination(oldNestedPagination));
      })
    })
  }

  const columns = useMemo(() => GetCyleCountColumn())
  const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

  return (
    <div>
      <ControlledTable
        nested={'cycle-count'}
        columns={visibleColumns}
        data={reduxCycleCount?.cycleCount?.content}
        ApiHit={loadData}
      />
    </div>
  )
}
