import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useModal } from "../../shared/modal-views/use-modal";
import useCustomAlertController from "../../Hooks/use-custom-alert";
import useDynamicLoading from "../../Hooks/use-dynamic-loading";
import { HitApi } from "../../Store/Action/Api/ApiAction";
import { changeOrderStatus } from "../../Constant/Api/Api";
import Skeleton from "react-loading-skeleton";
import { HeaderCell } from "../../Component/ui/table";
import { Text } from "rizzui";
import { EditScreen } from "../../shared/edit-screen";
import ProductList from "../order/product/product-list";
import { getFormattedDate } from "../../Utils/Utils";
import { routes } from "../../config/routes";
import SearchableSelect from "../../Component/ui/form/select/SearchableSelect";
import { ACTIVE, CANCEL, DISPATCH, DISPATCHED, HOLD, IN_BUILDING, IN_TRANSIT, LOADING, ORDER_INITIATED, ORDER_PICKED, ORDER_PICKING, ORDER_RECEIVED_PARTIALLY, ORDER_RECEIVING, TOLLING, UNHOLD, UNLOADING, inboundText, outboundText } from "../../Constant/Common/common-variable";
import TableActions from "../../Component/ui/table/TableActions";




export const GetInboundOrderColumn = (ApiHit) => {
    const { openModal, closeModal } = useModal();
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const { showCustomAlert } = useCustomAlertController();
    const [loadingRows, setLoadingRows] = useState({});
    const { loadingState = { doc: {} }, setDynamicLoading } = useDynamicLoading();
    const [selectedStatus, setSelectedStatus] = useState()

    const handleStatusChange = (e, rowIndex) => {
        const { label, value, ele, _id, movementStatus } = e
        setSelectedStatus((prevStatus) => ({
            ...prevStatus,
            [rowIndex]: { operationStatus: value, movementStatus },
        }));
    };

    const handleUpdateOrder = (row,) => {
        const status = selectedStatus?.[row?.index] || {};
        const json = {
            orderId: row?._id,
            updatedOrderId: row?._id,
            operationStatus: status?.operationStatus,
            movementStatus: status?.movementStatus,
            orderStatus: status?.operationStatus,
        };
        if (json?.orderId && json?.operationStatus && json?.movementStatus && json?.orderStatus) {
            setDynamicLoading({ [row?.index]: true })
            HitApi(json, changeOrderStatus).then((result) => {
                showCustomAlert(result)
                setDynamicLoading({ [row?.index]: false })
                setTimeout(() => {
                    if (ApiHit) { ApiHit() }
                    setSelectedStatus()
                }, 100);

            })
        } else {
            var result = { status: 404, message: 'Please select action from dropdown first' }
            showCustomAlert(result)
        }
    }

    const renderCell = (value, row, content) => {
        return loadingRows[row?.index] ? <Skeleton /> : content;
    }

    return [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        {
            title: <HeaderCell title="_id" />,
            dataIndex: '_id',
            key: '_id',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value?.slice(-5) || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Dispatch From" className="font-extrabold" />,
            dataIndex: 'dispatchFromName',
            key: 'dispatchFromName',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        }, {
            title: <HeaderCell title="Dispatch To" className="font-extrabold" />,
            dataIndex: 'dispatchToName',
            key: 'dispatchToName',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Order Creation Date" className="font-extrabold" />,
            dataIndex: 'orderDateTime',
            key: 'orderDateTime',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year'], '-', ':', '24', true) || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Expected Arrival Date" className="font-extrabold" />,
            dataIndex: 'expectedArrival',
            key: 'expectedArrival',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year'], '-', ':', '24', true) || '---'}</Text>
            )),
        }, {
            title: <HeaderCell title="Batch No." className="font-extrabold" />,
            dataIndex: 'batchNumber',
            key: 'batchNumber',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Order Type" className="font-extrabold" />,
            dataIndex: 'orderType',
            key: 'orderType',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        }, {
            title: <HeaderCell title="Order Status" className="font-extrabold" />,
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Movement Status" className="font-extrabold" />,
            dataIndex: 'movementStatus',
            key: 'movementStatus',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Products" className="font-extrabold" />,
            dataIndex: 'productIds',
            key: 'productIds',
            width: 100,
            render: (value, row) => <div>
                <label className="cursor-pointer text-blue-700 underline" onClick={() => EditScreen(openModal, closeModal, row, 'Product List', ProductList, 800, ApiHit)}>View Products</label>
            </div>,
        },
        {
            title: <HeaderCell title="Actions" className="font-extrabold" />,
            dataIndex: 'action',
            key: 'action',
            width: 300,
            render: (_, row) => {
                let editUrl = routes?.panel?.order?.editInboundOrder + '/' + row?._id + '/' + row.orderType

                console.log('editUrl', editUrl);

                let hide = false;
                var defaultOptions = OrderDropdownFilter(row?.orderStatus)
                if (row?.orderStatus === ORDER_INITIATED || row?.orderStatus === 'ORDER_RECEIVED_FR') {
                    return <div className="flex items-center">
                        <div><TableActions href={editUrl} iconClassName={''} /></div>
                        <div className="w-48"><SearchableSelect selectionType={row?.index} defaultOptions={defaultOptions} clearable={false} onChange={(e) => handleStatusChange(e, row?.index)} actionButton={true} actionButtonClick={() => handleUpdateOrder(row)} actionButtonloading={loadingState?.doc?.[row.index] || false} hide={hide} /></div>
                    </div>
                }
            },
        },
    ];
};

export const OrderDropdownFilter = (status) => {
    switch (status) {
        case ORDER_INITIATED:
            var td = [
                { label: CANCEL, value: CANCEL, movementStatus: IN_BUILDING },
            ]
            return td;
        case 'ORDER_RECEIVED_FR':
            var td = [
                { label: LOADING, value: LOADING, movementStatus: IN_BUILDING },
                { label: UNLOADING, value: UNLOADING, movementStatus: IN_BUILDING },
            ]
            return td;


    }
}