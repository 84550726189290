import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDynamicLoading from '../../Hooks/use-dynamic-loading';
import { getBuildingFromStorage, setBuildingToStorage } from '../../Storage/Storage';
import { setSearchableSelectSelectedData } from '../../Store/Action/common/searcheable-select/searcheable-select-action';
import { DISPATCH, HOLD, IN_BUILDING, IN_TRANSIT, inboundText, incommingOrderText, outboundText } from '../../Constant/Common/common-variable';
import PageHeader from '../../shared/page-header';
import { ScreenName } from '../../Constant/Screen/Screen';
import { routes } from '../../config/routes';
import { GetOrderColumn, getStatusOptions } from './outbound-column';
import { HitApi } from '../../Store/Action/Api/ApiAction';
import { addOrder, changeOrderStatus, searchOrder, searchOrderTrail } from '../../Constant/Api/Api';
import { GetInboundOrderColumn } from '../inbound/inbound-column';
import { useColumn } from '../../Hooks/use-column';
import { setOrderApiJson, setOrderData, setOrderSearchJson } from '../../Store/Action/order/order-action';
import { CompileOrderData } from '../order/promiss/order-promiss';
import { setPagination } from '../../Store/Action/Pagination/PaginationAction';
import { TableClass } from '../../Constant/Classes/Classes';
import ControlledTable from '../../Component/ui/table/custom-table';
import useCustomAlertController from '../../Hooks/use-custom-alert';
import { HeaderCell } from '../../Component/ui/table';
import { Text } from 'rizzui';
import { getFormattedDate } from '../../Utils/Utils';
import Skeleton from 'react-loading-skeleton';
import SearchableSelect from '../../Component/ui/form/select/SearchableSelect';
import { CompileOutboundJson } from './outbound-promiss';
import toast from 'react-hot-toast';

export default function OutboundOrder() {
    const dispatch = useDispatch()
    const reduxOrder = useSelector(state => state.OrderReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer);
    const [orderTrails, setOrderTrails] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});
    const [loadingRows, setLoadingRows] = useState({});
    const { loadingState = { doc: {} }, setDynamicLoading } = useDynamicLoading();
    const { showCustomAlert } = useCustomAlertController();

    useEffect(() => {
        if (reduxOrder?.doc === null) {
            checkStorage()
        }
    }, [])

    const checkStorage = () => {
        var selectedBuilding = getBuildingFromStorage()
        var findItem = selectedBuilding?.find(Obj => Obj?.selectionType === outboundText)
        if (findItem) {
            dispatch(setSearchableSelectSelectedData(selectedBuilding))
            handleBuilingChange(findItem)
        }
    }

    const handleBuilingChange = (e) => {
        const { value, _id } = e
        var selectedBuilding = getBuildingFromStorage()
        var apiJson = reduxOrder?.apiJson
        var searchJson = reduxOrder?.searchJson
        Object.assign(apiJson, { orderType: outboundText, currentLocation: _id })
        Object.assign(searchJson.search, { orderType: outboundText, currentLocation: _id })
        dispatch(setOrderApiJson(apiJson))
        dispatch(setOrderSearchJson(searchJson))
        saveDataToLocal(e)
        loadData(_id, 'init')
    }

    const saveDataToLocal = (e) => {
        let selectedBuilding = getBuildingFromStorage() || [];
        selectedBuilding = selectedBuilding.filter(obj => obj.selectionType !== outboundText);
        setBuildingToStorage([...selectedBuilding, e]);
    }

    const loadData = (_id, type) => {
        var searchJson = reduxOrder?.searchJson
        if (type === 'init') {
            Object.assign(searchJson, { page: 1, limit: reduxPagination?.doc?.limit });
        } else {
            Object.assign(searchJson, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
        }
        setDynamicLoading({ order: true })
        HitApi(searchJson, searchOrder).then((result) => {
            if (result?.content?.[0]) {
                CompileOrderData(result, reduxPagination?.doc).then((compiledData) => {
                    dispatch(setOrderData(compiledData));
                    dispatch(setPagination({
                        limit: searchJson?.limit,
                        totalPages: compiledData?.totalPages,
                        number: compiledData?.number,
                        totalElements: compiledData?.totalElements,
                    }));
                    setDynamicLoading({ order: false })
                })
            } else {
                dispatch(setOrderData([]));
                setDynamicLoading({ order: false })
            }
        })
    }

    const onClear = () => {
        var searchJson = reduxOrder?.searchJson
        Object.assign(searchJson, { page: 1, limit: reduxPagination?.doc?.limit, search: {} })
        dispatch(setOrderSearchJson(searchJson))
        // resetting or removeing selected builing from local storage
        var selectedBuilding = getBuildingFromStorage() || []
        var filterItems = selectedBuilding?.filter(Obj => Obj?.selectionType !== outboundText)
        setBuildingToStorage(filterItems)
        dispatch(setOrderData([]));
    }

    const GetOrderTail = async (row) => {
        try {
            const json = { page: 1, limit: 10, search: { orderDetails: row?._id } };
            const res = await HitApi(json, searchOrderTrail);
            if (res?.content?.length > 1) {
                const length = res?.content?.length;
                const dd = res?.content?.[length - 2];
                return [{ label: dd?.orderStatus, value: dd?.orderStatus, movementStatus: IN_BUILDING }];
            }
            return [];
        } catch (error) {
            console.error("Error fetching order trail:", error);
            return [];
        }
    };

    const fetchOrderTrail = async (row) => {
        if (!orderTrails[row._id] && row?.orderStatus === HOLD) {
            const trail = await GetOrderTail(row);
            setOrderTrails(prev => ({ ...prev, [row._id]: trail }));
        }
    };

    const handleUpdateOrder = (row) => {
        const status = selectedStatus?.[row?.index] || {};

        console.log('handleUpdateOrder status', status);

        const json = {
            orderId: row?._id,
            updatedOrderId: row?._id,
            operationStatus: status?.operationStatus,
            movementStatus: status?.movementStatus,
            orderStatus: status?.operationStatus,
        };
        console.log('json - - - ', json);

        if (json?.orderId && json?.operationStatus && json?.movementStatus && json?.orderStatus) {
            setDynamicLoading({ [row?.index]: true })
            HitApi(json, changeOrderStatus).then((result) => {
                showCustomAlert(result)
                setDynamicLoading({ [row?.index]: false })

                // Order Dispatch

                if(result){
                    if (status?.operationStatus === DISPATCH && row?.orderType === outboundText) {
                        var tj = { ...row }
                        Object.assign(tj, { currentLocation: row?.dispatchTo, orderType: inboundText, orderStatus: incommingOrderText?.toUpperCase(), movementStatus: IN_TRANSIT })
                        delete tj?._id
                        CompileOutboundJson(tj).then((FinalJson) => {
                            HitApi(FinalJson, addOrder).then((result) => {
                                toast.success('Order Dispatched Successfully')
            
                                if (result.data._id) {
                                    const changeStatusJson = {
                                        orderId: row?._id,
                                        updatedOrderId: result?.data?._id,
                                        operationStatus: result?.data?.orderStatus,
                                        movementStatus: result?.data?.movementStatus,
                                        orderStatus: result?.data?.orderStatus,
                                    };
            
                                    console.log('changeStatusJson', changeStatusJson);
            
                                    HitApi(changeStatusJson, changeOrderStatus).then((result) => {
            
                                        console.log('result', result);
            
                                        showCustomAlert(result)
                                        setTimeout(() => {
                                            loadData()
                                            // setSelectedStatus()
                                        }, 100);
                                    })
                                }
                            })
                        })
                    }
                }
            })
        } else {
            var result = { status: 404, message: 'Please select action from dropdown first' }
            showCustomAlert(result)
        }
        // Check status and Create new Order with Order Type Inbound

        
    };


    const handleStatusChange = (e, rowIndex) => {
        const { value, movementStatus } = e;
        setSelectedStatus(prevStatus => ({
            ...prevStatus,
            [rowIndex]: { operationStatus: value, movementStatus },
        }));
    };

    const renderCell = (value, row, content) => {
        return loadingRows[row?.index] ? <Skeleton /> : content;
    }

    const GetOrderColumn = () => {
        return [
            {
                title: <HeaderCell title="#" />,
                dataIndex: 'index',
                key: 'index',
                width: 10,
                render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
            },
            {
                title: <HeaderCell title="_id" />,
                dataIndex: '_id',
                key: '_id',
                width: 10,
                render: (value, row, index) => renderCell(value, row, <Text>{value?.slice(-5) || '---'}</Text>),
            },
            {
                title: <HeaderCell title="Dispatch From" className="font-extrabold" />,
                dataIndex: 'dispatchFromName',
                key: 'dispatchFromName',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{value || '---'}</Text>
                )),
            }, {
                title: <HeaderCell title="Dispatch To" className="font-extrabold" />,
                dataIndex: 'dispatchToName',
                key: 'dispatchToName',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{value || '---'}</Text>
                )),
            },
            {
                title: <HeaderCell title="Order Creation Date" className="font-extrabold" />,
                dataIndex: 'orderDateTime',
                key: 'orderDateTime',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year'], '-', ':', '24', true) || '---'}</Text>
                )),
            },
            {
                title: <HeaderCell title="Expected Arrival Date" className="font-extrabold" />,
                dataIndex: 'expectedArrival',
                key: 'expectedArrival',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year'], '-', ':', '24', true) || '---'}</Text>
                )),
            }, {
                title: <HeaderCell title="Batch No." className="font-extrabold" />,
                dataIndex: 'batchNumber',
                key: 'batchNumber',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{value || '---'}</Text>
                )),
            },
            {
                title: <HeaderCell title="Order Type" className="font-extrabold" />,
                dataIndex: 'orderType',
                key: 'orderType',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{value || '---'}</Text>
                )),
            }, {
                title: <HeaderCell title="Order Status" className="font-extrabold" />,
                dataIndex: 'orderStatus',
                key: 'orderStatus',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{value || '---'}</Text>
                )),
            },
            {
                title: <HeaderCell title="Movement Status" className="font-extrabold" />,
                dataIndex: 'movementStatus',
                key: 'movementStatus',
                width: 100,
                render: (value, row) => renderCell(value, row, (
                    <Text className="font-medium text-gray-700">{value || '---'}</Text>
                )),
            },
            {
                title: <HeaderCell title="Action" />,
                dataIndex: 'action',
                key: 'action',
                width: 10,
                render: (value, row) => {
                    if (row?.orderStatus !== DISPATCH) {
                        fetchOrderTrail(row);
                        const options = row?.orderStatus === HOLD
                            ? orderTrails[row._id] || []
                            : getStatusOptions(row.orderStatus);

                        return (
                            <div className="w-48">
                                <SearchableSelect
                                    selectionType={row.index}
                                    defaultOptions={options}
                                    clearable={false}
                                    actionButton={true}
                                    actionButtonClick={() => handleUpdateOrder(row)}
                                    actionButtonloading={loadingState?.doc?.[row.index] || false}
                                    onChange={(e) => handleStatusChange(e, row?.index)}
                                    value={selectedStatus?.[row?.index]?.operationStatus || null} // bind selected value
                                />
                            </div>
                        );
                    }
                }
            },
        ];
    };

    const columns = useMemo(() => GetOrderColumn(), [orderTrails, selectedStatus]);
    const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

    return (
        <div>
            <PageHeader
                screen={ScreenName?.outboundOrder}
                href={routes?.panel?.order?.createOuboundOrder + '/' + reduxOrder?.apiJson?.currentLocation + '/' + reduxOrder?.apiJson?.orderType}
                columns={columns}
                checkedColumns={checkedColumns}
                setCheckedColumns={setCheckedColumns}
                json={reduxOrder?.searchJson}
                setAction={setOrderApiJson}
                onChange={(e) => handleBuilingChange(e)}
                onClear={onClear}
                disableCreate={reduxOrder?.searchJson?.search?.currentLocation === undefined && true}
            />

            <ControlledTable
                screen={ScreenName?.outboundOrder}
                variant="modern"
                isLoading={loadingState?.doc?.order}
                data={reduxOrder?.doc?.content}
                columns={visibleColumns}
                className={TableClass}
                json={reduxOrder?.searchJson}
                setAction={setOrderSearchJson}
                ApiHit={loadData}
            />

        </div>
    )
}

