import React from 'react'
import Upload from '../../Component/ui/upload/upload'
import CustomInput from '../../Component/ui/form/input/custom-input'
import { useState } from 'react';
import CustomButton from '../../Component/ui/buttons/custom-button';
import { setApkUploadApiJson } from '../../Store/Action/apk-upload/apk-action';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../Constant/Api/Api';
import { HitApiFormData } from '../../Store/Action/Api/ApiAction';
import { ApkUploadSchema } from '../../Utils/validators/apk-upload/apk-upload-schema';
import useValidation from '../../Hooks/useValidation';
import useAlertController from '../../Hooks/use-alert-controller';

function AddUploadApk({closeModal, ApiHit }) {
  const reduxapkUpload = useSelector(state => state.ApkUploadReducer)
  const { errors, validate } = useValidation(ApkUploadSchema);
  const { showCustomAlert } = useAlertController();

  const [apkError, setApkError] = useState()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {

    const file = e.target.files[0]
    if (file && file.name.endsWith('.apk')) {
      setApkError('')
      dispatch(setApkUploadApiJson({ ...reduxapkUpload?.apiJson, file }))
    } else {
      setApkError("Only .apk files are allowed.");
    }

    dispatch(setApkUploadApiJson({ ...reduxapkUpload?.apiJson, file: e.target.files[0] }))
  }
  const handleSubmit = (e) => {
    const { file, versionName, versionCode } = reduxapkUpload?.apiJson;
    if (!file) {
      setApkError("Please upload an APK file.");
      return;
    }
    setApkError('')
    e.preventDefault();
    var json = reduxapkUpload?.apiJson
    const validationErrors = validate(json);

    if (Object.keys(validationErrors).length === 0 && apkError === '') {
      setLoading(true)
      const formData = new FormData();
      formData.append('file', file);
      formData.append('versionName', versionName);
      formData.append('versionCode', versionCode)

      HitApiFormData(formData, uploadFile).then((result) => {

        if(result?.status === 201){
          setLoading(false)
          showCustomAlert({
              type:'success',
              title: 'Success!',
              message: result?.message,
          });
          handleClose()
          ApiHit()
        }
        else {
          setLoading(false)
          showCustomAlert({
              type: 'error',
              title: 'Error!',
              message: result?.message,
          });
          handleClose()
        }
        setLoading(false);
      }).catch((error) => {
        console.error("API Error: ", error);
        setLoading(false);
      });

    }
  };


  const handleClose = () => {
    closeModal()
    dispatch(setApkUploadApiJson({}))
}
  return (
    <div>
      <div className='grid grid-cols-1 gap-4 p-10'>
        <Upload label={"Upload Apk"} onChange={handleFileChange} error={apkError} />
        <CustomInput important={true} name="versionName" label="Version Name" value={reduxapkUpload?.apiJson?.versionName} error={errors} setAction={setApkUploadApiJson} reduxState={reduxapkUpload?.apiJson} validate={validate} />
        <CustomInput important={true} name="versionCode" label="Version Code" value={reduxapkUpload?.apiJson?.versionCode} error={errors} setAction={setApkUploadApiJson} reduxState={reduxapkUpload?.apiJson} validate={validate} />
        <CustomButton type={'submit'} text={'Submit'} loading={loading} onClick={handleSubmit} />
      </div>

    </div>
  )
}

export default AddUploadApk





