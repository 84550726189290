import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import {  updateMqttConfig } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import AddConfigurationMaster from '../../../Form/master/configuration-master/add-configuration-master';
import { useNavigate } from 'react-router-dom';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { deactiveText } from '../../../Constant/Common/common-variable';
import useCustomAlertController from '../../../Hooks/use-custom-alert';


export const GetConfigurationMasterColumns = (ApiHit) => {
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({})
  const navigate = useNavigate();



  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, {status : deactiveText})
    
    try {
      const result = await HitApi(row, updateMqttConfig);
      showCustomAlert(result)

    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
    
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

    return [
      {
        title: <HeaderCell title="#" />,
        dataIndex: 'index',
        key: 'index',
        width: 10,
        render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
      },
      {
        title: <HeaderCell title="Name" className="font-extrabold" />,
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Type" className="font-extrabold" />,
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Description" className="font-extrabold" />,
        dataIndex: 'description',
        key: 'description',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
     
      {
        title: <HeaderCell title="Actions" className="font-extrabold" />,
        dataIndex: 'action',
        key: 'action',
        width: 300,
        render: (_, row) => renderCell(null, row, (
          <TableActions
            screen={ScreenName.configurationMaster}
            row={row}
            onEdit={(rowData) => EditScreen(false, false, rowData, 'Edit Configuration Master', AddConfigurationMaster, 1200, ApiHit ,navigate,`/master/configuration/edit/${row._id}`)}
            onDelete={(rowData) => handleDelete(rowData)}
            checkKeys={[]}
          />
        )),
      },
    ];
  
}
