import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { activate, download } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Loader, Switch, Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';
import { useModal } from '../../../shared/modal-views/use-modal';
import Status from '../../../Component/ui/common/status';
import DownloadIcon from '../../../Constant/Icons/download-icon';


export const ApkUploadColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({})
  const [loadActive, setLoadActive] = useState(false)


  const handleToggle = (row, checked) => {
    setLoadActive(row?._id)
    HitApi(row, activate).then((result) => {
      console.log("result", result);
      setLoadActive(false)
      ApiHit()
    });
  };

// const handleDonwnloadApk = (ID) =>{
//   let json = {
//       _id :ID
//   }

//   // HitApi(json ,download).then((result)=>{
//   //   console.log(result);
    
//   // })

// }
const handleDownloadApk = (ID) => {
    const url = `/api/download?_id=${ID}`;

    fetch(url, {
        method: 'GET',
    })
    .then((response) => {
        if (response.ok) {
            return response.blob(); 
        }
        throw new Error('Failed to download APK');
    })
    .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `apk_${ID}.apk`;
        link.click();
    })
    .catch((error) => {
        console.error('Error downloading APK:', error);
    });
};



  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 8,
      render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
    },
    {
      title: <HeaderCell title="File Name" className="font-extrabold" />,
      dataIndex: 'filename',
      key: 'filename',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="File type" className="font-extrabold" />,
      dataIndex: 'fileType',
      key: 'fileType',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="File Size" className="font-extrabold" />,
      dataIndex: 'fileSize',
      key: 'fileSize',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="upload Date" className="font-extrabold" />,
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      width: 100,
      render: (value, row) => <Status value={value} />,
    },
    {
      title: <HeaderCell title="Version Name" className="font-extrabold" />,
      dataIndex: 'versionName',
      key: 'versionName',
      width: 100,
      render: (value, row) => <Status value={value} />,
    },
    {
      title: <HeaderCell title="Version Code" className="font-extrabold" />,
      dataIndex: 'versionCode',
      key: 'versionCode',
      width: 100,
      render: (value, row) => <Status value={value} />,
    },
    {
      title: <HeaderCell title="Active" className="font-extrabold" />,
      dataIndex: 'active',
      key: 'active',
      width: 100,
      render: (value, row) => (
        <div className='flex items-center justify-center'>
          {loadActive === row?._id ? <Loader /> :
            <Switch
              checked={!!value}
              onChange={(checked) => handleToggle(row, checked)}
              className="switch-class"
              size='sm'
            />
          }
        </div>
      ),
    },
    {
      title: <HeaderCell title="Download Apk" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width:10,
      render: (_, row) => renderCell(null, row, (
        <div className='flex items-center justify-center' onClick={()=>handleDownloadApk(row?._id)}>
          <DownloadIcon />
        </div>
      )),
    },
  ];

}
