import React, { useState } from 'react'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import CustomInput from '../component/form/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import { setDeviceProfileApiJson } from '../../../../Store/Action/device-master/device-profile/device-profile-action'
import { searchConfig } from '../../../../Constant/Api/Api'

export default function AddNewDeviceProfile({errors, msg, setMsg}) {
  const dispatch = useDispatch()
  const reduxDevices = useSelector(state => state.DevicesReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxDeviceProfile = useSelector(state => state.DeviceProfileReducer)
  const [data, setData] = useState(null)

  const handleConfigSelect = (e, name) => {
    const { ele } = e;
    const json = reduxDevices?.apiJson;

    if (ele && name === 'configName') {
      json.deviceProfile = json.deviceProfile || {};
      Object.assign(json.deviceProfile, {
        profileUsage: ele?.configName,
        profileId: ele?._id,
      });
      setData(ele)
      dispatch(setDeviceProfileApiJson(ele?.config?.[0]))
    }
  };


  const customOnChange = (e, index) => {
    var json = reduxDeviceProfile?.apiJson
    const { value, name } = e?.target
    Object.assign(json?.capabilities?.[index] || {}, { userEntry: value })
    dispatch(setDeviceProfileApiJson(json))


  }

  return (
    <div className="grid grid-cols-1 gap-4 my-4">
      <SearchableSelect name="configName" _id={reduxDevices?.apiJson?.deviceProfile?.profileId} label={'Device Profile'} api={searchConfig} getFieldName={'configName'} error={errors} reduxState={reduxDeviceProfile?.apiJson} setAction={setDeviceProfileApiJson} onChange={(e) => handleConfigSelect(e, 'configName')} setData={setData} />
      { msg?.['profileId'] && <span className='text-red text-[11px] rizzui-input-error-text'>{msg?.['profileId']}</span> } 
      <div className='grid grid-cols-4 gap-4'>
        {data?.config?.[0]?.capabilities?.map((ele, index) => {
          return <div>
            <CustomInput userMode name={ele?.name} label={ele?.name +' '+ele?.value} parent={reduxDeviceProfile?.apiJson?.capabilities?.[index]} className={'capitalize'} reduxState={reduxDeviceProfile?.apiJson} setAction={setDeviceProfileApiJson} onChange={(e) => customOnChange(e, index)} />
          </div>
        })}
      </div>
    </div>
  )
}
