import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { deleteProduct, updateProduct } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { routes } from '../../../config/routes';
import Status from '../../../Component/ui/common/status';
import { deactiveText } from '../../../Constant/Common/common-variable';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';


export const GetProductMasterColumns = (openModal, closeModal, ApiHit) => {
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({})


  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, { status: deactiveText })
    try {
      const result = await HitApi(row, updateProduct);
      showCustomAlert(result)
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
    },
    {
      title: <HeaderCell title="Product Name" className="font-extrabold" />,
      dataIndex: 'productName',
      key: 'productName',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Product Description" className="font-extrabold" />,
      dataIndex: 'productDescription',
      key: 'productDescription',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Product Group" className="font-extrabold" />,
      dataIndex: 'productGroup',
      key: 'productGroup',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Selling Cost" className="font-extrabold" />,
      dataIndex: 'sellingCost',
      key: 'sellingCost',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Weight" className="font-extrabold" />,
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Grade" className="font-extrabold" />,
      dataIndex: 'grade',
      key: 'grade',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Batch" className="font-extrabold" />,
      dataIndex: 'captureBatchNo',
      key: 'captureBatchNo',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value === true ? 'true' : value === false ? 'false' : value ? value : '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Lot" className="font-extrabold" />,
      dataIndex: 'captureLotNo',
      key: 'captureLotNo',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value === true ? 'true' : value === false ? 'false' : value ? value : '---'}</Text>
      )),
    }, {
      title: <HeaderCell title="Status" className="font-extrabold" />,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, row) => renderCell(value, row, <Status value={value} />),
    },
    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={ScreenName.customerMaster}
          row={row}
          href={routes?.panel?.master?.editProduct}
          // onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit Product Master', AddProductMaster, 1200, ApiHit)}
          onDelete={(rowData) => handleDelete(rowData)}
          checkKeys={[]}
        />
      )),
    },
  ];

}
