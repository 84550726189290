import React, { useEffect } from 'react'
import '@fontsource/hind-vadodara/300.css';
import '@fontsource/hind-vadodara/400.css';
import '@fontsource/hind-vadodara/500.css';
import '@fontsource/hind-vadodara/600.css';
import '@fontsource/hind-vadodara/700.css';
import './App.css'
import './custom.css'
import 'react-loading-skeleton/dist/skeleton.css'
import Layout from './Layout/Layout';
import GlobalDrawer from './shared/drawer-views/container';
import GlobalModal from './shared/modal-views/container';
import Login from './WebView/login/login';
import GlobalConfirmBox from './shared/confirm/container';
import "./i18n";
import { GetBuildVersion, getAuthenticatedUser, getAuthenticatedUserWithRoles, logOutAuthenticatedUser, removeBuildingFromStorage } from './Storage/Storage';
import { Toaster } from 'react-hot-toast';
import { LauncherScreen } from './WebView/launcher/launcher-screen';
import { buildVersion } from './Constant/Api/Api';
export default function App() {

  let user = getAuthenticatedUserWithRoles()

  useEffect(() => {

    console.log('user', user); 

  }, [])

  const Check = () => {
    const version = GetBuildVersion();
    if (version !== null && version !== undefined && buildVersion === version) {
      return true;
    }
    handleClear();
  };

  const handleClear = () => {
    logOutAuthenticatedUser()
    removeBuildingFromStorage()
  }

  return (
    <div>
      {
        user ? Check() ? <>
          <Layout />
          <GlobalDrawer />
          <GlobalModal />
          <GlobalConfirmBox />
          <Toaster position='top-center' containerStyle={{ zIndex: 99999 }} />
        </> : <Login /> : <Login />
      }
    </div>
  )
}
