import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { searchDevice, searchDeviceProfile } from '../../../../Constant/Api/Api'
import CustomInput from '../component/form/custom-input'

export default function OldProfile() {
    const dispatch = useDispatch()
    const reduxDevices = useSelector(state => state.DevicesReducer)
    const [data, setData] = useState(null)

    useEffect(() => {
        if (reduxDevices?.apiJson?.profileId && data === null) {
            loadDefaultDeviceProfile()
        }

    }, [data, reduxDevices])

    const loadDefaultDeviceProfile = () => {
        var json = { page: 1, limit: 1, search: { _id: reduxDevices?.apiJson?.profileId } }
        HitApi(json, searchDeviceProfile).then((result) => {

            setData(result?.content?.[0])
        })
    }

    return (
        <div>
            <div className='grid grid-cols-4 gap-4'>
                {data?.capabilities?.map((ele, index) => {
                    return <div>
                        <CustomInput name={ele?.name} label={ele?.name} parent={ele} className={'capitalize'}  disabled/>
                    </div>
                })}
            </div>
        </div>
    )
}
