import { GetPageCount } from "../../../../Utils/Utils"

export const CompileInventoryData = (data, pagination) => {
    return new Promise((resolve, reject) => {
        Object.assign(pagination, { number: isNaN(pagination?.number) ? 1 : pagination?.number })
        var td = {
            content: data?.content?.map((ele, index) => Object.assign(ele, { index: GetPageCount(pagination, index)})),
            totalElements: data?.totalElements, number: data?.number + 1, totalPages: data?.totalPages,
        }
        resolve(td)
    })
}