import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Loader } from 'rizzui';
import { CANCELLED, CONSUME, DISPATCHED, HOLD, InventoryText, LOADING, ORDER_RECEIVING, TOLLING, UNHOLD, UNLOADING, _id, readerText } from '../../../Constant/Common/common-variable';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import cn from '../../../Utils/class-names';
import { MQTTIP, searchDeviceProfile } from '../../../Constant/Api/Api';
import { setDashboardApiJson, setDashboardSelectedData } from '../../../Store/Action/dashbaord/DashboardAction';
import mqtt from 'mqtt';
import Status from '../../../Component/ui/common/status';

export default function ReaderItem({ ele, onClick, selected }) {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer);
    const [data, setData] = useState(null);
    const { loadingState, setDynamicLoading } = useDynamicLoading();
    const [isConnected, setIsConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    useEffect(() => {




    }, []);

    const loadDeviceProfile = async (ele) => {
        var json = { page: 1, limit: 1, search: { [_id]: ele?.profileId } }
        var result = await HitApi(json, searchDeviceProfile)
        if (result?.content?.[0]) {
            var json = reduxDashboard?.apiJson
            // updayting resudx selcted data
            let temp_selected = [...(reduxDashboard?.selectedData || [])];
            let findItem = temp_selected?.find(obj => obj?._id === ele?._id);
            if (findItem) {
                temp_selected = temp_selected.filter(item => item?.isParent)
                dispatch(setDashboardSelectedData(temp_selected));
                Object.assign(json, { deviceProfile: null })
                setIsConnected(null)
            } else {
                temp_selected = temp_selected.filter(item => item?.isParent)
                temp_selected?.push(ele)
                dispatch(setDashboardSelectedData(temp_selected));
                Object.assign(json, { deviceProfile: result?.content?.[0] })
                dispatch(setDashboardApiJson(json))
                setIsConnected(null)
            }

        }
    };

    
    return (
        <div className='w-60 group'>
            <div className={cn('card cursor-pointer p-4 justify-between border-2 rounded-md ', reduxDashboard?.selectedData?.find((Obj) => Obj.displayText === ele?.displayText) ? 'bg-red-lighter border-red-custom_light' : '')} onClick={() => loadDeviceProfile(ele)}>
                <div className='flex justify-between cursor-pointer'>
                    <label className='capitalize cursor-pointer'>{ele?.displayText?.replaceAll('_', ' ')}</label>
                    <label className='cursor-pointer'>
                        {data !== null ? data : ele?.api ? <Loader /> : null}
                        {isConnected?._id ? <Badge color={'success'} renderAsDot /> : null}
                    </label>
                </div>
            </div>
        </div>
    );
}

export const ConstructQuery = (ele, data) => {
    const baseQuery = { ...ele?.query, search: ele?.query?.search || {} };
    const buildingId = data?.apiJson?.selectedBuilding?._id;
    const customTitle = [HOLD, UNHOLD, CONSUME, LOADING, UNLOADING, DISPATCHED, CANCELLED, TOLLING];
    const inventoryTitle = [HOLD, CONSUME, ORDER_RECEIVING];
    if (ele?.serverKeys === InventoryText) {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, currentLocation: buildingId } };
    }
    else if (ele?.serverKeys === InventoryText && inventoryTitle.includes(ele?.title)) {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, currentLocation: buildingId } };
    } else if (ele?.serverKeys === 'Orders' || customTitle.includes(ele?.title)) {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, dispatchTo: buildingId } };
    } else if (ele?.serverKeys === 'Readers') {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, buildingIds: { $in: [buildingId] } } };
    } else {
        ele.query = baseQuery;
    }
    return ele.query;
};
