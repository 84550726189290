
import { SET_APK_UPLOAD_API_JSON, SET_APK_UPLOAD_DATA } from "../../Action/apk-upload/apk-action"

const initialState = {
    doc: null,
    apiJson: {},
    searchJson: { page: 1, limit: 10, search: {} },
    timestamp: Date.now()
}

const ApkUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APK_UPLOAD_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_APK_UPLOAD_API_JSON:
            return ({ ...state, apiJson: action.value, timestamp: Date.now() })
  
        default:
            return state;
    }
}

export default ApkUploadReducer;
