import { searchCycleCount, searchDevice, searchOrder, searchTag } from "../../../Constant/Api/Api";
import { ACTIVE, ActiveInventoryText, CANCELLED, CONSUME, DISPATCHED, HOLD, INVENTORY, InactiveInventoryText, LOADING, ORDER_INITIATED, ORDER_PICKED, ORDER_RECEIVED, ORDER_RECEIVING, TOLLING, UNHOLD, UNLOADING, inboundOrder, inboundText, incommingOrder, incommingOrderText, outboundOrder, outboundText, readerText } from "../../../Constant/Common/common-variable";

export const DashboardCardData = [
    {
        _id: 0,
        title: 'Inventory',
        displayText: 'Inventory',
        query: { page: 1, limit: 1, search: { tagType: INVENTORY } },
        serverKeys : 'Inventory',
        api: searchTag,
        isParent : true,
        enableCursor: true,
        ApiHit: false,
        child: [
            { _id: 9, title: ACTIVE, displayText: 'Active', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : ACTIVE}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 10, title: HOLD, displayText: 'Hold', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : HOLD}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 11, title: DISPATCHED, displayText: 'Dispatch', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : DISPATCHED}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            // { _id: 11, title: DISPATCHED, displayText: 'DISPATCHED', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : DISPATCHED}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 12, title: CONSUME, displayText: 'Consume', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : CONSUME}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 13, title: CANCELLED, displayText: 'Cancelled', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : CANCELLED}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 14, title: TOLLING, displayText: 'Tolling', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : TOLLING}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 15, title: 'FILLED', displayText: 'Filled', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{status : 'FILLED'}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 16, title: 'EMPTY', displayText: 'Empty', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{status : 'EMPTY'}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 1, title: LOADING, displayText: 'Loading', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : LOADING}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 1, title: UNLOADING, displayText: 'Unloading', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : UNLOADING}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 1, title: ORDER_PICKED, displayText: 'Order Picked', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : ORDER_PICKED}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },
            { _id: 1, title: ORDER_RECEIVED, displayText: 'Order Received', serverKeys : 'Inventory', query: { page: 1, limit: 1, search: { $or:[{opreationStatus : ORDER_RECEIVED}] } }, api: searchTag, enableCursor: true, ApiHit: true, active : false },

        ],
        active : false
    }, {
        _id: 1,
        title: 'Total Fixed Readers',
        displayText: 'Readers',
        serverKeys : 'Readers',
        query: { page: 1, limit: 10, search: { deviceType : 'Reader' } },
        isParent : true,
        child:[],
        api: searchDevice,
        ApiHit: true,
        loadData : true
    }, {
        _id: 2,
        title: 'Orders',
        displayText: 'Orders',
        serverKeys : 'Orders',
        query: { page: 1, limit: 1, search: { } },
        isParent : true,
        api: searchOrder,
        // ApiHit: true,
        active : false,
        child: [
            { _id: 0, title: ORDER_INITIATED, displayText: 'Order Initiated', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : ORDER_INITIATED}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 0, title: ORDER_RECEIVED, displayText: ORDER_RECEIVED, serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : ORDER_RECEIVED}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 0, title: incommingOrderText, displayText: incommingOrderText, serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : incommingOrderText?.toUpperCase()}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 1, title: LOADING, displayText: 'Loading', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : LOADING}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 2, title: UNLOADING, displayText: 'Unloading', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : UNLOADING}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 3, title: HOLD, displayText: 'Hold', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : HOLD}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 4, title: UNHOLD, displayText: 'Unhold', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : UNHOLD}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 5, title: DISPATCHED, displayText: 'Dispatch', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : DISPATCHED}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 6, title: CONSUME, displayText: 'Consume', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : CONSUME}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 7, title: CANCELLED, displayText: 'Cancelled', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : CANCELLED}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
            { _id: 8, title: TOLLING, displayText: 'Tolling', serverKeys : 'Orders', query: { page: 1, limit: 1, search: { $or:[{orderStatus : TOLLING}] } }, api: searchOrder, enableCursor: true, ApiHit: true, active : false },
        ]
    },
    {
        _id: 4,
        title: 'Errors',
        displayText: 'Errors',
        serverKeys : 'Errors',
        query: { page: 1, limit: 1, search: { isError : true, $or:[{tagType : INVENTORY}]} },
        enableCursor: true,
        isParent : true,
        api: searchTag,
        ApiHit: true,
        active : false
    },{
        _id: 5,
        title: 'Cycle Count',
        displayText: 'Cycle Count',
        serverKeys : 'Cycle Count',
        query: { page: 1, limit: 1, search: { } },
        enableCursor: true,
        // isLoadFile : true,
        isParent : true,
        child:[],
        api: searchCycleCount,
        ApiHit: true,
        loadData : true
    },
]