import React, { useEffect, useState } from 'react';
import { validationSchema } from '../../Utils/validators/validationSchema';
import useValidation from '../../Hooks/useValidation';
import CustomInput from '../../Component/ui/form/input/custom-input';
import { useDispatch, useSelector } from 'react-redux';
import { setRolesAndPermission, setRolesAndPermissionApiJson } from '../../Store/Action/RolesAndPermission/RolesAndPermissionAction';
import PermissionComponent from './PermissionComponent';
import CustomButton from '../../Component/ui/buttons/custom-button';
import { Text } from 'rizzui';
import { ConstructJson, ReCreateJsonForEdit, setAllPermissionsFalsePromise } from './permission-promiss';
import { HitApi } from '../../Store/Action/Api/ApiAction';
import { addRole, updateRole } from '../../Constant/Api/Api';
import { _id } from '../../Constant/Common/common-variable';
import { AllPermissionList } from '../../Store/Reducer/RolesAndPermission/AllPermission';
import useCustomAlertController from '../../Hooks/use-custom-alert copy';
import useDynamicLoading from '../../Hooks/use-dynamic-loading';

const rolesAndPermissionScheme = {
  roleName: validationSchema.string('Role Name Field is Required')
    .min(6, 'Role Name Field should be min 6 characters long'),
  permission: validationSchema.array('Permission is Required')
};

const AddRole = ({ closeModal, row, ApiHit }) => {
  var dispatch = useDispatch()
  const reduxRolesAndPermission = useSelector(state => state.RolesAndPermissionReducer)
  const { errors, validate } = useValidation(rolesAndPermissionScheme);
  const { showCustomAlert } = useCustomAlertController();
  const { loadingState, setDynamicLoading } = useDynamicLoading()



  useEffect(() => {
    if (row?.[_id] || row?.id) {
      loadDefault(row)
    }
  }, [])

  const loadDefault = (row) => {
    const modifiedJson = ReCreateJsonForEdit(row, reduxRolesAndPermission?.doc);
    var tj = {
      roleName: row?.roleName,
      permission: modifiedJson
    }
    dispatch(setRolesAndPermissionApiJson(tj))
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    var json = reduxRolesAndPermission?.apiJson
    Object.assign(json, { [name]: value });
    dispatch(setRolesAndPermissionApiJson(json))
    validate({ ...reduxRolesAndPermission?.apiJson, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ConstructJson(reduxRolesAndPermission?.doc).then((FinalJson) => {
      if (FinalJson) {
        var json = {
          roleName: reduxRolesAndPermission?.apiJson?.roleName,
          allowedEndPoints: FinalJson?.allowedEndPoints,
          permission: FinalJson?.permission,
        }
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
          setDynamicLoading({ addRole: true })
          if (row?.[_id] || row?.id) {
            Object.assign(json, { _id: row?.[_id] || row?.id })
            HitApi(json, updateRole).then((result) => {
              showCustomAlert(result)
              if (ApiHit) { ApiHit() }
              handleClose()
              setDynamicLoading({ addRole: false })

            })
          } else {
            HitApi(json, addRole).then((result) => {
              showCustomAlert(result)
              if (ApiHit) { ApiHit() }
              handleClose()
              setDynamicLoading({ addRole: false })

            })
          }
        }
      }else{
        setDynamicLoading({addRole : false})
      }
    })
  }

  const handleClose = () => {
    closeModal();
    setAllPermissionsFalsePromise(AllPermissionList).then((FinalData) => {
      if (FinalData) {

        dispatch(setRolesAndPermissionApiJson({}))
        dispatch(setRolesAndPermission(FinalData))
      }
    })
  }

  return (
    <div className='p-10'>
      <form onSubmit={handleSubmit}>
        <div className="space-y-5 lg:space-y-6">
          <div className='grid grid-cols-2 gap-4'>
            <CustomInput
              name="roleName"
              inputType={'text'}
              label="Role Name"
              value={reduxRolesAndPermission?.apiJson?.roleName}
              onChange={handleChange}
              error={errors}
              reduxState={reduxRolesAndPermission?.apiJson}
              setAction={setRolesAndPermissionApiJson}
            />
          </div>
          <div className='flex flex-col'>
            <Text as="h6" className='font-bold'>Access</Text>
            {errors?.permission && <span className='text-red text-[13px] mt-0.5 rizzui-input-error-text'>{errors?.permission}</span>}
          </div>
          <div className='overflow-auto max-h-[32rem]'>
            <PermissionComponent permissionsData={reduxRolesAndPermission?.doc} />
          </div>
          <div className='flex gap-3 justify-end'>
            <CustomButton text={'Cancel'} className={''} onClick={() => handleClose()} />
            <CustomButton type={'submit'} className={''} loading={loadingState?.doc?.addRole} text={row?.[_id] ? 'Update' : 'Submit'} />
          </div>
        </div>
      </form>

    </div>
  );
};

export default AddRole;
