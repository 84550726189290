import { useNavigate } from "react-router-dom";
import { useModal } from "../shared/modal-views/use-modal";
import { useDispatch } from "react-redux";
import { useReduxReset } from "../Hooks/use-redux-reset";
import { setOrderApiJson, setOrderSearchJson } from "../Store/Action/order/order-action";
import cn from "../Utils/class-names";

export function MenuLink({ item, isChildActive }) {
    const navigate = useNavigate();
    const { closeModal, closeAllModals } = useModal();
    const dispatch = useDispatch()
    const resetAllState = useReduxReset('ALL');
    const Icon = item?.icon;

    const handleNavigate = (url) => {
        resetAllState()
        dispatch(setOrderApiJson({}))
        // dispatch(setOrderSearchJson({page:1,limit : 10, search:{}}))
        navigate(url)
        closeAllModals()
        // window.location.href = url
    }

    return (
        <label
            // to={item?.href}
            onClick={() => handleNavigate(item?.href)}
            className={cn(
                'relative flex w-full items-center justify-between rounded-md px-3.5 py-2 font-medium capitalize text-gray-900',
                isChildActive
                    ? 'before:top-2/5 bg-gray-100 text-primary before:absolute before:-start-2.5 before:block before:h-4/5 before:w-1 before:rounded-ee-md before:rounded-se-md before:bg-primary dark:bg-gray-200 2xl:before:-start-2.5'
                    : 'text-gray-900 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-700/90 dark:hover:bg-gray-200'
            )}
        >
            <div className="flex items-center truncate">
                {Icon && (
                    <span
                        className={cn(
                            'me-3 inline-flex h-5 w-5 items-center justify-center rounded-md [&>svg]:h-[20px] [&>svg]:w-[20px]',
                            isChildActive
                                ? 'text-primary'
                                : 'text-gray-400 dark:text-gray-500 dark:group-hover:text-gray-700'
                        )}
                    >
                        <Icon />
                    </span>
                )}
                <span className="truncate">{item?.name}</span>
            </div>
            {item?.badge?.length ? <label>{item?.badge}</label> : null}
        </label>
    );
}
