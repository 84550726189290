import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { updateGeneral } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import AddGeneralMaster from '../../../Form/master/general-master/add-general-master';
import { ScreenName } from '../../../Constant/Screen/Screen';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';
import { useModal } from '../../../shared/modal-views/use-modal';
import { deactiveText } from '../../../Constant/Common/common-variable';
import Status from '../../../Component/ui/common/status';


export const GetGeneralMasterColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({})


  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, { status: deactiveText })
    try {
      const result = await HitApi(row, updateGeneral);
      showCustomAlert(result)
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
  };
  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 8,
      render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
    },
    {
      title: <HeaderCell title="Label" className="font-extrabold" />,
      dataIndex: 'label',
      key: 'label',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Value" className="font-extrabold" />,
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Used By" className="font-extrabold" />,
      dataIndex: 'usedBy',
      key: 'usedBy',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Status" className="font-extrabold" />,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, row) => <Status value={value} />,
    },

    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={ScreenName?.generalMaster}
          row={row}
          onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit General Master', AddGeneralMaster, 400, ApiHit)}
          onDelete={(rowData) => handleDelete(rowData)}
          checkKeys={[]}
        />
      )),
    },
  ];

}
