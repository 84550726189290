import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader, Text, Title } from 'rizzui';
import { ACTIVE, ActiveInventoryText, CANCELLED, CONSUME, DISPATCHED, HOLD, INVENTORY, InactiveInventoryText, InventoryText, LOADING, ORDER_RECEIVING, TOLLING, UNHOLD, UNLOADING, inboundOrder, inboundText, incommingOrder, outboundOrder, outboundText } from '../../../Constant/Common/common-variable';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import cn from '../../../Utils/class-names';

export default function Item({ ele, onClick,  selected }) {
    const reduxDashboard = useSelector(state => state.DashboardReducer);
    const [data, setData] = useState(null);
    const { loadingState, setDynamicLoading } = useDynamicLoading();

    useEffect(() => {
        setData(null)
        if (data === null && ele?.api) loadData();

    }, []);

    const loadData = async () => {
        const query = ConstructQuery(ele, reduxDashboard);
        ele.query = query;  // Assign the new query to ele.query
        const result = await HitApi(query, ele?.api);

        setData(result?.totalElements||0);
        setDynamicLoading({ [ele?.title]: false });
    };

    return (
        <div className='w-48'>
            <div className={cn('card cursor-pointer p-4 justify-between border-2 rounded-md', reduxDashboard?.selectedData?.find((Obj)=>Obj.displayText === ele?.displayText) ? 'bg-red-lighter border-red-custom_light' : '')} onClick={onClick}>
                <div className='flex justify-between'>
                    <label className='capitalize'>{ele?.displayText?.replaceAll('_', ' ')}</label>
                    <label className=''>
                    {data !== null ? data : ele?.api ? <Loader /> : null}
                    </label>
                </div>
            </div>
        </div>
    );
}

export const ConstructQuery = (ele, data) => {
    const baseQuery = { ...ele?.query, search: ele?.query?.search || {} };
    const buildingId = data?.apiJson?.selectedBuilding?._id;
    const customTitle = [HOLD, UNHOLD, CONSUME, LOADING, UNLOADING, DISPATCHED, CANCELLED, TOLLING];
    const inventoryTitle = [HOLD, CONSUME, ORDER_RECEIVING];
    
    if (ele?.serverKeys === InventoryText){
        ele.query = { ...baseQuery, search: { ...baseQuery.search, currentLocation: buildingId, tagType : INVENTORY } };
    }
    else if (ele?.serverKeys === InventoryText && inventoryTitle.includes(ele?.title)) {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, currentLocation: buildingId } };
    } else if (ele?.serverKeys === 'Orders' || customTitle.includes(ele?.title)) {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, currentLocation: buildingId } };
    } else if (ele?.serverKeys === 'Readers') {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, buildingIds: {$in:[buildingId]} } };
    } else if (ele?.serverKeys === 'Errors') {
        ele.query = { ...baseQuery, search: { ...baseQuery.search, currentLocation: buildingId } };
    }else {
        ele.query = baseQuery;
    }
    
    return ele.query;
};
