import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SingleBarChartOptions, compileChartData, searchQueryByType } from '../../../Utils/Utils';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CompileChartData } from './chart-promiss';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import CustomDatePicker from '../../../Component/ui/date-and-time/date-picker';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { ReportTypeOptions } from '../../../Constant/Common/Common';
import { setDashboardChartJson } from '../../../Store/Action/dashbaord/DashboardAction';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';

export default function DashbaordCharts({ title }) {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer);
    const [chartOption, setChartOption] = useState(null);
    const { loadingState, setDynamicLoading } = useDynamicLoading()
    const chartRef = useRef();
    const selectionType = 'chart-type';

    useEffect(() => {

        const selected = reduxSelect?.selected;
        const findItem = selected?.find(Obj => Obj?.selectionType === selectionType && Obj?.label === reduxDashboard?.chartJson?.type);
        if (findItem === undefined || !findItem) {
            const oldData = reduxSelect?.selected || [];
            oldData.push({ label: reduxDashboard?.chartJson?.type, name: 'type', selectionType });
            dispatch(setSearchableSelectSelectedData(oldData));
        }

    }, [reduxDashboard])

    const loadCompiledData = (reduxDashboard) => {
        CompileChartData(reduxDashboard?.chartData?.content, reduxDashboard?.chartJson?.type, reduxDashboard?.chartJson?.date, SingleBarChartOptions, title).then((CompiledData) => {

            if (CompiledData) {
                setChartOption(CompiledData)
            }
        })
    }

    if (reduxDashboard?.chartData?.content && chartOption === null) {
        loadCompiledData(reduxDashboard)
    }

    const loadChartData = () => {
        var chartJson = reduxDashboard?.chartJson
        var query = searchQueryByType(chartJson)
        var searchQuery = chartJson?.query
        Object.assign(searchQuery.search, { updatedAt: query })

        // Storing Chart Json for Future Work
        var api = reduxDashboard?.apiJson?.selectedElement?.api
        // Ends Here
        setDynamicLoading({fullPage : true})
        HitApi(searchQuery, api).then((result) => {

            CompileChartData(result?.content, reduxDashboard?.chartJson?.type, reduxDashboard?.chartJson?.date, SingleBarChartOptions, title).then((CompiledData) => {

                if (CompiledData) {
                    setChartOption(CompiledData)
                    setDynamicLoading({fullPage : false})
                }
            })
        })

    }

    const handleChange = (e) => {
        const { label, value } = e;
        const json = reduxDashboard?.chartJson;
        Object.assign(json, { type: value });
        dispatch(setDashboardChartJson(json))
        loadChartData()
    };

    const handleDateChange = (date) => {
        const json = reduxDashboard?.chartJson;
        Object.assign(json, { date: date });
        dispatch(setDashboardChartJson(json))
        loadChartData()
    };


    return (
        <div>
            <div className='flex justify-end space-x-2'>
                <SearchableSelect
                    className={'w-40'}
                    name={'type'}
                    label={'Report Range'}
                    selectionType={selectionType}
                    defaultOptions={ReportTypeOptions}
                    // getFieldName={'value'}
                    // reduxState={reduxChart?.apiJson}
                    // setAction={setChartApi}
                    onChange={handleChange}
                />
                <CustomDatePicker
                    className={'w-40'}
                    dateFormat={reduxDashboard?.chartJson?.type === 'daily' || reduxDashboard?.chartJson?.type === 'weekly' ? "MMM, yyyy" : reduxDashboard?.chartJson?.type === 'monthly' ? 'yyyy' : 'd MMM yyyy'}
                    name={'date'}
                    label={'Date'}
                    showMonthYearPicker={reduxDashboard?.chartJson?.type === 'daily' || reduxDashboard?.chartJson?.type === 'weekly'}
                    showYearPicker={reduxDashboard?.chartJson?.type === 'monthly'}
                    reduxState={reduxDashboard?.chartJson}
                    // setAction={setChartApi}
                    onChange={handleDateChange}
                />
            </div>
            <div className='mt-6'>
                <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOption} />
            </div>
        </div>
    )
}
