import { PiBrowserDuotone } from 'react-icons/pi';
import { routes } from '../config/routes';
import UserManagementIcon from '../Constant/Icons/user-management-icon';
import RolePermissionIcon from '../Constant/Icons/role-permission-icon';
import SiteMasterIcon from '../Constant/Icons/site-master-icon';
import BuildingsMasterIcon from '../Constant/Icons/building-master-icon';
import ReaderMasterIcon from '../Constant/Icons/reader-master-icon';
import ProductMasterIcon from '../Constant/Icons/product-master-icon';
import CustomerMasterIcon from '../Constant/Icons/coustumer-master-icon';
import SupplierMasterIcon from '../Constant/Icons/supplier-master-icon';
import GeneralMasterIcon from '../Constant/Icons/general-master-icon';
import MappingMasterIcon from '../Constant/Icons/mapping-master-icon';
import VehicleMasterIcon from '../Constant/Icons/vehicle-master-icon';
import ConfigurationMasterIcon from '../Constant/Icons/configuration-master-icon';
import WebReceivingIcon from '../Constant/Icons/web-receiving-icon';
import LocationMasterIcon from '../Constant/Icons/location-master-icon';
import ZoneMasterIcon from '../Constant/Icons/zone-master-icons';
import TagMasterIcon from '../Constant/Icons/tag-master-icon';
import { RxDashboard } from 'react-icons/rx';
import { apiServicesManager, brokerManager, buildingsMaster, certificateManager, configurationDeviceManager, customerMaster, deviceManager, devicesManager, generalMaster, inbound, inboundOrder, locationMaster, mapperManager, mappingMaster, master, outbound, outboundOrder, productMaster, rolesAndPermissionMaster, siteMaster, supplierMaster, tagMaster, userManagementMaster, vehicleMaster, zoneMaster } from '../Constant/Common/common-variable';

export const menuItems = [
  {
    id: '1', name: 'Dashboard', href: routes?.panel?.dashboard, icon: ProductMasterIcon
  },
  {
    id: '1', name: master, href: '', icon: RxDashboard,
    menuItems: [
      { name: userManagementMaster, href: routes?.panel?.master?.users, icon: UserManagementIcon },
      { name: rolesAndPermissionMaster, href: routes?.panel?.master?.rolesAndPermission, icon: RolePermissionIcon },
      { name: siteMaster, href: routes?.panel?.master?.siteMaster, icon: SiteMasterIcon },
      { name: buildingsMaster, href: routes?.panel?.master?.buildingsMaster, icon: BuildingsMasterIcon },
      { name: zoneMaster, href: routes?.panel?.master?.zomeMaster, icon: ZoneMasterIcon },
      { name: tagMaster, href: routes?.panel?.master?.tagMaster, icon: TagMasterIcon },
      { name: locationMaster, href: routes?.panel?.master?.locationMaster, icon: LocationMasterIcon },
      { name: productMaster, href: routes?.panel?.master?.productMaster, icon: ProductMasterIcon },
      { name: customerMaster, href: routes?.panel?.master?.customerMaster, icon: CustomerMasterIcon },
      { name: supplierMaster, href: routes?.panel?.master?.supplierMaster, icon: SupplierMasterIcon },
      { name: vehicleMaster, href: routes?.panel?.master?.vehicleMaster, icon: VehicleMasterIcon },
      { name: generalMaster, href: routes?.panel?.master?.generalMaster, icon: GeneralMasterIcon },
      { name: mappingMaster, href: routes?.panel?.master?.mappingMaster, icon: MappingMasterIcon },
    ],
  },
  {
    id: '2',
    name: deviceManager,
    title: 'Manage Devices',
    icon: ReaderMasterIcon,
    menuItems: [
      { name: certificateManager, href: routes?.panel?.deviceManager?.certificates, icon: ReaderMasterIcon },
      { name: brokerManager, href: routes?.panel?.deviceManager?.broker, icon: ReaderMasterIcon },
      { name: configurationDeviceManager, href: routes?.panel?.deviceManager?.configuration, icon: ReaderMasterIcon },
      { name: mapperManager, href: routes?.panel?.deviceManager?.mapper, icon: ReaderMasterIcon },
      { name: apiServicesManager, href: routes?.panel?.deviceManager?.apiService, icon: ReaderMasterIcon },
      { name: devicesManager, href: routes?.panel?.deviceManager?.devices, icon: ReaderMasterIcon },
    ],
  },
  {
    id: '3',
    name: inbound,
    title: 'Inbound',
    icon: WebReceivingIcon,
    menuItems: [
      { name: inboundOrder, href: routes?.panel?.order?.inboundOrder, icon: WebReceivingIcon },
    ],
  },
  {
    id: '4',
    name: outbound,
    title: 'Outbound',
    icon: WebReceivingIcon,
    menuItems: [
      { name: outboundOrder, href: routes?.panel?.order?.outboundOrder, icon: WebReceivingIcon },
    ],
  },
];
