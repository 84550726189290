
import Skeleton from "react-loading-skeleton";
import { HeaderCell } from "../../../../Component/ui/table";
import { Text } from "rizzui";
import { useState } from "react";

export const GetCyleCountColumn = () => {
    const [loadingRows, setLoadingRows] = useState({});

    const renderCell = (value, row, content) => (
        loadingRows[row.index] ? <Skeleton /> : content
    );

    return [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Tag No." />,
            dataIndex: 'rfidTag',
            key: 'rfidTag',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Tag Type" />,
            dataIndex: 'tagType',
            key: 'tagType',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Current Location" />,
            dataIndex: 'currentLocation',
            key: 'currentLocation',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Movement Status" />,
            dataIndex: 'movementStatus',
            key: 'movementStatus',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        
        
    ];
};
