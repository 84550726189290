import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import useValidation from '../../../Hooks/useValidation';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';
import { validationSchema } from '../../../Utils/validators/validationSchema';
import SearchableMultiSelect from '../../../Component/ui/form/select/searchable-multi-select';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import { addBuildingToZone, searchZone, updateLocation } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';

export const zoneMasterSchema = {
  tempZoneIds: validationSchema.string()?.optional()
};

export default function SearchAndAddZone({ row, ApiHit }) {
  const dispatch = useDispatch()
  const { errors, validate } = useValidation(zoneMasterSchema);
  const { showCustomAlert } = useCustomAlertController()
  const { loadingState, setDynamicLoading } = useDynamicLoading()
  const [selectedZone, setSelectedZone] = useState(null)

  const handleChange = (e) => {
    var json = row
    var tempZoneIds = []
    e?.map((ele) => {
      tempZoneIds?.push({ _id: ele?._id })
    })
    setSelectedZone(tempZoneIds)
  }

  const handleSubmit = () => {

    const json = row
    Object.assign(json, { usedBy: selectedZone?.map((ele) => ele?._id) })
    delete json?.tempZoneIds

    HitApi(json, updateLocation).then((result) => {
      showCustomAlert(result)
      if(ApiHit){ApiHit()}
    })
  }

  return (
    <div className='p-10 '>
      <div className='h-80 flex justify-between flex-col'>
        <SearchableMultiSelect name="tempZoneIds" label={'Search Building'} hideSelectedOptions validate={validate} error={errors} getFieldName={'value'} api={searchZone} callBackJson={{ _id: 'value', buildingName: 'label' }} reduxState={row} onChange={handleChange} />
        <CustomButton type={'submit'} text={'Add Building'} onClick={handleSubmit} loading={loadingState?.doc?.addBuilding} />
      </div>
    </div>
  )
}
