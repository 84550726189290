import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchBuilding } from '../../../../Constant/Api/Api'
import { GetUserQuery } from '../../../../Utils/Utils'
import { setBuildingMasterData } from '../../../../Store/Action/master/building-master/building-master-action'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { CompileBuildingMaster } from '../../buildings-master/promiss/building-master.promiss'
import { FaAngleRight } from 'react-icons/fa'
import cn from '../../../../Utils/class-names'
import { Title } from 'rizzui'
import { setSelectedMappingMasterBuildingData, setSelectedMappingMasterJson } from '../../../../Store/Action/master/mapping-master/mapping-master-action'
import { setZoneMasterData } from '../../../../Store/Action/master/zone-master/zone-master-action'

export default function Building() {
    const dispatch = useDispatch()
    const reduxBuilding = useSelector(state => state.BuildingMasterReducer)
    const reduxMappingMaster = useSelector(state => state.MappingMasterReducer)

    useEffect(() => {
        if (reduxBuilding?.doc === null) {
            loadBuildingData()
        }



    }, [])

    const loadBuildingData = () => {
        var json = GetUserQuery(reduxBuilding?.searchJson)

        HitApi(json, searchBuilding).then((result) => {
            CompileBuildingMaster(result).then((CompiledData) => {
                dispatch(setBuildingMasterData(CompiledData))
            })
        })
    }

    const handleClick = (ele) => {
        var json = reduxMappingMaster?.mappingJson
        Object.assign(json, {building:{value: ele?.buildingName, _id : ele?._id, show:true}})
        dispatch(setSelectedMappingMasterJson(json)) // updateing mapping json with selected data
        dispatch(setSelectedMappingMasterBuildingData(ele)) // setting selected building data
        dispatch(setZoneMasterData(null)) // setting zone master data to null

    }

    let item;
    if (reduxBuilding?.doc !== null) {
        item = reduxBuilding?.doc?.content?.map((ele, index) => {
            return <div key={index} className='group' onClick={() => handleClick(ele)}>
                <div className={cn('py-3 px-2 my-1.5 shadow-sm rounded-lg flex items-center justify-between group-hover:cursor-pointer border ', ele?._id === reduxMappingMaster?.mappingJson?.building?._id ? 'bg-red-lighter text-red-main font-bold tracking-wider border border-red-main' : 'bg-white ')}>
                    <label className='group-hover:cursor-pointer'>{ele?.buildingName}</label>
                    <label className='group-hover:cursor-pointer'><FaAngleRight /></label>
                </div>
                <label className='group-hover:cursor-pointer'>{ele?._id}</label>
            </div>
        })
    }


    return (
        <div>
            <div className='h-[4.3rem]'><Title as='h5'>Buildings</Title></div>
            <div>
                {item || 'No Data Found'}
            </div>
        </div>
    )
}
