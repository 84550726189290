import { validationSchema } from "../utils/validation-schema";

export const addCertificateSchema = (apiJson) => {
    

    return {
        certificateName: validationSchema?.string('Certificate Name is Required'),
        certificateType: validationSchema?.string('Certificate Type is Required'),
        version: validationSchema?.string('Certificate Version is Required'),
        issuer: validationSchema?.string('Certificate Issuer is Required'),
        serialNumber: validationSchema?.string('Serial no is Required'),
        keyUsage: validationSchema?.string('Key Usage is Required'),
        extendedKeyUsage: validationSchema?.string('Extended Key usage is Required'),
        signature: validationSchema?.string('Signature is Required'),
        publicKey: validationSchema?.string('Public Key is Required'),



        // publicKey: apiJson?.isSecureConnection ? validationSchema?.array().required('Secure Connection Type Field is Required').refine(arr => arr.length > 0, { message: 'Array cannot be empty' }) : validationSchema?.string().optional(),
        // host: validationSchema?.string('Host Field is Required'),
        // port: validationSchema?.string('PORT Field is Required'),
        // ntpServerHost : validationSchema?.string().optional(),
        // ntpServerPort : validationSchema?.string().optional(),
        // username: validationSchema?.string('Usernmae Field is Required'),
        // password: validationSchema?.string('Password Field is Required'),
        // brokerDetailsId: validationSchema?.string('Broker Field is Required'),
        // certificateId: validationSchema?.string('Certificate Field is Required'),
        // apiService: validationSchema?.array()?.required('Api Service Field is Required'),
        // deviceProfile : !apiJson?.deviceProfile?.mapperId ? validationSchema?.string('Mapper Field is Required') : validationSchema?.string().optional(),
        // configName : !apiJson?.deviceProfile?.profileId ? validationSchema?.string('Profile Field is Required') : validationSchema?.string().optional(),
    }
};
