import React from 'react'
import { STATUS_CLASSES } from '../../../Constant/Colors/Color';
import { Badge, Text } from 'rizzui';

export default function Status({ value }) {
    const lowerCaseValue = value?.toLowerCase();
    const statusClass = STATUS_CLASSES[lowerCaseValue] || STATUS_CLASSES.default;

    return (
        <div className="flex items-center">
            <Badge color={statusClass.badgeColor} renderAsDot />
            <Text className={`ms-2 font-medium capitalize ${statusClass.textColor}`}>
                {value || '---'}
            </Text>
        </div>
    );
}
