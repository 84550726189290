import React from 'react'
import CarbonSidebar from './carbon-sidebar'
import CustomRoutes from './Routes'
import Header from './carbon-header'
import useDynamicLoading from '../Hooks/use-dynamic-loading'
import { Loader } from 'rizzui'

export default function Layout({ children }) {
  const { loadingState } = useDynamicLoading()
  return (
    <>
    <main className="flex min-h-screen flex-grow">
      <CarbonSidebar className="fixed flex-col justify-between dark:bg-gray-50 block " />
      <div className="flex w-full flex-col pl-[100px] bg-slate-50/70">
        <Header />
        <div className="flex flex-grow flex-col px-4 pb-6 pt-2 md:px-5 lg:px-6 lg:pb-8 3xl:px-8 3xl:pt-4 4xl:px-10 4xl:pb-9">
          <CustomRoutes />
        </div>
      </div>
    </main>
    {loadingState?.doc?.fullPage && (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-[999] flex justify-center items-center">
        <Loader size='xl' className='text-red-main w-16' />
      </div>
    )}
    </>
  )
}
