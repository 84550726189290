import React, { useEffect, useState } from 'react';
import Header from '../api-service/ApiRequestType/Header'
import Auth from '../api-service/ApiRequestType/Auth'
import Body from '../api-service/ApiRequestType/Body'
import { useDispatch, useSelector } from 'react-redux';
import { AllApiCallHere } from './store/AllApiCallHere';
import { setApiResCommingOrNot, setServiceGlobalVariabls, setServiceRequestData } from './store/Action/ServiceMasterAction';
import { searchRequest, updateRequest } from './constants/constant';
import { dynamicFetch } from './FullJson';
import { File } from 'lucide-react';
import CustomJsonEditor from '../../../Component/ui/editor/json-editor';
import { CompileConfiguration, checkJSONFormat, convertJsonToXml, convertNodeToJson, extractHashValues, replaceValues, xmlToJson } from './utils';
import { proxy } from '../../../Constant/Api/Api';
import { Colors } from '../../../Constant/Colors/Color';
import Loader from './component/Loader';
import base64 from 'base-64';

function ApiRequest({ dataForRequest }) {

    const ServiceMasterReducer = useSelector(state => state.ServiceMasterReducer);

    const [activeTab, setActiveTab] = useState('Authorization');
    const [accuatalResult, setAccuatalResult] = useState(null)
    const [sendLoader, setSendLoader] = useState(false)
    const [render, setRender] = useState(Date.now())
    const [saveClickLoader, setSaveClickLoader] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (ServiceMasterReducer?.requestDoc === null) {
            getApiRequest()
            setAccuatalResult("")
            setRender(Date.now())
        }
    }, [ServiceMasterReducer, render])

    const getApiRequest = () => {
        var json = {
            page: 1,
            limit: 1,
            search: {
                _id: ServiceMasterReducer?.doc?.[dataForRequest?.serviceIndex]?.requests?.[dataForRequest?.requestIndex]?.requestId
            }
        }
        AllApiCallHere(json, searchRequest).then(res => {
            if (res?.content?.length > 0) {
                dispatch(setServiceRequestData(res?.content?.[0]))
                setRender(Date.now())
            };
        })
    }

    const onClickSend = async () => {

        dispatch(setApiResCommingOrNot(true))
        setSendLoader(true)

        var oldData = ServiceMasterReducer?.requestDoc
        const hashValues = extractHashValues(oldData?.request);
        if (hashValues?.length !== 0) {
            hashValues.map((ele) => {
                const tokenObject = ServiceMasterReducer?.globalVariables?.find(item => item.valueName === ele?.split("#")[1]);
                const token = tokenObject ? tokenObject?.data : null;
                oldData.request = replaceValues(oldData?.request, { [ele?.split("#")[1]]: token })
            })
        }
        const exists = oldData?.request?.header?.some(item => item.value === "application/xml");
        let header;
        var body = oldData?.request?.body
        body.raw = body?.raw

        if (oldData?.request?.auth?.bearer?.length !== 0) {
            var oldBearer = oldData?.request?.auth?.bearer
            var arr = []

            if (oldData?.request?.auth?.type === 'bearer') {
                if (oldBearer?.[0]?.value !== '') {
                    arr.push({ Authorization: 'Bearer ' + oldBearer?.[0]?.value })
                }
            } else {
                if (oldBearer?.[0]?.value !== '' && oldBearer?.[0]?.key !== '') {
                    arr.push({ Authorization: 'Basic ' + base64.encode(`${oldBearer?.[0]?.key}:${oldBearer?.[0]?.value}`) })
                }
            }

            header = [...oldData?.request?.header, ...arr]
        }


        // let noSpacesStr = body?.raw?.replace(/\s+/g, '');


        let json = {
            "request": {
                "host": oldData?.request?.url?.raw,
                "type": oldData?.request?.method,
                "body": body?.raw, // We'll set this below
            },
            "header": header !== null ? header : oldData?.request?.header
        };



        AllApiCallHere(json, proxy, exists).then(async res => {



            let jsonResult
            if (exists) {
                jsonResult = xmlToJson(res);
                // jsonResult = res;
            }
            setSendLoader(false)
            if (ServiceMasterReducer?.globalVariables !== null && ServiceMasterReducer?.globalVariables?.length !== 0) {
                var tempGlobalArr = await CompileConfiguration(ServiceMasterReducer?.globalVariables, jsonResult === undefined ? res : jsonResult);
            }
            oldData.response = [jsonResult === undefined ? res : jsonResult]
            dispatch(setServiceGlobalVariabls(tempGlobalArr))
            setAccuatalResult(jsonResult === undefined ? res : jsonResult)
            setRender(Date.now())
            dispatch(setServiceRequestData(oldData))
        })
    };

    const onChnageMethod = (value) => {
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.method = value
        dispatch(setServiceRequestData(oldJson))
    }

    const onChnageUrl = (value) => {
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.url.raw = value
        dispatch(setServiceRequestData(oldJson))
        setRender(Date.now())
    }

    const onClickSaveRequest = (data) => {

        setSaveClickLoader(true)

        var oldData = ServiceMasterReducer.requestDoc



        if (data) {

            oldData.response[0] = data
        } else if (accuatalResult !== null && accuatalResult !== "") {
            oldData.response[0] = accuatalResult
        }

        AllApiCallHere(oldData, updateRequest).then(result => {

            // setAccuatalResult(result)
            setSaveClickLoader(false)
            setRender(Date.now())
            alert(result?.message)
        })
    }

    const tabs = ['Authorization', 'Headers', 'Body'];

    return (
        ServiceMasterReducer?.requestDoc !== null ?
            <div>

                <div className="p-4 bg-white rounded-lg shadow-md">
                    <div className="flex gap-1 mb-4">
                        <select onChange={(e) => onChnageMethod(e.target.value)} className=" w-max rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            <option selected={ServiceMasterReducer?.requestDoc?.request?.method === 'GET'}>GET</option>
                            <option selected={ServiceMasterReducer?.requestDoc?.request?.method === 'POST'}>POST</option>
                            <option selected={ServiceMasterReducer?.requestDoc?.request?.method === 'PUT'}>PUT</option>
                            <option selected={ServiceMasterReducer?.requestDoc?.request?.method === 'DELETE'}>DELETE</option>
                        </select>
                        <div className='w-full'>
                            <input value={ServiceMasterReducer?.requestDoc?.request.url.raw} onChange={(e) => onChnageUrl(e.target.value)} className='w-full rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50' />
                        </div>
                        {
                            !sendLoader ?
                                <button style={{ background: Colors.LOGINRED }} className="px-4 text-white font-bold bg-blue-400 items-center text-center rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" onClick={() => onClickSend()}>
                                    Send
                                </button> :
                                <Loader className={'w-8 h-8'} />
                        }

                    </div>
                    {
                        ServiceMasterReducer?.apiResCommingOrNot === false &&
                        <div className='flex self-center mb-2'>
                            {
                                !saveClickLoader ?
                                    <button style={{ background: Colors.LOGINRED }} className="flex gap-1 p-2.5 text-white font-bold col-span-1 bg-blue-400 items-center text-center rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" onClick={() => onClickSaveRequest()}>
                                        <File size={18} /> Save
                                    </button>
                                    :
                                    <Loader className={'w-6 h-6'} />
                            }
                        </div>
                    }
                    <div className="flex border-b">
                        {tabs.map((tab) => (
                            <button
                                key={tab}
                                style={{ color: activeTab === tab ? Colors.LOGINRED : '#000', borderBottom: activeTab === tab ? '1px solid ' + Colors.LOGINRED : '' }}
                                className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))}

                    </div>
                    {
                        activeTab === 'Params' ?
                            <h1>Params</h1> :
                            activeTab === 'Authorization' ?
                                <Auth /> :
                                activeTab === 'Headers' ?
                                    <Header />
                                    :
                                    <Body />
                    }
                </div>
                <div className='mt-5'>
                    {/* accuatalResult === null || accuatalResult === "" ? JSON.stringify(ServiceMasterReducer?.requestDoc?.response?.[0]) : accuatalResult */}
                    <CustomJsonEditor readOnly={true} detectRender={render} json={accuatalResult === null || accuatalResult === "" ? ServiceMasterReducer?.requestDoc?.response?.[0] : accuatalResult} />
                    {/* <textarea className='w-full h-96' value={accuatalResult === null || accuatalResult === "" ? JSON.stringify(ServiceMasterReducer?.requestDoc?.response?.[0]) : accuatalResult} /> */}
                </div>
            </div>
            :
            'Loading'

    );
}

export default ApiRequest;