import React, { useEffect, useState } from 'react'
import CustomInput from '../component/form/custom-input'
import CustomButton from '../component/form/custom-button'
import { useDispatch, useSelector } from 'react-redux'
import { setDevicesApiJson, setDevicesData } from '../store/action/devices/devices-action'
import useValidation from '../hook/use-validation'
import { addDeviceSchema } from '../schema/add-device.schema'
import { Title } from 'rizzui'
import CustomSwitch from '../../../../Component/ui/switch/custom-switch'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import { addDevice, addDeviceProfile, deleteDeviceProfile, searchApiService, searchBroker, searchBuilding, searchCertificate, searchConfig, searchDevice, searchGeneral, searchMapper, updateDevice } from '../../../../Constant/Api/Api'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import { deviceVariable as variable } from '../../../../Constant/variables/device-manager/device/device.variable'
import { setDeviceProfileApiJson } from '../../../../Store/Action/device-master/device-profile/device-profile-action'
import { _id } from '../../../../Constant/Common/common-variable'
import OldProfile from './old-profile'
import AddNewDeviceProfile from './add-new-device-profile'
import DeletePopover from '../../../../shared/delete-popover'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import SearchableMultiSelect from '../../../../Component/ui/form/select/searchable-multi-select'
import PasswordInput from '../../../../Component/ui/form/input/password-input'
import useCustomAlertController from '../../../../Hooks/use-custom-alert'
import { routes } from '../../../../config/routes'
import { useNavigate } from 'react-router-dom'

export const Status = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
]

export const Protocol = [
  { label: 'MQTT', value: 'MQTT' },
  { label: 'UDP', value: 'UDP' },
  { label: 'TCP', value: 'TCP' },
  { label: 'HTTP', value: 'HTTP' },
  { label: 'HTTPS', value: 'HTTPS' },
]

export const DeviceType = [
  { label: 'Gateway', value: 'gateway' },
  { label: 'Reader', value: 'Reader' },
  { label: 'Position Sensor', value: 'position_sensor' },
  { label: 'Weighing Scale', value: 'weighing_scale' },
]

export const SecureConnectionType = [
  { label: 'TLS', value: 'TLS' },
  { label: 'SSL', value: 'SSL' },
  { label: 'TCP', value: 'TCP' },
]

export default function AddDevice() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const reduxDevices = useSelector(state => state.DevicesReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxDeviceProfile = useSelector(state => state.DeviceProfileReducer)
  const { showCustomAlert } = useCustomAlertController();
  const { errors, validate } = useValidation(addDeviceSchema(reduxDevices?.apiJson));
  const [data, setData] = useState(null)
  const [loading, setLaoding] = useState(false)
  const { loadingState, setDynamicLoading } = useDynamicLoading();
  const [msg, setMsg] = useState({})

  var url = window?.location?.pathname
  var ID = url?.split('/')?.[4]

  useEffect(() => {
    if (ID && !reduxDevices?.apiJson?._id) {
      loadDefault(ID)
    }

  }, [reduxDevices])


  const loadDefault = (_id) => {
    var searchJson = { ...reduxDevices?.searchJson }
    var json = reduxDevices?.apiJson
    Object.assign(json, { _id: _id })

    Object.assign(searchJson.search, { _id: _id })

    setDynamicLoading({ fullPage: true })

    HitApi(searchJson, searchDevice).then((result) => {
      if (result?.content?.[0]) {
        var row = result?.content?.[0]
        handleDataInput(row)
        setDynamicLoading({ fullPage: false })
      }
    })
  }

  const handleDataInput = (row) => {
    var json = reduxDevices?.apiJson
    var selected = reduxSelect?.selected
    Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
    Object.assign(json, { lat: row?.deviceLocation?.lat, lng: row?.deviceLocation?.lng })
    Object.assign(json, { brokerDetailsId: row?.brokerDetailsId?.[_id] })
    var tj = [
      { label: row?.['deviceType'], label: row?.['deviceType'], name: 'deviceType' },
      { label: row?.['buildingIds']?.[0], label: row?.['buildingIds']?.[0], name: 'buildingIds' },
      { label: row?.['protocol'], label: row?.['protocol'], name: 'protocol' },
      { label: row?.['secureConnectionType']?.[0], label: row?.['secureConnectionType']?.[0], name: 'secureConnectionType' },
    ]
    var tmj = []

    reduxDevices?.apiJson?.apiService?.forEach(element => {
      tmj.push({})
    });

    // multiSelected
    dispatch(setSearchableSelectSelectedData([...selected, ...tj]))
    dispatch(setDevicesApiJson(json))
  }

  // setLaoding(false)

  const handleNavigate = () => {
    var json = reduxDevices?.searchJson
    delete json?.search?._id
    dispatch(setDevicesApiJson(json))
    dispatch(setDevicesData(null))
    setDynamicLoading({ fullPage: false })
    navigate(routes?.panel?.deviceManager?.devices);
  }

  const handleSubmit = () => {
    var json = reduxDevices?.apiJson
    const validationErrors = validate(json);

    console.log('validationErrors', validationErrors);

  if (Object.keys(validationErrors).length === 0) {
          if (!json?.isDeviceMovable) { Object.assign(json, { deviceLocation: { lat: parseFloat(json?.lat), lng: parseFloat(json?.lng) } }) }
          setDynamicLoading({ fullPage: true })

          if (ID) {
            HitApi(json, updateDevice).then((result) => {
              if (result?.data?._id && result?.data?.profileId === "null") {
                handleDeviceProfileAdd(result)
              } else if (result?.data?._id) {
                showCustomAlert(result)
                handleNavigate()
              }
              else {
                setLaoding(false)
                setDynamicLoading({ fullPage: false })
              }
            })
          } else {
            HitApi(json, addDevice).then((result) => {

              if (result?.data?._id) {
                handleDeviceProfileAdd(result)
              } else {
                setLaoding(false)
                setDynamicLoading({ fullPage: false })
              }
            })
          }
          dispatch(setDevicesData(null))
        }
  }

  const handleDeviceProfileAdd = (result) => {
    var json = reduxDeviceProfile?.apiJson
    var resultData = result?.data
    Object.assign(json, {
      deviceId: resultData?.[_id],
      placement: resultData?.buildingIds?.[0],
      deviceUserIdentify: resultData?.deviceUserIdentify,
      macAddress: resultData?.macAddress,
      deviceType: resultData?.deviceType,
      deviceName: resultData?.deviceName,
      manufacturer: resultData?.manufacturer,
      model: resultData?.model,
      serialNumber: resultData?.serialNumber,
      mappingId: resultData?.deviceProfile?.mapperId,
      mappingValue: resultData?.deviceProfile?.profileUsage,
      // firmware : resultData?.firmwareVersion,
    })

    if (json?.deviceId) {
      HitApi(json, addDeviceProfile).then((res) => {

        if (res?.data?._id) {
          handleUpdateDevice(result?.data, res?.data)
        } else {
          setLaoding(false)
          setDynamicLoading({ fullPage: false })
        }
      })
    }
  }

  const handleUpdateDevice = (addDeviceResult, deviceProfileResult) => {
    var tempJson = addDeviceResult
    Object.assign(tempJson, { profileId: deviceProfileResult?._id, brokerDetailsId: addDeviceResult?.brokerDetailsId?.[_id] })
    Object.assign(reduxDevices?.apiJson, { profileId: deviceProfileResult?._id })
    HitApi(tempJson, updateDevice).then((result) => {

      if (result?.data?._id) {
        showCustomAlert(result)
        setLaoding(false)
        setDynamicLoading({ fullPage: false })
        handleNavigate()
      } else {
        setLaoding(false)
        setDynamicLoading({ fullPage: false })
      }
    })
  }

  const handleSelect = (e, name, type) => {
    let { label, value, _id } = e
    var json = reduxDevices?.apiJson || {}
    Object.assign(json, { [name]: type ? _id : name === 'secureConnectionType' ? [value] : value })
    dispatch(setDevicesApiJson(json))
  }

  const handlePlacement = (e, name, type) => {
    let { label, value, _id } = e
    var json = reduxDevices?.apiJson || {}
    Object.assign(json, { buildingIds: [_id] })
    dispatch(setDevicesApiJson(json))
  }
  const handleConfigSelect = (e, name) => {
    const { ele } = e; // Destructuring only the necessary property
    const json = reduxDevices?.apiJson; // Shallow copy without initializing deviceProfile upfront

    if (ele && name === 'configName') {
      // Ensure deviceProfile exists on json, and then assign properties
      json.deviceProfile = json.deviceProfile || {};
      Object.assign(json.deviceProfile, {
        profileUsage: ele?.configName,
        profileId: ele?._id,
      });
      setData(ele)
      dispatch(setDeviceProfileApiJson(ele?.config?.[0]))
    } else if (ele && name === 'mapperName') {
      // Ensure deviceProfile exists on json, and then assign properties
      json.deviceProfile = json.deviceProfile || {};
      Object.assign(json.deviceProfile, {
        mappedResultId: ele?._id,
        mapperId: ele?._id,
      });
    } else if (ele && name === 'deviceProfileName') {
      setData(ele)
      dispatch(setDeviceProfileApiJson(ele?.config?.[0]))
    }
  };

  const handleDelete = (clearJson) => {
    var json = { _id: reduxDevices?.apiJson?.profileId }
    HitApi(json, deleteDeviceProfile).then((res) => {
      if (res?.status === 200) {
        Object.assign(reduxDevices?.apiJson, { profileId: 'null' })
        dispatch(setDevicesApiJson(reduxDevices?.apiJson))
        HitApi(reduxDevices?.apiJson, updateDevice).then((result) => {

        })
      }
    })
  }

  const handleClear = (fieldName) => {
    var json = reduxDevices?.apiJson
    delete json?.[fieldName]
    dispatch(setDeviceProfileApiJson(json))
  }

  const onChange = (e) => {
    var json = reduxDevices?.apiJson
    Object.assign(json, { apiService: e })
    dispatch(setDevicesApiJson(json))
  }

  console.log('----',  reduxDevices?.apiJson?.deviceType === 'weighing_scale' || reduxDevices?.apiJson?.deviceType === 'position_sensor');

  // _id={reduxDevices?.apiJson?.buildingIds?.[0]}
  return (
    <div className='p-10 bg-white rounded-lg'>
      <form >
        <div className='mb-4'><Title as='h4'>Basic Information</Title></div>
        <div className="grid grid-cols-4 gap-4 ">
          <CustomInput name="deviceName" inputType={'text'} label="Device Name" validate={validate} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <SearchableSelect name="deviceType" label={'Device Type'} defaultOptions={DeviceType} error={errors} onChange={(e) => handleSelect(e, 'deviceType')} />
          <SearchableSelect name="buildingIds" _id={reduxDevices?.apiJson?.buildingIds?.[0]} label={'Placement'} api={searchBuilding} getFieldName={'buildingName'} error={errors} onChange={(e) => handlePlacement(e, 'placement')} />
          <CustomInput name="make" inputType={'text'} label="Make" validate={validate} value={reduxDevices?.apiJson?.make} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="model" label="Model" validate={validate} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="firmwareVersion" validate={validate} label="Firmware Version" value={reduxDevices?.apiJson?.firmwareVersion} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomSwitch name="isCloudConfigurable" validate={validate} label={'Is Cloud Configurable'} position={'top'} value={reduxDevices?.apiJson?.isCloudConfigurable} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          {reduxDevices?.apiJson?.isCloudConfigurable && <SearchableSelect name="protocol" validate={validate} label={'Protocol'} error={errors} defaultOptions={Protocol} onChange={(e) => handleSelect(e, 'protocol')} />}
          <CustomSwitch name="isSecureConnection" label={'Is Secure Connection'} position={'top'} value={reduxDevices?.apiJson?.isSecureConnection} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          {reduxDevices?.apiJson?.isSecureConnection && <SearchableSelect name="secureConnectionType" validate={validate} label={'Secure Connection Type'} error={errors} defaultOptions={SecureConnectionType} onClear={() => handleClear('secureConnectionType')} onChange={(e) => handleSelect(e, 'secureConnectionType')} />}
        </div>
        <div className='mt-8 mb-4'><Title as='h4'>Network Information</Title></div>
        <div className="grid grid-cols-4 gap-4 my-4">
          <CustomInput name="host" inputType={'ip'} validate={validate} label="Host " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="port" inputType={'port'} validate={validate} label="Port " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="ntpServerHost" important={false} inputType={'ip'} validate={validate} label="NTP Server Host" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="ntpServerPort" important={false} inputType={'port'} validate={validate} label="NTP Server Port" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="username" label="Username " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <PasswordInput name="password" label="Password " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          { (reduxDevices?.apiJson?.deviceType === 'weighing_scale' || reduxDevices?.apiJson?.deviceType === 'position_sensor')  && <CustomInput name="deviceUserIdentify" label="Device User Identify" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} /> }
          { (reduxDevices?.apiJson?.deviceType === 'weighing_scale' || reduxDevices?.apiJson?.deviceType === 'position_sensor')  && <CustomInput name="macAddress" label="Mac Address" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} /> }
          {/* <CustomInput name="deviceUserIdentify" label="Device User Identify" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          <CustomInput name="macAddress" label="Mac Address" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} /> */}
        </div>
        <div className="grid grid-cols-4 gap-4 my-4">
          <CustomSwitch name="isDeviceMovable" label={'Is Device Movable'} position={'top'} value={reduxDevices?.apiJson?.isDeviceMovable} error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />
          {!reduxDevices?.apiJson?.isDeviceMovable && <CustomInput name="lat" label="Device Location (lat)" error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />}
          {!reduxDevices?.apiJson?.isDeviceMovable && <CustomInput name="lng" label="Device Location (lng) " error={errors} reduxState={reduxDevices?.apiJson} setAction={setDevicesApiJson} />}
        </div>
        <div className='mt-8 mb-4'><Title as='h4'>Device Configuraiton</Title></div>
        <div className="grid grid-cols-4 gap-4 my-4">
          <SearchableSelect validate={validate} clearable={false} name="brokerDetailsId" _id={reduxDevices?.apiJson?.brokerDetailsId} label={'Broker'} error={errors} api={searchBroker} getFieldName={'brokerIp'} onChange={(e) => handleSelect(e, 'brokerDetailsId', true)} />
          <SearchableSelect validate={validate} clearable={false} name="certificateId" _id={reduxDevices?.apiJson?.certificateId} label={'Certificate'} error={errors} api={searchCertificate} getFieldName={'certificateName'} onChange={(e) => handleSelect(e, 'certificateId', true)} />
          <SearchableMultiSelect name="apiService" validate={validate} label={'Api Service'} getFieldName={'serviceName'} api={searchApiService} error={errors} reduxState={reduxDevices?.apiJson} callBackJson={{ serviceId: 'value', serviceUsage: 'label' }} onChange={onChange} />
          <SearchableSelect name="deviceProfile" error={errors} _id={reduxDevices?.apiJson?.deviceProfile?.mapperId} label={'Mapper'} api={searchMapper} dynamicSearch={{ 'mapperName': { $ne: null } }} getFieldName={'mapperName'} onChange={(e) => handleConfigSelect(e, 'mapperName')} />
        </div>
        <div className='mt-8 mb-4'><Title as='h4' className='flex items-center '>Device Profile {reduxDevices?.apiJson?.profileId !== "null" && reduxDevices?.apiJson?.profileId !== undefined && <div className='mx-6'><DeletePopover title="Delete" description="Are you sure you want to delete this Device Profile?" onDelete={() => handleDelete()} /></div>} </Title></div>
        {reduxDevices?.apiJson?.profileId !== "null" && reduxDevices?.apiJson?.profileId !== undefined ? <OldProfile /> : <AddNewDeviceProfile errors={errors} msg={msg} setMsg={setMsg} />}
        <div className='flex gap-3 justify-end'>
          <CustomButton text={'Cancel'} variant='flat' className={''} />
          <CustomButton type={'submit'} className={''} text={ID ? 'Update' : 'Submit'} onClick={() => handleSubmit(true)} loading={loadingState?.doc?.fullPage} />
        </div>
      </form>

    </div>
  )
}
